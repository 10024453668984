/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { connect ,useDispatch,} from 'react-redux';
import { bindActionCreators } from 'redux';
import { useToasts } from 'react-toast-notifications';


import ActionCreators from '../../actions';

import { SelectCompanyScreen } from '../../Screens';
import { BiWindows } from 'react-icons/bi';
import {getInspectionCompaniesEdit, getInspectionDetail,getCarDetails} from "../../actions/vehicleInspection";
import axios from "axios";
import {Api} from "../../services/configs";
import {useHistory} from "react-router-dom";

const SelectCompanyContainer = (props) => {

  const history = useHistory();
  const { addToast } = useToasts();

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  };

  const {
    createInspection,
    createReInspection,
    getCompaniesByState,
    stateCompanies,
    companies,
    match,
    getInspectionCompaniesEdit,
    getInspectionDetail,
    getNewCompanyData
  } = props;
const dispatch=useDispatch();
  // const [inspectionChecked, setInspectionChecked] = useState([]);
  const [inspectionChecked, setInspectionChecked] = useState('');
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [loadingcompny, setLoadingcompny] = useState(false);
  const [checkEmpty, setCheckEmpty] = useState(false);
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [inspectionState, setInspectionState] = useState("");
  const [stateEmpty, setStateEmpty] = useState(false);
  const [licensePlateNumber, setLicensePlateNumber] = useState('');
  const [alreadyExistModal, setAlreadyExistModal] = useState(false);
  const [inspectionErrorResp, setInspectionErrorResp] = useState('');
  const [getInspectionDetailData, setGetInspectionDetailData] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [turoState, setTuroState] = useState('');
  useEffect(() => {
    if(match?.params?.inspection === "true" && inspectionState === "" && props?.location?.state?.state)
    {
      const body = {
        stateName: props?.location?.state?.state
      }
    getCompaniesByState(body, setLoadingcompny);
    }

    if(match?.params?.inspection === "edit" && inspectionState === "" && props?.location?.state?.state)
    {
      const body = {
        stateName: props?.location?.state?.state
      }
      getCompaniesByState(body, setLoadingcompny);
    }

    if(match?.params?.inspection === "addCompany" && inspectionState === "" && props?.location?.state?.state)
    {
      const body = {
        stateName: props?.location?.state?.state
      }


      getCompaniesByState(body, setLoadingcompny, match?.params?.inspection, props?.location?.state?.selectedCompanies);
    }

    if(inspectionState != "") {
      const body = {
        stateName: inspectionState
      }
    getCompaniesByState(body, setLoadingcompny);
  }

    if(match?.params?.inspection === "addCompany" && inspectionState === "" && props?.location?.state?.state)
    {

       }else{

      let myArray = [];
      props?.location?.state?.selectedCompanies?.map((data) => {

        myArray.push( data );
      });
      setInspectionChecked(myArray);
    }




  }, [inspectionState]);

  const handleInspectionCheck = (id) => {
    const i=inspectionChecked;
    if(i===id)
    {
      setInspectionChecked('');
      setLicensePlateNumber('');
   const companyid=  localStorage.getItem('companyId')
      const body={companyId:companyid}
      dispatch(getCarDetails(body,setLoadingcompny))
    }
    

    else {
      setInspectionChecked(id);
      setLicensePlateNumber(id);
    }
  };


  if(match?.params?.inspection === 'edit')
  {


  useEffect(()=> {

    const { match } = props;
    getInspectionDetail(props?.location?.state?.InspectionId, setGetInspectionDetailData, setDetailLoading, setLicensePlateNumber);


  }, []);

  }else if(match?.params?.inspection === 'addCompany'){


    useEffect(()=> {
      const { match } = props;
      getInspectionDetail(props?.location?.state?.InspectionId, setGetInspectionDetailData, setDetailLoading, setLicensePlateNumber);


    }, []);

  }
  const handleSubmitAlreadyExist = () => {
    window.location.href = `/vehicleinspection/${inspectionErrorResp?.inspectionId}/${inspectionErrorResp?.vehicleId}?lyftUser=${inspectionErrorResp?.lyftInspection}`;
  }

  const handleAutoReInspection = (e) => {
    setAlreadyExistModal(false);
    setLicensePlateNumber('');
    setNumberEmpty(true);
    setInspectionChecked('');
  }

  const handleLicensePlateNumber = (number) => {
    setLicensePlateNumber(number);
    const licence=number;
    const companyid=  localStorage.getItem('companyId')
    const body={licensePlateNumber:licence,
      companyId  :companyid
  }
    dispatch(getCarDetails(body,setLoadingcompny))
  };

  const handleState = (state) => {
   setInspectionState(state);
  };


  const handlEmptyFields = () => {
    inspectionChecked.length === 0 ? setCheckEmpty(true) : setCheckEmpty(false);
  };

  const handleCreateInspection = (reInspection) => {
    if (reInspection === "false") {
     const companyid= localStorage.getItem('companyId');
      const body = {
        licensePlateNumber,
        // inspectionState,
        companyId:companyid,
        // companies: inspectionChecked,
        // licensePlateNumber: inspectionChecked,
      };
      localStorage.setItem( 'licensePlateNumber', licensePlateNumber)
      createInspection(body, setLoadingSelect, addToast, setAlreadyExistModal, setInspectionErrorResp, setLicensePlateNumber, setNumberEmpty,setInspectionChecked);
    }
    else if (reInspection === "true") {
      const body = {
        // inspectionState: inspectionState ? inspectionState : props?.location?.state?.state,
      inspectionChecked,
      };
      createReInspection(props?.location?.state?.vehicleId, body, setLoadingSelect);
    }
  };


useEffect(()=> {
  const companyid=  localStorage.getItem('companyId')
  const body={companyId:companyid}
dispatch(getCarDetails(body,setLoadingcompny))

}, []);
  const handleEditInspection = (reInspection) => {

     if (reInspection === "edit") {
      const body = {
        companies: inspectionChecked,
        licensePlateNumber: licensePlateNumber
      };

      const vehicleId = props?.location?.state?.VehicleId;

       getInspectionCompaniesEdit(props?.location?.state?.InspectionId, body, setLoadingSelect, vehicleId, history, addToast);
    }
  };

  const handleAddNewCompany = (reInspectionData) => {
    if (reInspectionData === "addCompany") {
      const body = {
        companies: inspectionChecked,
      };

      const inspectionId = props?.location?.state?.InspectionId;
      const vehicleId = props?.location?.state?.vehicleId;



      getNewCompanyData(inspectionId, body, setLoadingSelect, history, vehicleId, addToast);
    }
  }


  useEffect(() => {

    if((props?.location?.state?.state === "California" 
    ||props?.location?.state?.state === 'Northern California (Bay Area)'
   ||props?.location?.state?.state === 'Northern California (Sacramento Region)'
   ||props?.location?.state?.state === 'Northern California (Other)'
   ||props?.location?.state?.state === 'Central California'
   ||props?.location?.state?.state === 'Southern California (LA region)'
   ||props?.location?.state?.state === 'Southern California (Orange County)'
   ||props?.location?.state?.state=== 'Southern California (San Diego Region)' 
   ||props?.location?.state?.state === 'Southern California (Other)' )  && (inspectionChecked?.includes(16) ||  props?.location?.state?.selectedCompanies?.includes(16))) {

        setTuroState(stateCompanies?.splice(4, 1));
    }else if((props?.location?.state?.state === "California" 
    ||props?.location?.state?.state === 'Northern California (Bay Area)'
   ||props?.location?.state?.state === 'Northern California (Sacramento Region)'
   ||props?.location?.state?.state === 'Northern California (Other)'
   ||props?.location?.state?.state === 'Central California'
   ||props?.location?.state?.state === 'Southern California (LA region)'
   ||props?.location?.state?.state === 'Southern California (Orange County)'
   ||props?.location?.state?.state=== 'Southern California (San Diego Region)' 
   ||props?.location?.state?.state === 'Southern California (Other)' )  && (inspectionChecked?.includes(15) ||  props?.location?.state?.selectedCompanies?.includes(15) )){

      setTuroState(stateCompanies?.splice(5, 1));
    }

  }, [stateCompanies]);
  const filteredCompanies = () => {
    const selectedCompanies = [...props?.location?.state?.selectedCompanies];
    if ( selectedCompanies.length > 0 ) {
      return stateCompanies?.filter(company => !selectedCompanies.includes(company.id));
    } else {
      return [];
    }

  }

  const filteredCompaniesSelected = () => {
    const selectedCompanies = [...props?.location?.state?.selectedCompanies];
    if ( selectedCompanies.length > 0 ) {
      return stateCompanies?.filter(company => selectedCompanies.includes(company.id));
    } else {
      return [];
    }

  }

  return <SelectCompanyScreen
      filteredCompanies={filteredCompanies}
      filteredCompaniesSelected={filteredCompaniesSelected}
    companies={companies}
    stateCompanies={stateCompanies}
    checkEmpty={checkEmpty}
    stateEmpty={stateEmpty}
    numberEmpty={numberEmpty}
    handleState={handleState}
    setStateEmpty={setStateEmpty}
    setCheckEmpty={setCheckEmpty}
    submitLoading={submitLoading}
    loadingSelect={loadingSelect}
    loadingcompny={loadingcompny}
    setNumberEmpty={setNumberEmpty}
    inspectionState={inspectionState}
    setSubmitLoading={setSubmitLoading}
    handlEmptyFields={handlEmptyFields}
    inspectionChecked={inspectionChecked}
    alreadyExistModal={alreadyExistModal}
    licensePlateNumber={licensePlateNumber}
    reInspection={match?.params?.inspection}
    inspectionErrorResp={inspectionErrorResp}
    handleInspectionCheck={handleInspectionCheck}
    handleAutoReInspection={handleAutoReInspection}
    handleCreateInspection={handleCreateInspection}
    reInspectionState={props?.location?.state?.state}
    // InspectionEditState={inspectionDataEdit}
    getInspectionDetailData={getInspectionDetailData}
    handleSubmitAlreadyExist={handleSubmitAlreadyExist}
    handleLicensePlateNumber={handleLicensePlateNumber}
    handleEditInspection={handleEditInspection}
    detailLoading={detailLoading}
    reInspectionPlateNumber={props?.location?.state?.plateNumber}
    handleAddNewCompany={handleAddNewCompany}
    addToast={addToast}
    selectedCompanies={props?.location?.state?.selectedCompanies}
  />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
 
  return {
    
    vehiclesByStatus: state.vehicleInstruction.inspectionByStatus,
    companies: state?.auth?.companies?.data,
    // stateCompanies: state?.auth?.stateCompanies?.data,
    stateCompanies: state?.auth?.stateCompanies,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectCompanyContainer);
