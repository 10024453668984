/* eslint-disable */

import React from 'react';
import { Modal } from 'antd';
import { Tabs } from 'antd';
import { ClipLoader } from 'react-spinners';
import { IoCloseCircleOutline, IoSyncOutline, IoCheckmark, IoClose } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Webcam from 'react-webcam';

import './style.css';
import '../../App.css';

const InstructionModal = ({
  uploadingPercentage,
  aiLoading,
  isLoading,
  isDisabled,
  vehicleInstructionValues,
  isModalVisible,
  handleImageUpload,
  handleVideoUpload,
  handleModalClose,
  handleImageControl,
  locationMap,
  handleImageControlvin,
  vinwebcam,
  webRef,
  handlecameraOpen,
  imageConstraints,
  clickPic,
  WebCameraOpen,
  handleClickPicture,
  handleCamerarotation,
  capturedImage,
  captureAreaRef,
  saveImage,
  handleSaveImage,
  // isMobile,

}) => {
  const { TabPane } = Tabs;
  function callback(key) {
  }
  const isMobile = window.innerWidth <= 767;

  return (
    <div className="instruction-modalaaaa">
      {vehicleInstructionValues?.id ? (
        // vehicleInstructionValues?.id === 'exterior__rear' ? (
        //   <Modal
        //     visible={isModalVisible}
        //     closable={isLoading ? false : true}
        //     title={false}
        //     style={{ width: '100%' }}
        //     wrapClassName="instruction-modalls"
        //     footer={null}
        //     onCancel={handleModalClose}
        //     mask={true}
        //     className="close-icon"
        //     // closeIcon={false}


        //     maskClosable={isLoading ? false : true}
        //     closeIcon={
        //       vehicleInstructionValues?.type === 'Photo' ? (
        //         <IoCloseCircleOutline color={'white'} size={40} />
        //       ) : (
        //         //video icon
        //         <IoCloseCircleOutline color={'white'} size={40} />
        //       )
        //     }
        //     maskStyle={{ backgroundColor: '#266FB7' }}
        //     bodyStyle={{
        //       width: '100%',
        //       textAlign: 'center',
        //       // background: 'linear-gradient(182.55deg, #1876CC -17.67%, #051532 115.19%), #191A1B',
        //     }}
        //   >

        //     <div
        //       style={{
        //         width: '100%',
        //         background: '100%',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         justifyContent: 'center',
        //         // height: '100%',
        //       }}

        //     >
        //       {vehicleInstructionValues?.url ? (
        //         vehicleInstructionValues?.type === 'Photo' ? (
        //           <>
        //             <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => handleModalClose()}>
        //               <IoCloseCircleOutline color={'white'} size={40} />
        //             </div>
        //             <div className="modal-images">
        //               <img
        //                 alt=""
        //                 className="modal-inside-image"
        //                 src={`${process.env.REACT_APP_AWS_S3_LINK}/${vehicleInstructionValues?.url}`}
        //               />
        //             </div>
        //           </>
        //         ) : (
        //           <>
        //             <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => handleModalClose()}>
        //               <IoCloseCircleOutline color={'white'} size={40} />
        //             </div>
        //             <div className="video-container">
        //               <video
        //                 preload="metadata"
        //                 id="myVideo"
        //                 autoPlay={false}
        //                 className="modal-video"
        //                 controls
        //               >
        //                 <source
        //                   src={`${process.env.REACT_APP_AWS_S3_LINK}/${vehicleInstructionValues?.url}#t=0.5`}
        //                   type="video/mp4"
        //                 />
        //               </video>
        //             </div>
        //           </>
        //         )
        //       ) : (
        //         <>

        //           <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => { isDisabled === true ? null : handleModalClose() }}>
        //             <IoCloseCircleOutline color={'white'} size={40} />
        //           </div>
        //           <div className="main-heading-text">{vehicleInstructionValues?.title}</div>

        //           <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        //             <div>
        //               <Tabs defaultActiveKey="1" onChange={callback} centered>
        //                 <TabPane
        //                   tab={

        //                     <div className="instruction-tab-names" style={{ fontWeight: 'bold' }}>
        //                       Individually
        //                     </div>

        //                   }
        //                   key="1"
        //                 >
        //                   {vehicleInstructionValues?.type === 'Photo' ? (
        //                     <>
        //                       <img
        //                         alt=""
        //                         className="modal-image"
        //                         src={vehicleInstructionValues?.image}
        //                       />
        //                     </>
        //                   ) : (
        //                     <video
        //                       preload="metadata"
        //                       id="myVideo"
        //                       autoPlay={false}
        //                       width="100%"
        //                       height="240"
        //                       controls
        //                       src={`${vehicleInstructionValues?.video}#t=0.5`}
        //                     >
        //                       <source
        //                         src={`${vehicleInstructionValues?.video}#t=0.5`}
        //                         type="video/mp4"
        //                       />
        //                     </video>
        //                   )}

        //                 </TabPane>
        //                 <TabPane
        //                   tab={
        //                     <div className="instruction-tab-names" style={{ fontWeight: 'bold' }}>
        //                       With support
        //                     </div>
        //                   }
        //                   key="2"
        //                 >
        //                   {vehicleInstructionValues?.type === 'Photo' ? (
        //                     <>
        //                       <img
        //                         alt=""
        //                         className="modal-image"
        //                         src={vehicleInstructionValues?.image}
        //                       />
        //                     </>
        //                   ) : (
        //                     <video
        //                       preload="metadata"
        //                       id="myVideo"
        //                       autoPlay={false}
        //                       width="100%"
        //                       height="240"
        //                       controls
        //                       src={`${vehicleInstructionValues?.video2}#t=0.5`}
        //                     >
        //                       <source
        //                         src={`${vehicleInstructionValues?.video2}#t=0.5`}
        //                         type="video/mp4"
        //                       />
        //                     </video>
        //                   )}
        //                 </TabPane>

        //               </Tabs>

        //             </div>
        //             <div style={{ display: 'flex', marginLeft: '0px' }}>
        //               <div className="exterior-rear-circle">i</div>
        //               <div style={{ color: 'white', marginTop: '-210px', marginLeft: '12px', textAlign: 'justify' }}>
        //                 {vehicleInstructionValues?.description}
        //               </div>
        //             </div>
        //             <div
        //               style={{ paddingLeft: '18px', marginTop: '-105px' }}
        //               className="instruction-modal-step-container"
        //             >
        //               {vehicleInstructionValues?.steps.map((step, index) => (
        //                 <ul style={{ padding: 0, marginLeft: 10 }} key={index}>
        //                   <li>
        //                     <div key={index} className="instruction-modal-step">{step}</div>

        //                   </li>
        //                 </ul>
        //               ))}
        //             </div>
        //           </div>
        //           <div className="input-img-containerr">
        //             <div className="image-upload">
        //               {
        //               // isMobile?
        //               vehicleInstructionValues?.type === 'Photo' ? (
        //                 <>
        //                   {isLoading ?
        //                     <div style={{ width: 111, height: 111 }}>
        //                       <CircularProgressbar
        //                         value={parseInt(uploadingPercentage)}
        //                         text={`${parseInt(uploadingPercentage)}%`}
        //                         styles={buildStyles({
        //                           rotation: 0.75,
        //                           // strokeLinecap: 'round',
        //                           textSize: '16px',
        //                           fontWeight: 'bolder',
        //                           pathTransitionDuration: 0,
        //                           pathColor: `rgba(255, 122, 0, 1)`,
        //                           textColor: '#fff',
        //                         })}
        //                       />
        //                     </div>
        //                     : null}
        //                   <label htmlFor="file-input-photo" style={{ position: 'relative' }}>
                            
        //                     {isLoading ?
        //                       <div style={{ color: "#fff", marginTop: "10px" }}>
        //                         {isLoading ?
        //                           parseInt(uploadingPercentage) == 0 || uploadingPercentage == 0 ? 'Uploading'
        //                             : parseInt(uploadingPercentage) >= 90 ? 'Finalizing Upload'
        //                               : 'Uploading'
        //                           : 'Capture Now'
        //                         }
        //                       </div>
        //                       :
        //                       <div className="inspection-capture_now-button">
        //                         {aiLoading ? (
        //                           <ClipLoader color={'white'} size={20} />
        //                         ) : (
        //                           isLoading ? null
        //                             : 'Capture Now'
        //                         )}
        //                       </div>
        //                     }
        //                   </label>
        //                   <input
        //                     disabled={isLoading ? true : false}
        //                     type="file"
        //                     id="file-input-photo"
        //                     accept="image/*"
        //                     capture
        //                     onChange={handleImageUpload}
        //                   />
        //                 </>
        //               ) : (
        //                 <>
        //                   {isLoading ?
        //                     <div style={{ width: 111, height: 111 }}>
        //                       <CircularProgressbar
        //                         value={parseInt(uploadingPercentage)}
        //                         text={`${parseInt(uploadingPercentage)}%`}
        //                         styles={buildStyles({
        //                           rotation: 0.75,
        //                           // strokeLinecap: 'round',
        //                           textSize: '16px',
        //                           fontWeight: 'bolder',
        //                           pathTransitionDuration: 0,
        //                           pathColor: `rgba(255, 122, 0, 1)`,
        //                           textColor: '#fff',
        //                         })}
        //                       />
        //                     </div>
        //                     : null}

        //                   <label htmlFor="file-input-video">
        //                     {isLoading ?
        //                       <div style={{ color: "#fff", marginTop: "10px" }}>
        //                         {isLoading ?
        //                           parseInt(uploadingPercentage) == 0 || uploadingPercentage == 0 ? 'Uploading'
        //                             : parseInt(uploadingPercentage) >= 90 ? 'Finalizing Upload'
        //                               : 'Uploading'
        //                           : 'Capture Now'
        //                         }
        //                       </div>
        //                       :
        //                       <div className="inspection-capture_now-button">
        //                         {aiLoading ? (
        //                           <ClipLoader color={'white'} size={20} />
        //                         ) : (
        //                           isLoading ? null
        //                             : 'Capture Now'
        //                         )}
        //                       </div>
        //                     }
        //                   </label>
        //                   <input
        //                     disabled={isLoading ? true : false}
        //                     type="file"
        //                     id="file-input-video"
        //                     accept="video/*"
        //                     capture
        //                     onChange={handleVideoUpload}
        //                   />
        //                 </>
        //               )
                      
        //               // :null
        //               }
        //             </div>
        //           </div>
        //         </>
        //       )}
        //     </div>
        //   </Modal>
        // )
        //  : (
          <Modal
            visible={isModalVisible}
            closable={isLoading ? false : true}
            title={false}
            style={{ width: '100%' }}
            wrapClassName="instruction-modalls "
            footer={null}
            onCancel={handleModalClose}
            mask={true}
            className="instruction-close-icon "
            // closeIcon={false}




            maskClosable={isLoading ? false : true}
            closeIcon={
              vehicleInstructionValues?.type === 'Photo' ? (
                <IoCloseCircleOutline color={'white'} size={40} />
              ) : (
                //video icon
                <IoCloseCircleOutline color={'white'} size={40} />
              )
            }
            maskStyle={{ backgroundColor: '#266FB7' }}
            bodyStyle={{
              width: '100%',
              textAlign: 'center',
              // background: 'linear-gradient(182.55deg, #1876CC -17.67%, #051532 115.19%), #191A1B',
            }}
          >
            <div
            // style={{
            // width: '100%',
            // background: '100%',
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // height: '100%',
            // }}
            >
              {vehicleInstructionValues?.url ? (
                vehicleInstructionValues?.type === 'Photo' ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => handleModalClose()}>
                      <IoCloseCircleOutline color={'white'} size={40} />
                    </div>
                    <div className="modal-images">
                      { vehicleInstructionValues?.id === 'vin-extraction' ?
                      <img
                        alt=""
                        className="modal-inside--image"
                        src={`${process.env.REACT_APP_AWS_S3_LINK}/${vehicleInstructionValues?.url}`}
                      />
                      :<img
                      alt=""
                      className="modal-inside-image"
                      src={`${process.env.REACT_APP_AWS_S3_LINK}/${vehicleInstructionValues?.url}`}
                    />}
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => handleModalClose()}>
                      <IoCloseCircleOutline color={'white'} size={40} />
                    </div>
                    <div className="video-container">
                      <video
                        preload="metadata"
                        id="myVideo"
                        autoPlay={false}
                        className="modal-video"
                        controls
                      >
                        <source
                          src={`${process.env.REACT_APP_AWS_S3_LINK}/${vehicleInstructionValues?.url}#t=0.5`}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '80px' }} onClick={() => { isDisabled === true ? null : handleModalClose() }}>
                        <IoCloseCircleOutline color={'white'} size={40} />
                      </div>

                      <div className="interior-header">
                        {vehicleInstructionValues?.title}
                      </div>
                      {vehicleInstructionValues?.title === 'Drivers License Verification' && vehicleInstructionValues?.id === 'verification_card' ?
                        <div>
                          <div className="lyft-must-field">
                            (For Lyft Only)
                          </div>
                        </div> : null}
                      <div >
                        {vehicleInstructionValues?.type === 'Photo' ? (
                          <>
                          <img alt="" className="modal-image modal-image-up" src={vehicleInstructionValues?.image} />
                        </>
                        ) : (
                          <video
                            preload="metadata"
                            id="myVideo"
                            autoPlay={false}
                            width="100%"
                            height="240"
                            className="modal-image modal-image-up"
                            controls
                            src={`${vehicleInstructionValues?.video}#t=0.5`}
                          >
                            <source src={`${vehicleInstructionValues?.video}#t=0.5`} type="video/mp4" />
                          </video>
                        )}
                        <div style={{ display: 'flex', marginTop: '20px' }}>
                          <div className="interior-circle">i</div>
                          <div className="instruction-modal-text">
                            {vehicleInstructionValues?.description}
                          </div>
                        </div>
                        <div
                          style={{ paddingLeft: '18px' }}
                          className="instruction-modal-step-container"
                        >
                          {vehicleInstructionValues?.steps.map((step, index) => (
                            <div style={{ lineHeight: 0, position: 'relative' }}>
                              <ul style={{ padding: 0, marginLeft: 10 }} key={index}>
                                <li>
                                  <div key={index} className="instruction-modal-step modal-image-up modal-image-down">{step}</div>
                                </li>

                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="input-img-container">
                      <div className="image-upload">
                        {
                        // isMobile?
                        vehicleInstructionValues?.type === 'Photo' ? (
                          <>
                            {isLoading ?
                              <div style={{ width: 111, height: 111, marginTop: 10 }}>
                                <CircularProgressbar
                                  value={parseInt(uploadingPercentage)}
                                  text={`${parseInt(uploadingPercentage)}%`}
                                  styles={buildStyles({
                                    rotation: 0.75,
                                    // strokeLinecap: 'round',
                                    textSize: '16px',
                                    fontWeight: 'bolder',
                                    pathTransitionDuration: 0,
                                    pathColor: `rgba(255, 122, 0, 1)`,
                                    textColor: '#fff',
                                  })}
                                />
                              </div>
                              : null}
                            <label htmlFor="file-input-photo">
                              {isLoading ?
                                <div style={{ color: "#fff", marginTop: "10px" }}>
                                  {isLoading ?
                                    parseInt(uploadingPercentage) == 0 || uploadingPercentage == 0 ? 'Uploading'
                                      : parseInt(uploadingPercentage) >= 90 ? 'Finalizing Upload'
                                        : 'Uploading'
                                    : 'Capture Now'
                                  }
                                </div>
                                :
                                <div className="inspection-capture_now-button">

                                  {aiLoading ? (
                                    <ClipLoader color={'white'} size={20} />
                                  ) : (
                                    isLoading ? null
                                      : 'Capture Now'
                                  )}
                                </div>
                              }
                            </label>
                            {
                              vehicleInstructionValues?.id === 'license_plate_number' 
                              && isMobile
                              ?
                                <input
                                  disabled={isLoading ? true : false}
                                  //  type="file"
                                  id="file-input-photo"
                                  accept="image/*"
                                  capture
                                  // onChange={handleImageUpload}
                                  onClick={handleImageControlvin}
                                /> :
                                <input
                                  disabled={isLoading ? true : false}
                                  type="file"
                                  id="file-input-photo"
                                  accept="image/*"
                                  capture
                                  // onChange={handleImageUpload}
                                  onChange={handleImageControl}
                                />
                            }

                            {vinwebcam === true

                              ?
                              <>
                                <div className="blurr--background">
                                </div>
                                <Webcam
                                  videoConstraints={imageConstraints}
                                  className='webCam--video' audio={false}
                                  screenshotFormat="image/png"
                                  screenshotQuality={0.9}
                                  style={{ width: '374px', height: '100px',marginLeft:'10px' }}

                                  ref={webRef} onUserMedia={handlecameraOpen}
                                 

                                />
                                <canvas style={{ display: 'none' }} ref={ captureAreaRef} />
                              </> :
                              null}
                            {!saveImage?
                            clickPic ?
                              WebCameraOpen ?
                                <div className='video-recording'>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className="outer-circle" >
                                      <FaCircle size={50} color="white" onClick={handleClickPicture} />
                                    </div>
                                    <div>

                                      <IoSyncOutline color='white' style={{ marginLeft: '70px' }} size={27} onClick={handleCamerarotation} />

                                    </div>
                                  </div>
                                </div>
                                : null
                              : null
                              :<div className='video-save-recording' >
                              <div className="outer--circle" >
                                <IoCheckmark size={50}  onClick={handleSaveImage}/>
                              </div>
                              <div className="outer--circle" >
                                <IoClose size={50}  onClick={handleImageControlvin} />
                              </div>
                            </div>
                            }
                            {capturedImage &&
                             <>  
                           
                             <div className="blurr--background">
                            </div> 
                             <div className='webCam--image'> 
                                <img src={capturedImage} alt="Captured" />
                                </div>
                                </>
                                }

                          </>
                        ) : (
                          <>
                            {isLoading ?
                              <div style={{ width: 111, height: 111 }}>
                                <CircularProgressbar
                                  value={parseInt(uploadingPercentage)}
                                  text={`${parseInt(uploadingPercentage)}%`}
                                  styles={buildStyles({
                                    rotation: 0.75,
                                    // strokeLinecap: 'round',
                                    textSize: '16px',
                                    fontWeight: 'bolder',
                                    pathTransitionDuration: 0,
                                    pathColor: `rgba(255, 122, 0, 1)`,
                                    textColor: '#fff',
                                  })}
                                />
                              </div>
                              : null}

                            <label htmlFor="file-input-video">
                              {isLoading ?
                                <div style={{ color: "#fff", marginTop: "10px" }}>
                                  {isLoading ?
                                    parseInt(uploadingPercentage) == 0 || uploadingPercentage == 0 ? 'Uploading'
                                      : parseInt(uploadingPercentage) >= 90 ? 'Finalizing Upload'
                                        : 'Uploading'
                                    : 'Capture Now'
                                  }
                                </div>
                                :
                                <div className="inspection-capture_now-button">
                                  {aiLoading ? (
                                    <ClipLoader color={'white'} size={20} />
                                  ) : (
                                    isLoading ? null
                                      : 'Capture Now'
                                  )}
                                </div>
                              }
                            </label>
                            <input
                              disabled={isLoading ? true : false}
                              type="file"
                              id="file-input-video"
                              accept="video/*"
                              capture
                              onChange={handleVideoUpload}
                            />
                          </>
                        )
                        // :null
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal>
        // )
      ) : null}
    </div >
  );
};

export default InstructionModal;
