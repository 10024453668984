/* eslint-disable */

import react from 'react';
import Moment from 'react-moment';

import './style.css';

import ProgressModal from '../ProgressModal';

const InProgressTileComponent = ({
  item,
  handleRedirect,
  loadingDiscard,
  handleDiscardInspection, }) => {
   
    return (
      
      <div>
<div style={{display:'flex',justifyContent:'center'}}>
  <div className="inspection-tile">
   
     <div className="progress-close-icon">
     <ProgressModal item={item} handleDiscardInspection={handleDiscardInspection} loadingDiscard={loadingDiscard} />
      </div>
    
    <div className="inspection-tile-content">
      <div>
        <div className="inspection-tracking-id-text">
        License Plate No.
            {/* Status */}
        </div>
        <div className="inspection-tracking-id">
          {item?.Vehicle?.licensePlateNumber}
          {/* {"In-Progress"} */}
        </div>

      </div>
      <div>
        <div className="inspection-date-created">
          Date Created
        </div>
        <div className="inspection-date">
        <Moment format="MM/DD/YYYY">
        {item?.createdAt}
        </Moment>
        </div>

      </div>
    </div>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <button className="inspection-button" onClick={() => handleRedirect(item)}>Continue</button>
    </div>
  </div>
  </div>
  </div>
);

}

export default InProgressTileComponent;