import React, { useState } from 'react';
// import { IoIosArrowBack } from 'react-icons/io';
// import { useHistory } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import SideBar from '../SideBar';
import './style.css';

import LogoutModal from '../LogoutModal';

const Header = ({ title, arrow, path }) => {
  // const history = useHistory();
  const [sideBar, setSideBar] = useState(false);

  const handleSideBar = (value) => {
    setSideBar(value);
  };

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const showLogoutModal = () => {
    setModal(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('vehicleData');
    localStorage.removeItem('tabStatus');
    localStorage.removeItem('promoCode');
    localStorage.removeItem('companyId');
    // localStorage.removeItem('ReferralCode')
    window.location.reload(false);

    window.location.replace('/login');
  };

  // const handleBackArrow = (path) => {
  //   history.push(`${path}`);
  // };

  return (
    <div>
      <div className="header-center">
        <div className="header-width">
          {/* <div className="icon-container">
          <i className="fas fa-chevron-left fa-2x" style={{ opacity: 0 }} color="#FFFFFF" />
        </div> */}
          <div className="vec-inspection-top_header">
            {/* {!arrow ? ( */}
            <div className="icon-container">
              <div style={{ width: '100%' }} />
            </div>
            {/* ) : path ? (
            <IoIosArrowBack size={32} color="white" onClick={() => handleBackArrow(path)} />
          ) : (
            <IoIosArrowBack size={32} color="white" />
          )} */}
            {title && title === true ?
              <p className="veh-inspection-chexai_text">
                CHEX.<span style={{ color: '#FF7A00' }}>AI</span>
              </p>
              : null
            }
            <div onClick={() => handleSideBar(true)}>
              <div className="menu-icon">
                <FiMenu size={32} color="white" />
              </div>
            </div>
          </div>
          <span className="nav-back-arrow" />
          <SideBar handleSideBar={handleSideBar}  drawerVisible={sideBar} showLogoutModal={showLogoutModal} />
        </div>
      </div>
      {modal ? <LogoutModal logout={true} modal={modal} setModal={setModal} setLoading={setLoading} loading={loading} handleSubmit={handleLogout} /> : null}
    </div>
  );
};

export default Header;
