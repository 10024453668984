/* eslint-disable */

import React from 'react';
import './style.css';
import { AiFillCamera } from 'react-icons/ai';
import { FaPlay } from 'react-icons/fa';
// import { AiOutlineUpload } from 'react-icons/ai';
// import { MdPlayArrow } from 'react-icons/md';
// import { AiFillDelete, AiOutlineClose } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
// import VideoThumbnail from 'react-video-thumbnail';

const InspectionCard = ({
  title,
  titletwo,
  handleModal,
  // type,
  // category,
  item,
  groupType,
  deleteFile,
                            isModalVisible
}) => {

  return (
    <div style={{ cursor: 'pointer' }}>

      <div  style={{ background: 'none' }}>
        {item.url ? (
          item.type === 'Photo' ? (
            <div  style={{ position: 'relative' }}>
              <img
                onClick={() => handleModal(item, groupType)}
                alt=""
                className="inspection-image"
                src={`${process.env.REACT_APP_AWS_S3_LINK}/${item.url}`}
              />
              {/* <div
                
              > */}
                <div className="inspection-card-close_icon" onClick={() => deleteFile(item.groupType, item.fileId)} >
                  <IoClose color="#fff" size={14} />
                </div>
              </div>
            // </div>
          ) : (
            <div style={{ position: 'relative' }}>
              <video 
                onClick={() => handleModal(item, groupType)} preload="metadata" autoPlay={false} className="inspection-image">
                <source
                  src={`${process.env.REACT_APP_AWS_S3_LINK}/${item.url}#t=0.5`}
                  type="video/mp4"
                />
              </video>
              <div >
                <div
                >
                  <div className="inspection-card-close_icon" onClick={() => deleteFile(item.groupType, item.fileId)}
                  >
                    <IoClose color="#fff" size={14} />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div onClick={() => handleModal(item, groupType)} className="vec-inspection-inspection_card">
            <div className="vec-inspection-camera_icon"></div>
            <div className="veh-inspection-downloadicon_center">
              <div className="veh-inspection-download_icon">
                {item?.type == "Photo" ?
                  <AiFillCamera size={22} color="#97A9C5" />
                  : item?.type == "Video" ?
                    <FaPlay style={{ marginLeft: 5 }} size={18} color="#97A9C5" />
                    : null}
              </div>
              <div style={{ color: '#1468BA', fontSize: '10px', marginTop: '4px', fontWeight: 600 }}>
                {item?.type === "Photo" ? "Upload Image" : item?.type === "Video" ? "Upload Video" : null}
              </div>
            </div>

          </div>
        )}

      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p className="veh-inspection-registrationcard_text">{title}</p>
        {/* {item.url ? (
          // <div
          //   style={{ marginLeft: '5px', marginTop: '10px' }}
          //   onClick={() => deleteFile(item.groupType, item.fileId)}
          // >
          //   <AiFillDelete color="#C84A03" size={16} />
          // </div>
        ) : null} */}
      </div>
      <p className="veh-inspection-photo_text">{titletwo} </p>

    </div>
  );
};

export default InspectionCard;
