/* eslint-disable */
import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Select } from 'antd';
import { TiTick } from 'react-icons/ti';

import {
  Header,
  LogoutModal
} from '../../Components';
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentFooterareaInspectionInstruction,
  MainBgInsertDetails,
} from './style.js';
import './style.css';

const SelectCompanyScreen = ({
  // companies,
  stateEmpty,
  checkEmpty,
  handleState,
  numberEmpty,
  setStateEmpty,
  loadingcompny,
  submitLoading,
  stateCompanies,
  setSubmitLoading,
  inspectionChecked,
  alreadyExistModal,
  inspectionErrorResp,
  setAlreadyExistModal,
  handleInspectionCheck,
  handleCreateInspection,
  handleLicensePlateNumber,
  handleSubmitAlreadyExist,
  reInspectionPlateNumber,
  handleAutoReInspection,
  reInspectionState,
  inspectionState,
  licensePlateNumber,
  loadingSelect,
  setNumberEmpty,
  setCheckEmpty,
  reInspection,
                               handleEditInspection,
                               getInspectionDetailData,
                               detailLoading,
                               handleAddNewCompany,
                               selectedCompanies,
                             filteredCompaniesSelected,
                               filteredCompanies,addToast
}) => {
  const { Option } = Select;
  let filterArray = stateCompanies;
  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <div className="select-company-header">
            <div className="select-company-header-content">
              <Header title={true} />
            </div>
          </div>
          <MainDownContainer>
            <ContentFooterareaInspectionInstruction className={detailLoading ? 'overlayLoader' : ''}>
              {detailLoading ?
                  (
                      <div style={{position: 'absolute', top:'50%', left: '48%', zIndex: 1}}>
                        <ClipLoader color={'#FF7A00'} />
                      </div>
                  ): null}

              {reInspection === 'true' ? <div className="companyInstructionHeading">Re-Inspection</div> : <div className="companyInstructionHeading">New Inspection</div>}
              <div>
                {/* <i>
                  <div className="select-company-inspection-price">
                    <b>$24.99</b> for first inspection and <b>$14.99</b> for each additional selection
                  </div>
                </i> */}
                {reInspection === 'true' ? (
                  <>
                    <div className="select-company-heading">License Plate Number </div>
                    <div className="license-plate">
                      <input type="text" disabled={true} placeholder={reInspectionPlateNumber} className="license-plate-text" />
                    </div>
                  </>
                ) : reInspection === 'edit' ?
                    (
                        <>
                          <div className="select-company-heading">License Plate Number </div>
                          <div className="license-plate">
                            <input type="text"
                                   disabled={false}
                                   placeholder="License Plate Number"
                                   value={licensePlateNumber}
                                   className="license-plate-text"
                                   onChange={(number) => {
                                     handleLicensePlateNumber(number.target.value);
                                     setNumberEmpty(false);
                                   }}
                            />
                          </div>
                        </>
                    ) : reInspection === 'addCompany' ?
                        (
                            <>
                              <div className="select-company-heading">License Plate Number </div>
                              <div className="license-plate">
                                <input type="text"
                                       disabled={true}
                                       placeholder="License Plate Number"
                                       value={licensePlateNumber}
                                       className="license-plate-text"
                                       onChange={(number) => {
                                         handleLicensePlateNumber(number.target.value);
                                         setNumberEmpty(false);
                                       }}
                                />
                              </div>
                            </>
                        )

                    : reInspection === 'false' ? (
                  <>
                    {numberEmpty === true ? (
                      <div className="select-company-heading" 
                      // style={{ color: 'red' }}
                      >
                        Select Car License Plate Number
                      </div>
                    ) : (
                      <div className="select-company-heading">Select Car License Plate Number</div>
                    )}
                    <div className="license-plate">
                      <input
                        type="text"
                        value={licensePlateNumber}
                        placeholder="License Plate Number"
                        className="license-plate-text"
                        onChange={(number) => {
                          handleLicensePlateNumber(number.target.value);
                          setNumberEmpty(false);
                        }}
                      />
                    </div>
                  </>
                ) : null}


                <div>
                  {/* {checkEmpty === true ? (
                    <div className="select-company-heading" style={{ color: 'red' }}>
                      Select Car License Plate Number
                    </div>
                  ) : (
                    <div className="select-company-heading">Select Car License Plate Number</div>
                  )} */}
                  <div className="select-company-checks_container">
                    {
                    // inspectionState === "" && 
                    reInspection === 'false' ? (
                      <div style={{ marginBottom: 35}}>
                       { loadingcompny ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '300px',
                        }}
                      >
                        <ClipLoader color={'#246DB5'} size={20} />
                      </div>
                    )
                      :null}
                      {stateCompanies?.map((company) => {
                        const isSelected = inspectionChecked === company;
                        return (
                            <>
                              {/* {company?.displayOnFront === true ? (
                                  <div
                                      className="select-company-checkbox-container"
                                      onClick={() => {
                                        handleInspectionCheck(company?.id);
                                        setCheckEmpty(false);
                                      }}
                                      key={company?.id}
                                  >
                                    <>
                                      <div className="company-name-text">{company?.name}</div>
                                      {
                                        inspectionChecked.includes(company?.id) ? (
                                                <TiTick className="tick-background-color" color="#FF7C00" size={22} />
                                            ) : (
                                                <div className="tick-background-color"></div>
                                            )

                                      }
                                    </>
                                  </div>
                              ) : null} */}
                              {/* {company} */}
                              <div
                                                  className="select-company-checkbox-container"
                                                  onClick={() => {
                                                    handleInspectionCheck(company);
                                                    setCheckEmpty(false);
                                                  }}
                                                  key={company}
                                              >
                              <div className="company-name-text">
                                                    {company}
                                                  </div>
                                                  {isSelected ? (
                                                      <TiTick className="tick-background-color" color="#FF7C00" size={22} />
                                                  ) : (
                                                      <div className="tick-background-color"></div>
                                                  )}
                                                  </div>
                            </>
                        );
                      })}
                    </div>
                    //   <div
                    //   style={{
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     alignItems: 'center',
                    //     height: '300px',
                    //   }}
                    // >
                      // {/* Select Inspection State First */}
                    // {/* </div> */}
                    ): reInspection === 'edit' ?
                        (
                            <>
                              <div style={{ marginBottom: 35}}>
                                {stateCompanies?.map((company) => {
                                  return (
                                      <>
                                        {company?.displayOnFront === true ? (
                                            <div
                                                className="select-company-checkbox-container"
                                                onClick={() => {
                                                  handleInspectionCheck(company?.id);
                                                  setCheckEmpty(false);
                                                }}
                                                key={company?.id}
                                            >
                                              <>
                                                <div className="company-name-text">{company?.name}</div>
                                                {
                                                  inspectionChecked.includes(company?.id) ? (
                                                          <TiTick className="tick-background-color" color="#FF7C00" size={22} />
                                                      ) : (
                                                          <div className="tick-background-color"></div>
                                                      )

                                                }
                                              </>
                                            </div>
                                        ) : null}
                                      </>
                                  );
                                })}
                              </div>
                            </>
                        )
                        : reInspection === 'addCompany' ?
                            (
                                <>
                                  <div style={{ marginBottom: 35}}>
                                    {
                                      filteredCompanies()?.map((company)=> {
                                        return(
                                            <>
                                              <div
                                                  className="select-company-checkbox-container"
                                                  onClick={() => {
                                                    handleInspectionCheck(company?.id);
                                                    setCheckEmpty(false);
                                                  }}
                                                  key={company?.id}
                                              >
                                                <>
                                                  <div className="company-name-text">
                                                    {company?.name}
                                                  </div>
                                                  {inspectionChecked.includes(company?.id) ? (
                                                      <TiTick className="tick-background-color" color="#FF7C00" size={22} />
                                                  ) : (
                                                      <div className="tick-background-color"></div>
                                                  )}
                                                </>
                                            </div>
                                            </>
                                        )

                                      })
                                    }

                                    {
                                      filteredCompaniesSelected()?.map((company)=> {
                                        return(
                                            <>
                                              <div
                                                  className="select-company-checkbox-container"

                                                  key={company?.id}
                                              >
                                                <>
                                                  <div className="company-name-text" style={{color: 'gray'}}>
                                                    {company?.name}
                                                  </div>

                                                      <TiTick className="tick-background-color" color="gray" size={22} />

                                                </>
                                              </div>
                                            </>
                                        )

                                      })
                                    }
                                  </div>
                                </>
                            )

                        : loadingcompny ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '300px',
                        }}
                      >
                        <ClipLoader color={'#246DB5'} size={20} />
                      </div>
                    ) : (
                      <div style={{ marginBottom: 35}}>
                      {stateCompanies?.map((company) => {
                        return (
                          <>
                          {/* {company?.displayOnFront === true ? (
                                  <div>
                          <div
                            className="select-company-checkbox-container"
                            onClick={() => {
                              handleInspectionCheck(company?.id);
                              setCheckEmpty(false);
                            }}
                            key={company?.id}
                          >
                              <>
                                <div className="company-name-text">{company?.name}</div>

                                {inspectionChecked.includes(company?.id) ? (
                                    <TiTick className="tick-background-color" color="#FF7C00" size={22}/>
                                ) : (
                                    <div className="tick-background-color"></div>
                                )
                                }
                              </>
                          </div>

                                  </div>
                            ) : null} */}
                            <div
                                                  className="select-company-checkbox-container"
                                                  onClick={() => {
                                                    handleInspectionCheck(company);
                                                    setCheckEmpty(false);
                                                  }}
                                                  key={company}
                                              >
                              <div className="company-name-text">
                                                    {company}
                                                  </div>
                                                  {inspectionChecked.includes(company) ? (
                                                      <TiTick className="tick-background-color" color="#FF7C00" size={22} />
                                                  ) : (
                                                      <div className="tick-background-color"></div>
                                                  )}
                                                  </div>
                            </>
                        );
                      })}
                      </div>
                    )}

                  </div>
                </div>
                <div className="inpection-instruction-btn-container">
                  {reInspection === 'true' ? (
                    <>
                      {inspectionChecked.length !== 0 && checkEmpty === false ? (
                        <div className="inspection-btn-align">
                          <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '21px',
                                }}
                              >
                                <ClipLoader color={'#246DB5'} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : 
                       inspectionChecked.length !== 0 &&  reInspectionPlateNumber === '' ? (
                        <div className="inspection-btn-align">
                        <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                          {loadingSelect ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '21px',
                              }}
                            >
                              <ClipLoader color={'#246DB5'} size={20} />
                            </div>
                          ) : (
                            <div className="select-text">Select</div>
                          )}
                        </div>
                      </div>
                      ) : inspectionChecked.length === 0  && reInspectionPlateNumber !== '' ? (
                        <div className="inspection-btn-align">
                        <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                          {loadingSelect ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '21px',
                              }}
                            >
                              <ClipLoader color={'#246DB5'} size={20} />
                            </div>
                          ) : (
                            <div className="select-text">Select</div>
                          )}
                        </div>
                      </div>
                      ) : inspectionChecked.length !== 0 && reInspectionPlateNumber !== '' ? (
                        <div className="inspection-btn-align">
                          <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '21px',
                                }}
                              >
                                <ClipLoader color={'#246DB5'} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : 
                      
                      inspectionChecked.length === 0  && reInspectionPlateNumber === '' ? (
                       
                        <div className="inspection-btn-align">
                           <div
                            className="inspection-btn-container"
                            onClick={() => {
                              addToast(`Please complete at least one of the two fields before proceeding`, { appearance: 'error' })
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) :null
                      // (
                      //   <div className="inspection-btn-align">
                      //     <div
                      //       className="inspection-btn-container"
                      //       onClick={() => {
                      //         setCheckEmpty(true);
                      //       }}
                      //     >
                      //       <div className="select-text">Select</div>
                      //     </div>
                      //   </div>
                      // )
                      }
                    </>
                  ) : reInspection === 'edit' ?
                      (
                          <>
                            {inspectionChecked.length !== 0 && checkEmpty === false ? (
                                <div className="inspection-btn-align">
                                  <div className="inspection-btn-container" onClick={() => handleEditInspection(reInspection)}>
                                    {loadingSelect ? (
                                        <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: '21px',
                                            }}
                                        >
                                          <ClipLoader color={'#246DB5'} size={20} />
                                        </div>
                                    ) : (
                                        <div className="select-text">Select</div>
                                    )}
                                  </div>
                                </div>
                            ) : (
                                <div className="inspection-btn-align">
                                  <div
                                      className="inspection-btn-container"
                                      onClick={() => {
                                        setCheckEmpty(true);
                                      }}
                                  >
                                    <div className="select-text">Select</div>
                                  </div>
                                </div>
                            )}
                          </>
                      ) : reInspection === 'addCompany' ?
                          (
                              <>
                                {inspectionChecked.length !== 0 && checkEmpty === false ? (
                                    <div className="inspection-btn-align">
                                      <div className="inspection-btn-container" onClick={() => handleAddNewCompany(reInspection)}>
                                        {loadingSelect ? (
                                            <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  height: '21px',
                                                }}
                                            >
                                              <ClipLoader color={'#246DB5'} size={20} />
                                            </div>
                                        ) : (
                                            <div className="select-text">Select</div>
                                        )}
                                      </div>
                                    </div>
                                ) : (
                                    <div className="inspection-btn-align">
                                      <div
                                          className="inspection-btn-container"
                                          onClick={() => {
                                            setCheckEmpty(true);
                                          }}
                                      >
                                        <div className="select-text">Select</div>
                                      </div>
                                    </div>
                                )}
                              </>
                          )
                      : reInspection === 'false' ? 
                      (
                    <>
                      {
                      inspectionChecked.length !== 0 &&
                      checkEmpty === false &&
                      numberEmpty === false &&
                      licensePlateNumber !== '' 
                      // &&
                      // inspectionState !== '' &&
                      // stateEmpty === false 
                      ?
                       (
                        <div className="inspection-btn-align">
                          <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '21px',
                                }}
                              >
                                <ClipLoader color={'#246DB5'} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : inspectionChecked.length !== 0 &&  licensePlateNumber === '' ? (
                        <div className="inspection-btn-align">
                        <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                          {loadingSelect ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '21px',
                              }}
                            >
                              <ClipLoader color={'#246DB5'} size={20} />
                            </div>
                          ) : (
                            <div className="select-text">Select</div>
                          )}
                        </div>
                      </div>
                      ) : inspectionChecked.length === 0  && licensePlateNumber !== '' ? (
                      //   <div className="inspection-btn-align">
                      //   <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                      //     {loadingSelect ? (
                      //       <div
                      //         style={{
                      //           display: 'flex',
                      //           justifyContent: 'center',
                      //           alignItems: 'center',
                      //           height: '21px',
                      //         }}
                      //       >
                      //         <ClipLoader color={'#246DB5'} size={20} />
                      //       </div>
                      //     ) : (
                      //       <div className="select-text">Select</div>
                      //     )}
                      //   </div>
                      // </div>
                      <div className="inspection-btn-align">
                      <div
                       className="inspection-btn-container"
                       onClick={() => {
                         addToast(`Please select a License Plate Number from the options before proceeding.`, { appearance: 'error' })
                       }}
                     >
                       <div className="select-text">Select</div>
                     </div>
                   </div>
                      ) : inspectionChecked.length !== 0 &&  licensePlateNumber !== '' ? (
                        <div className="inspection-btn-align">
                          <div className="inspection-btn-container" onClick={() => handleCreateInspection(reInspection)}>
                            {loadingSelect ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  height: '21px',
                                }}
                              >
                                <ClipLoader color={'#246DB5'} size={20} />
                              </div>
                            ) : (
                              <div className="select-text">Select</div>
                            )}
                          </div>
                        </div>
                      ) : 
                      // inspectionChecked.length === 0 && inspectionState === '' && licensePlateNumber !== '' ? (
                      //   <div className="inspection-btn-align">
                      //     <div
                      //       className="inspection-btn-container"
                      //       onClick={() => {
                      //         setCheckEmpty(true);
                      //         setStateEmpty(true);
                      //       }}
                      //     >
                      //       <div className="select-text">Select</div>
                      //     </div>
                      //   </div>
                      // ) 
                      // : 
                      inspectionChecked.length === 0  && licensePlateNumber === '' ? (
                        // addToast(`please fill any one of the field`, { appearance: 'error' })
                        <div className="inspection-btn-align">
                           <div
                            className="inspection-btn-container"
                            onClick={() => {
                              addToast(`Please pick a License Plate Number from the options before proceeding.`, { appearance: 'error' })
                            }}
                          >
                            <div className="select-text">Select</div>
                          </div>
                        </div>
                      ) 
                      // : 
                      // inspectionChecked.length !== 0 && inspectionState === '' && licensePlateNumber === '' ? (
                      //   <div className="inspection-btn-align">
                      //     <div
                      //       className="inspection-btn-container"
                      //       onClick={() => {
                      //         setStateEmpty(true);
                      //         setNumberEmpty(true);
                      //       }}
                      //     >
                      //       <div className="select-text">Select</div>
                      //     </div>
                      //   </div>
                      // ) : (
                      //   <div className="inspection-btn-align">
                      //     <div
                      //       className="inspection-btn-container"
                      //       onClick={() => {
                      //         setCheckEmpty(true);
                      //         setNumberEmpty(true);
                      //         setStateEmpty(true);
                      //       }}
                      //     >
                      //       <div className="select-text">Select</div>
                      //     </div>
                      //   </div>
                      // )
                    : null }
                    </>
                  )
                   : null}
                </div>
              </div>

              <LogoutModal 
              logout={false} 
              loading={submitLoading}
              modal={alreadyExistModal} 
              setLoading={setSubmitLoading} 
              setModal={setAlreadyExistModal} 
              handleDeny={handleAutoReInspection}
              handleSubmit={handleSubmitAlreadyExist} 
              errorMessage={inspectionErrorResp?.errorMessage} 
              />
            </ContentFooterareaInspectionInstruction>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
    </MainContainer>
  );
};
export default SelectCompanyScreen;