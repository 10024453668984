/* eslint-disable */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import ActionCreators from '../../actions';
import { ThankyouScreen } from '../../Screens';
import axios from "axios";
// import {Api} from "../../services/configs";

const headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
};
const ThankyouContainer = () => {
  const history = useHistory();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // axios
    //     .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/generate/referral`, { headers })
    //     .then((resp) => {
    //     })
    //     .catch((err) => {
    //     });



  });
  const handleNext = () => {
    window.location.href = '/selectoption';
  };

  return <ThankyouScreen handleNext={handleNext} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ThankyouContainer);
