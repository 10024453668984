/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
// import { Collapse } from 'antd';
// import { UpCircleOutlined } from '@ant-design/icons';
import { AiOutlinePlus } from 'react-icons/ai';


import carInspectionImg from '../../Assets/carInspectionImg.png';
import { Header } from '../../Components';
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentVehicleInspection,
  ContentFooterareaInspectionInstruction,
  MainBgInsertDetails,
} from './style.js';
import './style.css';


const InspectionInstructionScreen = () => {
  const history = useHistory();
  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <ContentVehicleInspection>
            <Header title={true} />
          </ContentVehicleInspection>

          <MainDownContainer>
            <ContentFooterareaInspectionInstruction>
              <div className="inspectionInstructionHeading">Before You Start</div>
              <div>
                <div className="inspectionInstructionContentContainer">
                  <div>
                    <img className="carinspectionInstructionImg" src={carInspectionImg} />
                  </div>
                  <div
                    style={{
                      background: 'white',
                      padding: 10,
                      paddingBottom: 70,
                      position: 'relative',
                      borderRadius: '5px'
                    }}
                  >
                    <div>
                      <div>
                        {/* <div className="inspectionInstructionContentHeading">Minimum Vehicle Requirement</div>
                        <div className="inspectionInstructionContentText">
                          <ul>
                            <li>15 year old vehicle or newer (10 year old or newer for HopSkipDrive)</li>
                            <li>4-door vehicle</li>
                            <li>Good condition with no major cosmetic damage</li>
                            <li>No commercial branding (Uber & Lyft only)</li>
                          </ul> */}
                          {/* A photo of your vehicle's VIN can be used in absence of the card. For <b style={{fontStyle:'italic',marginRight:'4px'}}>Lyft</b>
                          inspections, you will be required to take a photo of your drivers license
                          card. */}
                        {/* </div> */}
                      </div>
                      <div className="inspectionInstructionContentStylings">
                        <div className="inspectionInstructionContentHeading">Camera Access for the Mobile Browser</div>
                        <div className="inspectionInstructionContentText">
                        {/* Your camera may be disabled in your phone settings. 
                        In order to make sure your camera is functional,
                        please follow the steps below: */}
                        In some cases your camera may not work to upload items. In that case don’t worry, follow the steps below in your phone settings:

                          {/* Place an object to prop your phone against in order to record rear
                          videoclip. Having someone to hold your phone would be ideal. */}
                        </div>
                      </div>
                      <div className="inspectionInstructionContentStyling">
                        <div className="inspectionInstructionContent">{"Settings > Browser (Chrome, Firefox etc) > Allow Camera"}</div>

                      </div>
                      <div className="inspectionInstructionContentStyling">
                        <div className="inspectionInstructionContentHeading">Things you will require</div>
                        <div className="inspectionInstructionContentText">
                        <ul>
                            {/* <li>Vehicle Registration Card (Photo of VIN may suffice)</li> */}
                            <li style={{marginLeft:'-10px'}}>A Penny </li>
                            {/* <li>An object large enough to hold a phone (for rear video)</li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inpection-instruction-btn-container">
                  <div className="inspection-btn-align">
                    <div
                      className="inspection-btn-container"
                      onClick={() => {
                        history.push(`/selectCompany/${false}`);
                      }}
                    >
                      <AiOutlinePlus color="white" size={22} />
                      <div className="inspections-btn-text">New Inspection</div>
                    </div>
                  </div>
                </div>
              </div>
            </ContentFooterareaInspectionInstruction>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
    </MainContainer>
  );
};
export default InspectionInstructionScreen;