import React from 'react'
import './style.css'
import {Col, Row} from "antd";

const AboutChexComponent = () =>{
    return(
        <div>
            <section className='new_about_section_main'>

                <Row>
                    <Col xl={24} md={24} sm={24}>
                        <div className='about_Content_section_wrapper'>
                            <h2 className='about-chex-left-title_main_new'>About Chex<span className='about-chex-left-title-span_main_new'>.AI</span></h2>
                            <p className="paraAbout_new">Chex.AI is an all-in-one solution customized for vehicle inspections. Our technology removes the need for scheduled appointments and
                                expensive reviews. Inspections are completed same day by a push of a button!</p>

                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col xl={24} md={24} sm={24}>
                        <div className='Chex_video_wrapper'>
                            <video
                                poster='https://res.cloudinary.com/dgjordf6e/image/upload/v1666085419/banner_image_poster_a2pplr.png'
                                type="video/mp4" playsInline
                                controls={true}
                                className='chex-demo-video_custom'
                            >
                                <source src="https://res.cloudinary.com/dgjordf6e/video/upload/v1665471106/Image_from_iOS_tjfodu_q5g3nw.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                </Row>
            </section>

        </div>
    )
}
export default AboutChexComponent