/* eslint-disable */

import axios from 'axios';
import * as types from '../utils/constants';
import { Api } from '../services/configs';

const headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
  
};

function setVehicleLoading(data) {
  return {
    type: types.IS_Vehicle_LOADING,
    isVehicleLoading: data,
  };
}

function setCarVerificationItems(data) {
  return {
    type: types.SET_CAR_VERIFICATION_ITEMS,
    carVerificationItem: data,
  };
}


function setExteriorItems(data) {
  return {
    type: types.SET_EXTERIOR_ITEMS,
    exteriorItems: data,
  };
}

function setTires(data) {
  return {
    type: types.SET_TIRES,
    tires: data,
  };
}

function setTire(data) {
  return {
    type: types.SET_TIRE,
    tires: data,
  };
}

function setDeleteLocalFile(data) {
  return {
    type: types.SET_DELETE_LOCAL_FILE,
    deleteFileData: data,
  };
}

function setVehicleStatus(data) {
  return {
    type: types.SET_VEHICLE_STATUS,
    vehicleStatus: data,
  };
}

function setInspectionByStatus(data) {
  return {
    type: types.SET_INSPECTION_BY_STATUS,
    inspectionByStatus: data,
  };
}


function setCertificateData(data) {
  return {
    type: types.SET_CERTIFICATE_DATA,
    vehicleCertificate: data,
  };
}

function setTUROCertificateData(data) {
  return {
    type: types.SET_TURO_CERTIFICATE_DATA,
    turoVehicleCertificate: data,
  };
}

function setUberCertificate(data) {
  return {
    type: types.SET_UBER_CERTIFICATE_DATA,
    uberVehicleCertificate: data,
  };
}

function setgetaroundCertificate(data) {
    return {
        type: types.SET_GetAround_CERTIFICATE_DATA,
        getAroundCertificate: data,
    };
}

function setLyftCertificate(data) {
  return {
    type: types.SET_LYFT_CERTIFICATE_DATA,
    lyftVehicleCertificate: data,
  };
}

function setVehicleDetails(data) {
  return {
    type: types.SET_VEHICLE_DETAILS,
    vehicleDetails: data,
  };
}

function setFileDetails(data) {
  return {
    type: types.SET_FILE_DETAILS,
    fileDetails: data,
  };
}
function setCompaniesByState(data) {
  return {
    type: types.SET_COMPANIES_BY_STATE,
    stateCompanies: data,
  };
}

export function uploadToAi(body, type, setAiLoading, setAiStatus, setIsImageModal, handleImageFinalize, imageFile) {
  setAiLoading(true);
  return (dispatch) => {
    const header = {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
    axios
      .post(`http://ec2-44-200-53-223.compute-1.amazonaws.com:8000/categories_classification?type=${type}`, body, {
        header,
      })
    .then((resp) => {
      setAiStatus(resp?.data);
      setAiLoading(false);
      if(resp?.data?.meta?.status === "pass" && resp?.data?.score >= 95){
        if(resp?.data?.class === type)
        {
          setIsImageModal(false);
          handleImageFinalize(imageFile);
        }
        else {
          setIsImageModal(true);
        }
      }
      else{
        setIsImageModal(true);
      }
    })
    .catch((err) => {
      setAiLoading(false);
    });
  }}

  export function uploadVideoToAi(body, setIsVideoModal, setAiLoading, videoFile, type, id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled) {

    setAiLoading(true);
    return (dispatch) => {
      const header = {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      if(imageCategory === "exterior_rear" ){
        axios
        .post(`http://f2ec-123-108-92-86.ngrok.io/car_backlights_classification`, body, {
          header,
        })
      .then((resp) => {
        if(resp?.data[0] === 'lightsOn')
        {
          dispatch(uploadFile(videoFile, type, id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled));
        }
        else{
          setIsVideoModal(true);
        }
        setAiLoading(false);
      })
      .catch((err) => {
        setAiLoading(false);
      });
    }
    else if (imageCategory === "horn" ){
      axios
        .post(`http://f2ec-123-108-92-86.ngrok.io/car_backlights_classification`, body, {
          header,
        })
      .then((resp) => {
        // if(resp?.data[0] === 'lightsOn')
        // {
        //   dispatch(uploadFile(videoFile, type, id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled));
        // }
        // else{
        //   setIsVideoModal(true);
        // }
        setAiLoading(false);
      })
      .catch((err) => {
        setAiLoading(false);
      });
    }
    else{
      axios
        .post(`http://f2ec-123-108-92-86.ngrok.io/car_backlights_classification`, body, {
          header,
        })
      .then((resp) => {
        // if(resp?.data[0] === 'lightsOn')
        // {
        //   dispatch(uploadFile(videoFile, type, id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled));
        // }
        // else{
        //   setIsVideoModal(true);
        // }
        setAiLoading(false);
      })
      .catch((err) => {
        setAiLoading(false);
      });
    }
    }}


    
export function getCarDetails(body,setLoadingcompny) {
  setLoadingcompny(true);
  return (dispatch) => {
    // const body={}
    axios
      .post(`${Api}/searchnumberplate`,body )
      .then((resp) => {
        dispatch(setCompaniesByState(resp.data));
        setLoadingcompny(false);
      })
      .catch((err) => {
        setLoadingcompny(false);
      });
  };
}

export function uploadFile(file, params, vehicle_id, category, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, lat, lng, addToast, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError) {

  return (dispatch) => {
    dispatch(setVehicleLoading(true));

    setDisabled(true);
    axios
      .post(`${Api}/file/upload`, params, {
        headers,
      })
      .then((resp) => {

        const { key, url } = resp.data;
        dispatch(uploadToS3(file, key, url, vehicle_id, category, params.type, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, lat, lng,addToast, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError));
      })
      .catch((err) => {
      });
  };
}

export function uploadToS3(file, key, uploadUrl, vehicle_id, category, ext, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, latitude, longitude,addToast, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError) {

  return (dispatch) => {
    const headers = {
      'Content-Type': 'image/png',
    };

    axios
      .put(`${uploadUrl}`, file, {
        headers,
        onUploadProgress: imageUploadingProgress,
      })
      .then((resp) => {

        // const date = ;
        const params = { url: key, vehicle_id, category, extension: ext, groupType: groupType, dateImage, latitude, longitude };
        dispatch(addFileInDB(params,addToast, setModalValue, setDisabled, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError));
      })
      .catch((err) => {
      });
  };
}

export function addFileInDB(params, addToast,setModalValue, setDisabled, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError) {

  return (dispatch) => {
    axios
      .post(`${Api}/vehicle/${params.vehicle_id}/file`, params, { headers })
      .then((resp) => {
        
        dispatch(getVehicleFile(params.vehicle_id, setModalValue, setDisabled, setSendEmailData));
        if(params?.category==='license_plate_number')
        {
          dispatch(getLicenseExtractionData(params?.url,addToast,setVehicleDetailModal,setInitialFieldValues,setFetchError))
        }
        if(params?.groupType==='tires' )
        {
          const islocation=true
          dispatch(SaveTireLocation(params?.vehicle_id,islocation))
        }
        else if(params?.groupType==='tire')
        {
          const islocation=false;
          dispatch(SaveTireLocation(params?.vehicle_id,islocation))
        }

      })
      .catch((err) => {

      });
  };
}


export function SaveTireLocation (id,islocation) {

  return (dispatch) => {
axios.put(`${Api}/inspection/location`, {
  isLocation:islocation,
  inspectionId:id
})

.then(response => {
})
.catch(error => {
 
});
  };
}

export function getLicenseExtractionData(params,addToast,setVehicleDetailModal,setInitialFieldValues,setFetchError) {

  return (dispatch) => {
    const imageUrl = params;
    const header = {
      "api_token": "FF5SE7GZULOP0CZQQ180KMZER",
      
    };
axios.post('https://cardamage.chex.ai/license-plate-number-extraction/', {
  image_url: `https://chex-ai-uploads.s3.amazonaws.com/${imageUrl}`
}, {
  headers: header
})
.then(response => {
 const license= localStorage.getItem('licensePlateNumber')
 if(response?.data?.detail  ==='plateNumber not found in the image.')
 {
  setVehicleDetailModal(true);
  setFetchError(true);
 }
else if(license!==response?.data?.plateNumber)
 {
  setVehicleDetailModal(true);
 }
  
})
.catch(error => {
  setVehicleDetailModal(true);
  setFetchError(true);
});
  };
}

export function SaveVehicleInspectionDetail(params, id,setVehicleDetailModal,setloadingDetail,addToast,  setFetchError,) {

  return (dispatch) => {


setloadingDetail(true)
    axios.put
    (`${Api}/vehicleUpdateApp/${id}`, params
     
    )
      .then((resp) => {
      
      addToast(' Vehicle details has been updated', { appearance: 'success' });
      setFetchError(false);
      setloadingDetail(false);
      setVehicleDetailModal(false);
      

      })
      .catch((err) => {
      });
  };
}

export function getVehicleFile(vehicleId, setModalValue, setDisabled, setSendEmailData, history, vehicle_Id) {

  return (dispatch) => {
    const carVerificationItems = [];
    const exteriorItems = [];
    const tires = [];
    const  tire=[];

    axios
      .get(`${Api}/filesAll/${vehicleId}`,  {headers} )
      .then((resp) => {
        const { data } = resp;
        if(data[data.length -1].hasAdded === "Existing"){

          // history.push(`/checkoutScreen/${vehicleId}/${vehicle_Id}`);
        }else{


        setSendEmailData(data[data.length -2].sendEmail);

        data.map((dat) => {
          if (dat.groupType === 'carVerificiationItems') {
            carVerificationItems.push(dat);
          }  else if (dat.groupType === 'exteriorItems') {
            exteriorItems.push(dat);
          } else if (dat.groupType === 'tires') {
            tires.push(dat);
          }
          else if (dat.groupType === 'tire') {
            tire.push(dat);
          }
          dispatch(setCarVerificationItems(carVerificationItems));
          dispatch(setExteriorItems(exteriorItems));
          dispatch(setTires(tires));
          dispatch(setTire(tire));
          dispatch(setVehicleLoading(false));
          if (setModalValue) {
            setDisabled(false);
            setModalValue(false);
          }
        });   }
      })
      .catch((err) => {
      });
  };
}

export function submitSurvey(params, addToast, setSurveyModal, setSurveyModalLoading, history) {
  return (dispatch) => {
    setSurveyModalLoading(true);
    axios
      .post(`${Api}/create/survey`, params, { headers })
      .then((resp) => {
        addToast(`You survey has been submitted successfully`, { appearance: 'success' });
        setSurveyModal(false);
        setSurveyModalLoading(false);
        history.push('/transactionScreen');
      })
      .catch((err) => {
        setSurveyModalLoading(false);
      });
  };
}

export function getSurveyStatus(id, setSurveyCheck) {
  return (dispatch) => {
    axios
      .get(`${Api}/survey/user/${id}`, { headers })
      .then((resp) => {
        if (resp.data === null) {
          setSurveyCheck(true);
        } else {
          setSurveyCheck(false);
        }
      })
      .catch((err) => { });
  };
}

export function deleteVehicleFile(fileId, groupType, deleteVehicleFile, setDeleteModal, addToast) {

  return (dispatch) => {
    deleteVehicleFile(true);
    axios
      .delete(`${Api}/files/${fileId}`, { headers })
      .then((resp) => {
        dispatch(setDeleteLocalFile({ fileId, groupType }));
        setDeleteModal(false);
        deleteVehicleFile(false);
        if(addToast){
          addToast(`Deleted Successfully.`, {appearance: 'success'});
        }
      })
      .catch((err) => {
        deleteVehicleFile(false);
      });
  };
}

export function getVehiclesStatus(vehicleId, setLoading) {
  return (dispatch) => {
    setLoading(true);
    axios
      .get(`${Api}/vehicle/company/${vehicleId}`, { headers })
      .then((resp) => {
        setLoading(false);
        dispatch(setVehicleStatus(resp.data));
      })
      .catch((err) => {
        setLoading(false);
      });
  };
}

export function getVehicleCertificate(params, setLoading, template) {
  return (dispatch) => {
    if(params?.companyId== 1)
    {
        let id=params?.id;
        setLoading(true);
        axios
            .get(`${Api}/dspCertificate/${id}`, { headers })
            .then((resp) => {
                setTimeout(function () {
                    setLoading(false);
                }, 2000);
              if (template === 'uber') {
                    dispatch(setgetaroundCertificate(resp?.data));
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    }
   else if(params?.companyId== 2)
    {
        let id=params?.id;
        setLoading(true);
        axios
            .get(`${Api}/dspCertificate/${id}`, { headers })
            .then((resp) => {
                setTimeout(function () {
                    setLoading(false);
                }, 2000);
              if (template === 'uber') {
                    dispatch(setgetaroundCertificate(resp?.data));
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    }
      // if(params?.companyId== 22)
      // {
      //     let id=params?.id;
      //     setLoading(true);
      //     axios
      //         .get(`${Api}/getaroundCertificate/${id}`, { headers })
      //         .then((resp) => {
      //             setTimeout(function () {
      //                 setLoading(false);
      //             }, 2000);
      //           if (template === 'uber') {
      //                 dispatch(setgetaroundCertificate(resp.data));
      //             }
      //         })
      //         .catch((err) => {
      //             setLoading(false);
      //         });
      // }
      else
      {
          setLoading(true);
          axios
              .get(`${Api}/dspCertificate/${params?.id}`)
              .then((resp) => {
                  setTimeout(function () {
                      setLoading(false);
                  }, 2000);
                  dispatch(setgetaroundCertificate(resp?.data));
                  if (template == 'template4')
                  {
                      dispatch(setTUROCertificateData(resp.data));
                  }
                  else if (template == 'template2') {
                      dispatch(setCertificateData(resp.data));
                  } else if (template === 'uber') {
                      dispatch(setUberCertificate(resp.data));
                  } else if (template === 'template3') {
                      dispatch(setLyftCertificate(resp.data));
                  }
              })
              .catch((err) => {
                  setLoading(false);
              });
      }

  };
}


export function queryKeyCheck(inspectionId, chkKey, setChkKeyResponse, addToast, setLoadingChk) {

  return (dispatch) => {
    setLoadingChk(true);

    axios
        .get(`${Api}/check/${chkKey}/${inspectionId}`, { headers })
        .then((resp) => {

          setChkKeyResponse(resp.data);
          setLoadingChk(false);
          if(resp?.data === "unexpired"){
            // addToast(`Key is unexpired`, { appearance: 'success' });
          }else{
            addToast(`Key is Expired`, { appearance: 'error' });
          }

        })
        .catch((err) => {
          setLoadingChk(false);
          // addToast(`Something Wrong`, { appearance: 'error' });
        });
  };
}



export function keyScooterReupload(inspectionId, params,setLoading, addToast, history) {

  return (dispatch) => {
    setLoading(true);

    axios
        .post(`${Api}/scooter/${inspectionId}/reupload`, params , { headers })
        .then((resp) => {

          setLoading(false);
          localStorage.removeItem('scooter_image_url');
          history.push('/thankyouScreenUpload');

        })
        .catch((err) => {

          setLoading(false)
          // addToast(`Something Wrong`, { appearance: 'error' });
        });
  };
}






export function reUploadFile(file, params, vehicle_id, imageUploadingProgress, addToast, setuploadingPercentage, setImageLink) {

  return (dispatch) => {

    // dispatch(setVehicleLoading(true));
    // setDisabled(true);
    axios
        .post(`${Api}/file/scooter/upload`, params, {
          headers,
        })
        .then((resp) => {

          const { key, url } = resp.data;
          dispatch(reUploadToS3(file, key, url, vehicle_id, params.type, imageUploadingProgress, setuploadingPercentage, setImageLink));
        })
        .catch((err) => {
        });
  };
}


export function reUploadToS3(file, key, uploadUrl, vehicle_id, ext, imageUploadingProgress, setuploadingPercentage, setImageLink) {

    return (dispatch) => {
        const headers = {
            'Content-Type': 'img/png',
        };
        axios
            .put(`${uploadUrl}`, file, {
                headers,
                onUploadProgress: imageUploadingProgress,
            })
            .then((resp) => {

                setuploadingPercentage(0);
                setImageLink(key);
                localStorage.setItem('scooter_image_url', key);
                // const date = ;
                const params = {url: key, vehicle_id, extension: ext};
                return;
                // dispatch(addFileInDB(params, setModalValue, setDisabled));
            })
            .catch((err) => {
            });
    };
}



export function changeVehicleStatus(id, vehicleId, setVehicleStatusLoading, history) {

  return (dispatch) => {
    setVehicleStatusLoading(true);
    axios
      .post(`${Api}/vehicle/status/${id}`, {}, { headers })
      .then((resp) => {
        setVehicleStatusLoading(false);
        history.push(`/VehicleAfterReviewing/${id}/${vehicleId}`);
      })
      .catch((err) => {
        setVehicleStatusLoading(false);
      });
  };
}



export function sendReuploadEmail(id, vehicleId, setVehicleStatusLoading, history, addToast) {

  return (dispatch) => {
    const body ={
      inspectionId: id
    }

    axios
        .post(`${Api}/reupload/alert`, body, { headers })
        .then((resp) => {

          addToast(`Successfully Reuploaded` , {appearance: 'success'});
          dispatch(changeVehicleStatus(id, vehicleId, setVehicleStatusLoading, history));
        })
        .catch((err) => {

          addToast(`${err?.response?.data?.message[0]}` , {appearance: 'error'});
        });
  };
}

export function getReportData(vechileId, setLoading) {
  setLoading(true);
  return (dispatch) => {
    axios
      .get(`${Api}/vehicle/${vechileId}/files`, { headers })
      .then((resp) => {
        dispatch(setCertificateData(resp.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
}

export function skipPaymentMethod(id, vehicleId, setLoading, history, paymentStatus) {
  setLoading(true);
  return (dispatch) => {
    axios
      .post(`${Api}/vehicles/payment/${id}`, {}, { headers })
      .then((resp) => {
        if (paymentStatus) {
          history.push(`/VehicleAfterReviewing/${id}/${vehicleId}`);
        } else {
          history.push('/thankyouScreen');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
}

export function getInspectionByStatus(params, setLoading) {
  setLoading(true);
  return (dispatch) => {
    axios
      .post(`${Api}/status/vehicle`, params, { headers })
      .then((resp) => {
        dispatch(setInspectionByStatus(resp.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
}



export function getNewCompanyData(inspectionId, body, setLoadingSelect, history, vehicleId, addToast) {

  setLoadingSelect(true);
  return (dispatch) => {
    axios
        .post(`${Api}/create/add/inspection/company/${inspectionId}`, body, { headers })
        .then((resp) => {

          setLoadingSelect(false);


          const hasAdded = {
            hasAddedData: resp?.data?.hasAdded,
          }
          if(resp?.data?.hasAdded === 'Existing'){
            localStorage.setItem('hasAdded', resp?.data?.hasAdded);
            // history.push(`/checkoutScreen/${inspectionId}/${vehicleId}`, hasAdded);

          }else{
            history.push(`/vehicleinspection/${resp?.data?.inspection?.id}/${vehicleId}`);
          }



        })
        .catch((err) => {

          addToast(err?.response?.data?.message[0], { appearance: 'warning' });


          setLoadingSelect(false);
        });
  };
}




export function getPaymentPriceInfoCheckout(vehicleId, setPrice, setPriceLoading, promoCode, setValuePromo, addToast, chkType,
  ) {
    return (dispatch) => {

        setPriceLoading(true);
        axios
        .post(`${Api}/transactions/checkout/${vehicleId}`, {code: promoCode }, { headers })
        .then((resp) => {
              setPriceLoading(false);
              setPrice(resp?.data?.object);
              if(resp?.data?.object?.promoCode === null){
                setValuePromo('');
                if(resp?.data?.object?.message === "Promo code expired!"){
                  addToast("Promo code expired!", { appearance: 'warning' });
                }else if(chkType === "deletePromo"){
                  addToast("Promo Code Successfully Deleted", { appearance: 'success' });
                }
                else if(chkType === "firstTime"){

                }
                else{
                  addToast("Promo Code Not Found!", { appearance: 'warning' });
                }

                localStorage.removeItem('promoCode');

              }else{
                addToast("Promo Code Applied.", { appearance: 'success' });
              }


            }

        ).catch((err) => {
      localStorage.removeItem('promoCode');
      setValuePromo('');
        if(err?.response?.data?.object?.message[0] === "Your inspection is already in review"){

            addToast(`${err?.response?.data?.message}`, { appearance: 'error' });
            ( window.location.replace('/selectOption'))


        }else{
            addToast(`${err?.response?.data?.message}`, { appearance: 'error' });
        }

      setPriceLoading(false);
    });
      }

  };





export function getInspectionCompaniesEdit(id ,body, setLoadingSelect, vehicleId, history, addToast) {

  setLoadingSelect(true);
  return (dispatch) => {

    axios.post(`${Api}/update/inspection/${id}`, body, { headers })
        .then((resp) => {

          addToast('Companies Updated Successfully', { appearance: 'success' }),
          setLoadingSelect(false);
          // history.push(`/checkoutScreen/${id}/${vehicleId}`);


        })
        .catch((err) => {

          setLoadingSelect(false);
        if(err?.response?.data?.errors[0]){
          addToast(err?.response?.data?.errors[0], { appearance: 'error' })
        }else{
          addToast('Something went wrong', { appearance: 'error' })
        }

        });
  };
}

export function createInspection(body, setLoadingSelect, addToast, setAlreadyExistModal, setInspectionErrorResp, setLicensePlateNumber, setNumberEmpty,setInspectionChecked) {
  setLoadingSelect(true);
  return (dispatch) => {

    axios
      .post(`${Api}/create/inspection`, body, { headers })
      .then((resp) => {
       
        // dispatch(setInspectionData(resp.data));
        window.location.href = `/vehicleinspection/${resp?.data?.id}/${resp?.data?.vehicleId}?lyftUser=${resp?.data?.lyftInspection}`;
        setAlreadyExist(false);
        setLoadingSelect(false);
      })
      .catch((err) => {

        if(err.response?.data?.errorMessage){
              addToast('License Plate Number Already Exist', { appearance: 'warning' }),
                  setAlreadyExistModal(true),
                  setInspectionErrorResp(err.response.data)
        }else if (err.response?.data?.message){
          addToast(err?.response?.data?.message[0], { appearance: 'warning' })
          setLicensePlateNumber('');
          setNumberEmpty(true);
          setInspectionChecked('');
        }
        setLoadingSelect(false);
      });
  };
}


export function getInspectionDetail(id, setGetInspectionDetailData, setDetailLoading, setLicensePlateNumber) {
  setDetailLoading(true);

  return (dispatch) => {
    axios
        .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/detail/${id}`, { headers })
        .then((resp) => {
              setGetInspectionDetailData(resp.data);
              if(setLicensePlateNumber){
                setLicensePlateNumber(resp?.data?.Vehicle?.licensePlateNumber);
              }
          setDetailLoading(false);

            }
        )
        .catch((err) => {
          setDetailLoading(false);
        });

  }
}


export function getInspectionDiscount(id, setPriceLoading, body,setPrice, valuePromo, setGetInspectionDetailData , addToast, setValuePromo, chkType, setDetailLoading,Referralcode,setValueReferral) {
  return (dispatch) => {

    setPriceLoading(true);
    axios
        .post(`${process.env.REACT_APP_DEVELOPMENT_URL}/update/inspection/${id}`, body, { headers })
        .then((resp) => {
          addToast('Company Added Successfully', { appearance: 'success' }),
          setPriceLoading(false);

          dispatch(getPaymentPriceInfoCheckout(id, setPrice, setPriceLoading, valuePromo, setValuePromo, addToast, chkType,Referralcode,setValueReferral));
          dispatch(getInspectionDetail(id, setGetInspectionDetailData,setDetailLoading ));


            }
        )
        .catch((err) => {
          addToast('Something went wrong', { appearance: 'error' })
        });

  }
}


export function autoReInspection(body, setLoading) {
  setLoading(true);
  return (dispatch) => {
    axios
    .post(`${Api}/inspection/recreate`, body, { headers })
    .then((resp) => {
      window.location.href = `/vehicleinspection/${resp?.data?.id}/${resp?.data?.vehicleId}?lyftUser=${resp?.data?.lyftInspection}`;
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    })
  }
}

export function discardInspection(inspectionId, setLoadingDiscard, setLoad, addToast) {
  setLoadingDiscard(true);
  setLoad(false);
  return (dispatch) => {
    axios
      .delete(`${Api}/delete/inspection/${inspectionId}?type=app`, { headers })
      .then((resp) => {
        setLoadingDiscard(false);
        setLoad(true);
      })
      .catch((err) => {

        setLoadingDiscard(false);
        setLoad(false);
        addToast(`${err?.response?.data?.message[0]}`, { appearance: 'error' });
      })
  }
}

export function createReInspection(reInspectionId, body, setLoadingSelect) {
  setLoadingSelect(true);
  return (dispatch) => {
    axios
      .post(`${Api}/create/reinspection/${reInspectionId}`, body, { headers })
      .then((resp) => {
        window.location.href = `/vehicleinspection/${resp?.data?.id}/${resp?.data?.vehicleId}?lyftUser=${resp?.data?.lyftInspection}`;

        setLoadingSelect(false);
      })
      .catch((err) => {
        setLoadingSelect(false);
      });
  };
}

export function UnSubscribeUser(id, setLoading, addToast, setError) {
  return (dispatch) => {
    setLoading(true);
    axios
      .put(`${Api}/unsubscribe?id=${id}`)
      .then((resp) => {
        setLoading(false);
        addToast("Unsubscribed Successfully", { appearance: 'success' })
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        addToast(err?.message, { appearance: 'error' });
      });
  };
}

export function getVehicleDetails(inspectionId, setLoadingSelect) {
  setLoadingSelect(true);
  return (dispatch) => {
    axios
      .get(`${Api}/vehicle/detail/${inspectionId}`, { headers })
      .then((resp) => {
        dispatch(setVehicleDetails(resp.data));
        setLoadingSelect(false);
      })
      .catch((err) => {
        setLoadingSelect(false);
      });
  };
}

export function getFileDetails(inspectionId, setLoading) {
  setLoading(true);
  return (dispatch) => {
    axios
      .get(`${Api}/files/details/${inspectionId}`, { headers })
      .then((resp) => {
        dispatch(setFileDetails(resp.data.files));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
}



export function checkCustomerStatus(id,  history) {

    return (dispatch) => {
        axios
            .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/review/status/${id}`)
            .then((resp) => {
                if(resp?.data?.review_status=== 'REVIEWED'){
                                history.push('/selectOption');
                            }
                }
            )
            .catch((err) => {

            });
    };
}