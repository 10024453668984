/* eslint-disable */

import React, {useState, useEffect, useRef} from 'react';
import {BrowserView, MobileView, isBrowser, isMobile, browserName} from 'react-device-detect';
// import imageCompression from 'browser-image-compression';
// import Compressor from 'compressorjs';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {checkCustomerStatus, sendReuploadEmail,SaveVehicleInspectionDetail} from '../../actions/vehicleInspection';
import axios from "axios";
import { moment } from 'moment';
import ActionCreators from '../../actions';
import Loading from '../../HOC/index';
import { VehicleInspectionScreen } from '../../Screens';

const queryString = require('query-string');

const headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
  
};

// const queryString = require('query-string');

const VehicleInspectionContainer = (props) => {
  const query = queryString.parse(location.search);

  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const webRef = useRef(null);
  const captureAreaRef = useRef(null);
  const [isModalVisible, setModalValue] = useState(false);
  const [isSurveyModalVisible, setSurveyModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [vehicleStatusLoading, setVehicleStatusLoading] = useState(false);
  const [uploadingPercentage, setuploadingPercentage] = useState(0);
  const [fileToBeDeleted, setDeletingFile] = useState(null);
  const [imageCategory, setImageCategory] = useState(null);
  const [rating, setNewRating] = useState(0);
  const [comment, setComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [surveyModalLoading, setSurveyModalLoading] = useState(false);
  const [surveyChecks, setSurveyChecks] = useState({
    online_services: false,
    face_time: false,
    visit_shop: false,
  });
  const [vehicleInstructionValues, setVehicleInstruction] = useState(null);
  const [groupType, setGroupType] = useState(null);
  const [surveyCheck, setSurveyCheck] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [aiStatus, setAiStatus] = useState({});
  const [isImagemodal, setIsImageModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [compressedFile, setCompressedFile] = useState('');
  const [sendEmailData, setSendEmailData] = useState('');
  const [showOneTire,setShowOneTire]=useState(false);
  const [vinwebcam,setVinWebCam]=useState(false);
  const [WebCameraOpen,setWebCameraOpen]=useState(false);
  const [frontFacing ,setFrontFacing]=useState(false);
  const [clickPic,setClickPic]=useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [saveImage,setSaveImage]=useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isEditable, setIsEditable] = useState(false);
  const [vehicleDetailModal,setVehicleDetailModal]=useState(false);
  const [initialFieldValues, setInitialFieldValues] = useState({});
  const [loadingDetail,setloadingDetail]=useState(false);
  const [loadingsubmit,setLoadingSubmit]=useState(false);
  const [fetchError, setFetchError] = useState(false);
const[detailEnter,setDetailEnter]=useState(false);
  const [locationMap, setLocationMap] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
  });
  const [payement_status,setpayementStatus]=useState(false);
  const[islocation,setisLocation]=useState(false);
  const [imageTire, setImageTire] = useState('');

  let today = new Date();
  const date = `${(today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : (today.getMonth() + 1)}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}-${today.getFullYear()}`;
  const dateImage = date.toString();

  useEffect(() => {
    // if (user?.updates || user?.updates === null || user?.updates === undefined) {
    //   window.location.replace('/logoutForChanges');
    // }
    handleRequests();
  }, []);
//   useEffect(() => {
//   let mobileAgent = window?.navigator?.userAgent;
//   if(props?.vehicleStatus?.hasTyreLocation===true)
//   {
//   if(mobileAgent){
//     if(isMobile){

//        // Check if geolocation is supported
//   if ("geolocation" in navigator) {
//     // Request geolocation permission
//     const requestGeolocationPermission = async () => {
//       try {
//         const position = await new Promise((resolve, reject) => {
//           navigator.geolocation.getCurrentPosition(resolve, reject, {
//             enableHighAccuracy: true,
//             timeout: 20000,
//           });
//         });

//         // Geolocation permission granted
//         setLocationMap({
//           loaded: true,
//           coordinates: {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           },
//         });
//       } catch (error) {
//         // Geolocation permission denied or error occurred
//         setLocationMap({
//           loaded: false,
//           coordinates: {
//             lat: "permission denied",
//             lng: "permission denied",
//           },
//         });
//         setShowOneTire(true);
//         setModalValue(false);
//       }
//     };

//     requestGeolocationPermission();
//   } else {
//     // Geolocation not supported
//     setLocationMap({
//       loaded: false,
//       coordinates: {
//         lat: "geolocation not supported",
//         lng: "geolocation not supported",
//       },
//     });
//     setShowOneTire(true);
//     setModalValue(false);
//   }
  
//     }
//   }
//   }
// }, [props?.vehicleStatus?.hasTyreLocation]); 



useEffect(() => {
  let mobileAgent = window?.navigator?.userAgent;
  const { match} = props;
  axios
  .get(`${process.env.REACT_APP_DEVELOPMENT_URL}/vehicle/review/status/${match?.params?.id}`)
  .then((resp) => {
    setpayementStatus(resp?.data?.payment_status);
    setisLocation(resp?.data?.isLocation)
    if(resp?.data?.payment_status===true && resp?.data?.isLocation===false )
    {
           setShowOneTire(true);
            setModalValue(false);
    }
    else if( resp?.data?.payment_status===true && resp?.data?.isLocation===true )
    {

    }
    else{
    // if(props?.vehicleStatus?.hasTyreLocation===true)
    // {
      if ("geolocation" in navigator) {
    if(mobileAgent){
      if(isMobile){
        navigator.geolocation.getCurrentPosition(
          function(position) {
    
                setLocationMap({
              loaded: true,
              coordinates: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
            });
          },
          function(error) {
            if(error.message==='The permission was revoked')
            {
  
            }
            else{
            setShowOneTire(true);
            setModalValue(false);
            }
          }, {
            enableHighAccuracy: true
            ,timeout : 20000
          }
    
      );
    
        }}
    // }
    }
  }
      }
  )
  .catch((err) => {

  });


}, []);  

  const handleRequests = () => {

     const { getVehicleFile, currentUser, getSurveyStatus, getVehiclesStatus, match } = props;
    // getSurveyStatus(currentUser?.id, setSurveyCheck);
dispatch(checkCustomerStatus(match?.params?.id,history))
      getVehicleFile(match?.params?.id,'','', setSendEmailData, history, match?.params?.vehicleId);


    getVehiclesStatus(match?.params?.id, setLoading);
  };

  const handleModal = (value, groupType) => {
    setImageCategory(value.id);
    setGroupType(groupType);
    setVehicleInstruction(value);
    setModalValue(!isModalVisible);
  };

  const handleImageControl = (e) => {

    handleImageUpload(e, vehicleInstructionValues);
  }

  const handleModalClose = () => {
    setModalValue(false);
    var video = document.getElementById('myVideo');
    video?.pause();
    video?.load();
  };

  const changeRating = (newRating, name) => {
    setNewRating(newRating);
  };




  const handleImageDeny = () => {
    setIsImageModal(false);
    setAiStatus({});
  }

  const handleImageConfirmation = () => {
    setIsImageModal(false);
    handleImageFinalize(imageFile);
    setAiStatus({});
  }

  const handleVideoDeny = () => {
    setIsVideoModal(false);
    setVideoFile(null);
  }

  const handleVideoConfirmation = () => {
    setIsVideoModal(false);
    const { uploadFile, match } = props;
    uploadFile(videoFile, { type: videoFile?.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled);  
    setVideoFile(null);
  }




  //New code for Image Upload with Location
  const handleImageUpload = async (event, data) => {

    setLocationMap({
      loaded: false,
      coordinates: {
        lat: '',
        lng: '',
      },
    });

    let mobileAgent = window?.navigator?.userAgent;
    if(payement_status===false)
    {

    
    // if(props?.vehicleStatus?.hasTyreLocation===true)
    // {
    if (data?.groupType === 'tires') {
      const imageFile = event.target.files[0];
      setImageTire(imageFile);

      if (!("geolocation" in navigator)) {
        setLocationMap({
          loaded: false,
          coordinates: {
            lat: "geo location not supported",
            lng: "geo location not supported",
          }
        });
      }

      if(mobileAgent){
      if(isMobile){
        navigator.geolocation.getCurrentPosition(
            function(position) {
      
                  setLocationMap({
                loaded: true,
                coordinates: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                }
              });
      
               locationImageTireHandler(position.coords.latitude, position.coords.longitude, imageFile );
              //  addToast(`${position.coords.latitude} || ${position.coords.longitude}`, { appearance: 'success' });
            },
            function(error) {
      
              setLocationMap({
                loaded: false,
                coordinates: {
                  lat: error?.code,
                  lng: error?.message,
                }
              });
              if(error.message==='The permission was revoked')
          {
            const imageFile = event.target.files[0];
            event.target.value = '';
            const {uploadFile, match} = props;
      
            uploadFile(imageFile, {type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError
            );
      
      
            setuploadingPercentage(0);
          }
          else{
      
              // locationImageTireHandler(error?.code, error?.message, imageFile );
              setShowOneTire(true);
              setModalValue(false);
              // addToast(` ${error.message}`, { appearance: 'error' });
          }
            }, {
              enableHighAccuracy: true
              ,timeout : 20000
            }
      
        );
      
      }

      // }
      else{
      //Code for laptop
      // locationImageTireHandler('Laptop User','Laptop User', imageFile );
      const imageFile = event.target.files[0];
      event.target.value = '';
      const {uploadFile, match} = props;

      uploadFile(imageFile, {type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'Laptop User','Laptop User','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError
      );


      setuploadingPercentage(0);
        // setModalValue(false);
        // addToast(`Please turn on location services for the browser on your mobile device before taking tire photos. Thanks` , {appearance: 'warning'});


      }
      
      }

      }else{
      const imageFile = event.target.files[0];
      event.target.value = '';
      const {uploadFile, match} = props;

      uploadFile(imageFile, {type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError
      );


      setuploadingPercentage(0);
      }
    }
    else if(islocation===true && payement_status===true)
    {
      const imageFile = event.target.files[0];

      event.target.value = '';

      const {uploadFile, match} = props;

      uploadFile(imageFile, {type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError);
      setuploadingPercentage(0);
    }
    else if(islocation===false && payement_status===true)
    {
      setShowOneTire(true);
      setModalValue(false);
    }
    else {
      const imageFile = event.target.files[0];

      event.target.value = '';

      const {uploadFile, match} = props;

      uploadFile(imageFile, {type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','','', setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError);
      setuploadingPercentage(0);
      }
  }

  const locationImageTireHandler = (lat, lng, imageFile) => {

    if(lat === '' && lng === ""){
      setModalValue(false);

      addToast(`Something Went Wrong. Try Again!!!` , {appearance: 'error'});
    }else{

      const { uploadFile, match } = props;

      uploadFile(imageFile,{type: imageFile?.type}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, lat, lng, addToast, setSendEmailData);
      setuploadingPercentage(0);
    }

  }

  //Old Compression image code which cut image below
  // const handleImageUpload = async (event) => {
  //   var imageFile = event.target.files[0];
  //   event.target.value = '';
  //   var options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true,
  //   };
  //   await imageCompression(imageFile, options)
  //     .then(function (compressedFile) {
  //       const { uploadFile, match } = props;
  //
  //       uploadFile(compressedFile, { type: compressedFile.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled);
  //     })
  //     .catch(function (error) { });
  //   setuploadingPercentage(0);
  // };


  const handleVideoUpload = async (event) => {
    var videoFile = event.target.files[0];
    event.target.value = '';

    const { uploadFile, match } = props;
    uploadFile(videoFile, { type: videoFile?.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled, '', '', '', setSendEmailData);
    setuploadingPercentage(0);
  };

  // const handleImageUpload = async (event) => {
  //   setImageFile(event.target.files[0]);
  //   let formData = new FormData;
  //   formData.append('file',event.target.files[0]);
  //   const { uploadToAi } = props;
  //   await uploadToAi(formData, imageCategory, setAiLoading, setAiStatus, setIsImageModal, handleImageFinalize, event.target.files[0]);
  //   event.target.value = '';
  //   };

  //   const handleImageFinalize = async (imageFile) => {
  //     var options = {
  //       maxSizeMB: 1,
  //       maxWidthOrHeight: 1920,
  //       useWebWorker: true,
  //     };
  //     await imageCompression(imageFile, options)
  //     .then(function (compressedFile) {
  //       const { uploadFile, match } = props;
        
  //       uploadFile(compressedFile, { type: compressedFile.type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled);
  //     })
  //     .catch(function (error) { });
  //     setuploadingPercentage(0);
  //     setImageFile(null)
  //   }

  // const handleVideoUpload = async (event) => {
  //   setVideoFile(event.target.files[0]);
  //   let formData = new FormData;
  //   formData.append('file',event.target.files[0]);
  //   const { uploadVideoToAi, match } = props;
  //   uploadVideoToAi(formData, setIsVideoModal, setAiLoading, event.target.files[0], { type: event.target.files[0].type }, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled)
  //   setuploadingPercentage(0);
  //   event.target.value = '';
  // };

  const handleSurveyModal = (value) => {
    if (surveyCheck) {
      setSurveyModal(value);
    } else {
      history.push('/transactionScreen');
    }
  };

  const handleCheckBox = (name, e) => {
    let obj = {
      ...surveyChecks,
      [name]: e.target.checked,
    };
    setSurveyChecks(obj);
  };

  const handleComment = (e) => {
    setCommentError(false);
    setComment(e.target.value);
  };

  const handleSubmitSurvey = async () => {
    const { submitSurvey } = props;
    if (comment) {
      const params = {
        ...surveyChecks,
        comment: comment,
        rating: rating,
      };
      await submitSurvey(params, addToast, setSurveyModal, setSurveyModalLoading, history);
    } else {
      setCommentError(true);
    }
  };

  const deleteFile = () => {
    const { deleteVehicleFile } = props;
    deleteVehicleFile(fileToBeDeleted?.id, fileToBeDeleted?.groupType, setDeleteLoading, setDeleteModal, addToast);
  };

  const handleDeleteModal = (groupType, id) => {
    setDeletingFile({ groupType, id });
    setDeleteModal(!isDeleteModal);
  };

  const imageUploadingProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor(loaded * 100) / total;
    setuploadingPercentage(percent);
  };
  const handleChangeVehicleStatus = () => {
    const { changeVehicleStatus, match } = props;
    if(sendEmailData === 'Yes'){
      dispatch(sendReuploadEmail(match?.params?.id,match?.params?.vehicleId,setVehicleStatusLoading,history,  addToast));
    }else{
      changeVehicleStatus(match?.params?.id, match?.params?.vehicleId, setVehicleStatusLoading, history);
    }


  };
  const handleSkipPayment = (paymentStatus) => {

    const { match, skipPaymentMethod } = props;
    skipPaymentMethod(match?.params?.id, match?.params?.vehicleId, setVehicleStatusLoading, history, paymentStatus);
  };
 
  const imageConstraints = frontFacing?   { facingMode: "user" ,}:  { facingMode: { exact: "environment" } ,} ;
const handleImageControlvin=()=>
{
  setVinWebCam(true);
  setClickPic(true);
  setCapturedImage(null);
  setSaveImage(false);
}
const handlecameraOpen=()=>
{
  setWebCameraOpen(true);
}
const handleCamerarotation=()=>
{
  setFrontFacing((prevIsBackCameraOpen) => !prevIsBackCameraOpen);
 
}

const handleClickPicture=()=>
{

  const video = webRef.current.video;
    const captureCanvas = document.createElement('canvas');
    captureCanvas.width = video.videoWidth;
    captureCanvas.height = video.videoHeight;
    const ctx = captureCanvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(video, 0, 0, captureCanvas.width, captureCanvas.height);

    const desiredWidth = 374;
    const desiredHeight = 100;
    const aspectRatio = desiredWidth / desiredHeight;

    // Calculate the target width and height while preserving the aspect ratio
    let targetWidth = captureCanvas.width;
    let targetHeight = captureCanvas.height;

    if (targetWidth / targetHeight > aspectRatio) {
      targetWidth = targetHeight * aspectRatio;
    } else {
      targetHeight = targetWidth / aspectRatio;
    }

    // Calculate the center position to crop the image
    const cropX = (captureCanvas.width - targetWidth) / 2;
    const cropY = (captureCanvas.height - targetHeight) / 2;

    // Create a new canvas for the cropped image
    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = desiredWidth;
    croppedCanvas.height = desiredHeight;
    const croppedCtx = croppedCanvas.getContext('2d');
    // croppedCtx.imageSmoothingEnabled = false;
    croppedCtx.drawImage(
      captureCanvas,
      cropX,
      cropY,
      targetWidth,
      targetHeight,
      0,
      0,
      desiredWidth,
      desiredHeight
    );

    // Convert the cropped image to data URL
    const croppedImage = croppedCanvas.toDataURL('image/png', 0.9 );
    setCapturedImage(croppedImage);
   
    // setCapturedImage(imageSrc);
    setVinWebCam(false);
    setClickPic(false);
    setSaveImage(true);
}
const handleSaveImage=()=>
{

  const {uploadFile, match} = props;
  const imageData = Buffer.from(capturedImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  setSaveImage(false);
  setCapturedImage(null)
  uploadFile(imageData, {type: "image/png"}, match?.params?.id, imageCategory, groupType, setModalValue, imageUploadingProgress, dateImage, setDisabled,'','',addToast, setSendEmailData,setVehicleDetailModal,setInitialFieldValues,setFetchError);

}
const handleEdit = () => {
  setIsEditable(true);
};
const handleFormSubmit=(values,form)=>
{
const { match} = props;
dispatch(SaveVehicleInspectionDetail(values,match?.params?.id,setVehicleDetailModal,setloadingDetail,addToast,setFetchError))
}

const handleSubmitInspection=()=>
{
setLoadingSubmit(true)
const { match} = props;
debugger

axios
  .patch(`${process.env.REACT_APP_DEVELOPMENT_URL}/inspection/${match?.params?.id}`, null, {
    headers: headers
  })
.then((resp) => {
  setLoadingSubmit(false)
  addToast(`Inspection Submit Successfully`, {appearance: 'success'});
          history.push(`/thankyouScreen`);
          localStorage.removeItem('licensePlateNumber')
})
.catch((err) => {
  setLoadingSubmit(false)
});

}
const handleSubmitInfo=()=>
{
  
  setVehicleDetailModal(false);
  setFetchError(false);

}


  return (
    <VehicleInspectionScreen
      rating={rating}
      isDisabled={isDisabled}
      vehicleInstructionValues={vehicleInstructionValues}
      vehicleInstructions={props.vehicleInstructions}
      isModalVisible={isModalVisible}
      isSurveyModalVisible={isSurveyModalVisible}
      isLoading={props.isLoading}
      isDeleteModal={isDeleteModal}
      deleteLoading={deleteLoading}
      surveyModalLoading={surveyModalLoading}
      uploadingPercentage={uploadingPercentage}
      currentUser={props.currentUser}
      handleImageUpload={handleImageUpload}
      handleModal={handleModal}
      deleteFile={deleteFile}
      handleDeleteModal={handleDeleteModal}
      handleModalClose={handleModalClose}
      handleVideoUpload={handleVideoUpload}
      handleSurveyModal={handleSurveyModal}
      changeRating={changeRating}
      handleCheckBox={handleCheckBox}
      handleComment={handleComment}
      setDeleteModal={setDeleteModal}
      handleSubmitSurvey={handleSubmitSurvey}
      commentError={commentError}
      paymentStatus={props.paymentStatus}
      match={props.match.params}
      changeVehicleStatus={handleChangeVehicleStatus}
      vehicleStatusLoading={vehicleStatusLoading}
      handleSkipPayment={handleSkipPayment}
      vehicleStatus={props?.vehicleStatus}
      lyftUser={query?.lyftUser}
      aiStatus={aiStatus}
      modal={isImagemodal}
      aiLoading={aiLoading} 
      handleImageDeny={handleImageDeny}
      handleImageConfirmation={handleImageConfirmation}
      isVideoModal={isVideoModal}
      handleVideoDeny={handleVideoDeny}
      handleVideoConfirmation={handleVideoConfirmation}
      handleImageControl={handleImageControl}
      locationMap={locationMap}
      showOneTire={showOneTire}
      loadingsubmit={loadingsubmit}
      handleImageControlvin={handleImageControlvin}
      vinwebcam={vinwebcam}
      webRef={webRef}
      handlecameraOpen={handlecameraOpen}
      handleCamerarotation={handleCamerarotation}
      imageConstraints={imageConstraints}
      clickPic={clickPic}
      WebCameraOpen={WebCameraOpen}
      handleClickPicture={handleClickPicture}
      capturedImage={capturedImage}
      captureAreaRef ={ captureAreaRef }
      saveImage={saveImage}
      handleSaveImage={handleSaveImage}
      isEditable={isEditable}
      handleEdit={handleEdit}
      vehicleDetailModal={vehicleDetailModal}
      initialFieldValues={initialFieldValues}
      handleFormSubmit={handleFormSubmit}
      loadingDetail={loadingDetail}
      fetchError={fetchError}
      handleSubmitInspection={handleSubmitInspection}
      detailEnter={detailEnter}
      isMobile={ isMobile}
      setVehicleDetailModal={setVehicleDetailModal}
      handleSubmitInfo={handleSubmitInfo}

    />
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    vehicleData: state.auth.vehicleData,
    isLoading: state.vehicleInstruction.isVehicleLoading,
    vehicleInstructions: state.vehicleInstruction,
    currentUser: state.auth.currentUser,
    vehicleStatus: state.vehicleInstruction.vehicleStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading(VehicleInspectionContainer));
