import createReducer from '../store/createReducer';
import * as types from '../utils/constants';

const initialState = {
  verificationItem: [
    {
      id: 'license_plate_number',
      type: 'Photo',
      title: 'License Plate',
      description: 'Please take a photo of the License plate on the vehicle ',
      url: '',
      fileId: null,
      groupType: 'carVerificiationItems',
      steps: [],
      image: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/Group+45067.png',
      // image: 'https://res.cloudinary.com/dgjordf6e/image/upload/v1692258058/Group_45067_chav1w.png',
      loading: false,
    },
    {
      id: 'odometer',
      type: 'Photo',
      title: 'Odometer',
      description: 'Please take a photo  entire odometer dashboard area with vehicle turned on capturing following items:',
      url: '',
      groupType: 'carVerificiationItems',
      fileId: null,
      steps: ['Vehicle mileage'],
      image: ' https://res.cloudinary.com/tech-qalanders/image/upload/v1616590296/chex-instruction/Photo-5Odometer_jxwevq.jpg',
      loading: false,
    },
   
   
  ],
  exteriorItems: [
    {
      id: 'exterior_left',
      type: 'Photo',
      title: 'Exterior Left',
      description: 'Please take a photo clearly capturing the entire exterior left side of the vehicle',
      url: '',
      fileId: null,
      groupType: 'exteriorItems',
      steps: [],
      image: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/WhatsApp+Image+2023-09-21+at+7.07.33+PM.jpeg',
      // image: 'https://res.cloudinary.com/dgjordf6e/image/upload/v1695306323/WhatsApp_Image_2023-09-21_at_7.07.33_PM_in84c9.jpg',
      loading: false,
    },
    {
      id: 'exterior_right',
      type: 'Photo',
      title: 'Exterior Right',
      description: 'Please take a photo clearly capturing the entire exterior right side of the vehicle',
      url: '',
      fileId: null,
      groupType: 'exteriorItems',
      steps: [],
      image: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/WhatsApp+Image+2023-09-21+at+7.07.32+PM.jpeg',
      // image: 'https://res.cloudinary.com/dgjordf6e/image/upload/v1695306271/WhatsApp_Image_2023-09-21_at_7.07.32_PM_sjm8hi.jpg',
      loading: false,
    },
    {
      id: 'exterior_front',
      type: 'Video',
      title: 'Exterior Front',
      description: 'Please upload a video clearly showing the front of the vehicle with turn indicators and head lights turned on',
      url: '',
      fileId: null,
      groupType: 'exteriorItems',
      steps: [],
      // steps: [' Headlights turned on', 'Front indicators blinking', 'Windshield wipers turned on'],
      // video: 'https://res.cloudinary.com/dgjordf6e/video/upload/v1694864606/IMG_2746_i8rnfq.mp4',
      video: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/IMG_2746.MOV',
      loading: false,
    },
    {
      id: 'exterior_rear',
      type: 'Video',
      title: 'Exterior Rear',
      description: 'Please upload a video clearly showing the rear of the vehicle with turn indicators and tail lights turned on',
      url: '',
      fileId: null,
      groupType: 'exteriorItems',
      steps: [],
      // steps: ['Tail Lights turned on', 'Back indicators blinking', 'Drive car 3-5 feet in reverse and push on the brake pedal', 'Once car is in the park mode, push the gas pedal'],
      // video: 'https://res.cloudinary.com/dgjordf6e/video/upload/v1694864783/IMG_2745_cufobs.mp4',
      video: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/IMG_2745.mov',
      video2: 'https://res.cloudinary.com/tech-qalanders/video/upload/v1616590306/chex-instruction/Clip_2_-_Rear_a0eoqp.mp4',
      loading: false,
    },
  ],
  tires: [
    {
      id: 'left_front_tire',
      type: 'Photo',
      title: 'Left Front Tire',
      description: 'Please place a penny on the tire thread and take a photo capturing following items:',
      url: '',
      fileId: null,
      groupType: 'tires',
      steps: ['Place Lincoln’s heads on the penny upside down and facing the camera'],
      image: 'https://res.cloudinary.com/tech-qalanders/image/upload/v1616590298/chex-instruction/Photot710Tire_xtabgg.jpg',
      loading: false,
    },
    {
      id: 'left_rear_tire',
      type: 'Photo',
      title: 'Left Rear Tire',
      description: 'Please place a penny on the tire thread and take a photo capturing following items:',
      url: '',
      fileId: null,
      groupType: 'tires',
      steps: ['Place Lincoln’s heads on the penny upside down and facing the camera'],
      image: 'https://res.cloudinary.com/tech-qalanders/image/upload/v1616590298/chex-instruction/Photot710Tire_xtabgg.jpg',
      loading: false,
    },
    {
      id: 'right_front_tire',
      type: 'Photo',
      title: 'Right Front Tire',
      description: 'Please place a penny on the tire thread and take a photo capturing following items:',
      url: '',
      fileId: null,
      groupType: 'tires',
      steps: ['Place Lincoln’s heads on the penny upside down and facing the camera'],
      image: 'https://res.cloudinary.com/tech-qalanders/image/upload/v1616590298/chex-instruction/Photot710Tire_xtabgg.jpg',
      loading: false,
    },
    {
      id: 'right_rear_tire',
      type: 'Photo',
      title: 'Right Rear Tire',
      description: 'Please place a penny on the tire thread and take a photo capturing following items:',
      url: '',
      fileId: null,
      groupType: 'tires',
      steps: ['Place Lincoln’s heads on the penny upside down and facing the camera'],
      image: 'https://res.cloudinary.com/tech-qalanders/image/upload/v1616590298/chex-instruction/Photot710Tire_xtabgg.jpg',
      loading: false,
    },
  ],
  tire: [   
    {
      id: 'tire',
      type: 'Video',
      title: 'Tire Tread',
      url: '',
      fileId: null,
      description: 'Please  record a video of each tire with a penny placed on the tire tread with heads on the penny upside down and facing the camera',
      groupType: 'tire',
      steps: [],
      // video: 'https://res.cloudinary.com/dgjordf6e/video/upload/v1690442359/img_2525__copy_1080p_ebk5oi.mp4',
      video: 'https://chex-ai-uploads.s3.amazonaws.com/chex-dsp/img_2525_(copy)+(1080p).MP4',
      loading: false,
    },
   
  ],
  isVehicleLoading: false,
};
const vehicleInsturctionsReducer = createReducer(initialState, {
  [types.SET_CAR_VERIFICATION_ITEMS](state, action) {
    action?.carVerificationItem.forEach((x) => {
      var item = state.verificationItem.find((a) => a.id === x.category);
      if (item) {
        item.url = x.url;
        item.fileId = x.id;
      }
    });
    return {
      ...state,
    };
  },
  [types.SET_EXTERIOR_ITEMS](state, action) {
    action?.exteriorItems.forEach((x) => {
      var item = state.exteriorItems.find((a) => a.id === x.category);
      if (item) {
        item.url = x.url;
        item.fileId = x.id;
      }
    });
    return {
      ...state,
    };
  },
  [types.SET_TIRES](state, action) {
    action?.tires.forEach((x) => {
      var item = state.tires.find((a) => a.id === x.category);
      if (item) {
        item.url = x.url;
        item.fileId = x.id;
      }
    });
    return {
      ...state,
    };
  },
  [types.SET_TIRE](state, action) {
    action?. tires.forEach((x) => {
      var item = state.tire.find((a) => a.id === x.category);
      if (item) {
        item.url = x.url;
        item.fileId = x.id;
      }
    });
    return {
      ...state,
    };
  },
  [types.SET_DELETE_LOCAL_FILE](state, action) {
    var groupType = action.deleteFileData?.groupType;
    if (groupType === 'carVerificiationItems') {
      let item = state.verificationItem.find((a) => a.fileId === action.deleteFileData?.fileId);
      if (item) {
        item.url = '';
      }
    }  else if (groupType === 'exteriorItems') {
      let item = state.exteriorItems.find((a) => a.fileId === action.deleteFileData?.fileId);
      if (item) {
        item.url = '';
      }
    } else if (groupType === 'tires') {
      let item = state.tires.find((a) => a.fileId === action.deleteFileData?.fileId);
      if (item) {
        item.url = '';
      }
    }
    else if (groupType === 'tire') {
      let item = state.tire.find((a) => a.fileId === action.deleteFileData?.fileId);
      if (item) {
        item.url = '';
      }
    }
    return {
      ...state,
    };
  },
  [types.IS_Vehicle_LOADING](state, action) {
    return {
      ...state,
      isVehicleLoading: action.isVehicleLoading,
    };
  },
  [types.SET_VEHICLE_STATUS](state, action) {
    return {
      ...state,
      vehicleStatus: action.vehicleStatus,
    };
  },
  [types.SET_CERTIFICATE_DATA](state, action) {
    return {
      ...state,
      vehicleCertificate: action.vehicleCertificate,
    };
  },
  [types.SET_TURO_CERTIFICATE_DATA](state, action) {
    return {
      ...state,
      turoVehicleCertificate: action.turoVehicleCertificate,
    };
  },
  [types.SET_UBER_CERTIFICATE_DATA](state, action) {
    return {
      ...state,
      uberVehicleCertificate: action.uberVehicleCertificate,
    };
  },
  [types.SET_GetAround_CERTIFICATE_DATA](state, action) {
    return {
      ...state,
      getAroundCertificate: action.getAroundCertificate,
    };
  },

  [types.SET_LYFT_CERTIFICATE_DATA](state, action) {
    return {
      ...state,
      lyftVehicleCertificate: action.lyftVehicleCertificate,
    };
  },
  [types.SET_INSPECTION_BY_STATUS](state, action) {
    return {
      ...state,
      inspectionByStatus: action.inspectionByStatus,
    };
  },
  [types.SET_INSPECTION_DATA](state, action) {

    return {
      ...state,
      inspectionData: action.inspectionData,
    };
  },
  [types.SET_VEHICLE_DETAILS](state, action) {
    return {
      ...state,
      vehicleDetails: action.vehicleDetails,
    };
  },
  [types.SET_FILE_DETAILS](state, action) {
    return {
      ...state,
      fileDetails: action.fileDetails,
    };
  },
});
export default vehicleInsturctionsReducer;
