/* eslint-disable */

import React,{useEffect} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Collapse, Row, Col } from 'antd';
import { Form, Input, Button } from "antd";
import Modal from 'react-modal';
import {BrowserView, MobileView, isBrowser, isMobile, browserName} from 'react-device-detect';
import { UpCircleOutlined } from '@ant-design/icons';
import { AiOutlineCheck } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import {
  InspectionCard,
  InstructionModal,
  Header,
  SurveyModal,
  ImageConfirmationModal,
  ConfimrationModal,
  VideoConfirmationModal,
} from '../../Components';
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  VehicleInspectionP,
  ContentVehicleInspection,
  ContentFooterareaVehicleinspection,
  MainBgInsertDetails,
  MobileViewWarningContainer,
  MobileViewWarning,
} from './style.js';
import './style.css';

const { Panel } = Collapse;

const VehicleInspectionScreen = ({
                                   vehicleInstructionValues,setVehicleDetailModal,
                                   isModalVisible,
                                   aiLoading,
                                   handleModal,
                                   isDisabled,
                                   handleImageUpload,
                                   vehicleInstructions,
                                   handleVideoUpload,
                                   isLoading,
                                   handleModalClose,
                                   isSurveyModalVisible,
                                   handleSurveyModal,
                                   changeRating,
                                   rating,
                                   commentError,
                                   handleCheckBox,
                                   handleComment,
                                   handleSubmitSurvey,
                                   deleteFile,
                                   handleDeleteModal,
                                   surveyModalLoading,
                                   isDeleteModal,
                                   setDeleteModal,
                                   deleteLoading,
                                   uploadingPercentage,
                                   paymentStatus,
                                   changeVehicleStatus,
                                   vehicleStatusLoading,
                                   currentUser,
                                   handleSkipPayment,
                                   vehicleStatus,
                                   match,
                                   lyftUser,
                                   modal,
                                   handleImageConfirmation,
                                   aiStatus,
                                   handleImageDeny,
                                   isVideoModal,
                                   handleVideoConfirmation,
                                   handleVideoDeny,
                                     handleImageControl,
                                     locationMap,
                                     handleImageControlvin,
                                     vinwebcam,
                                     webRef,
                                     handlecameraOpen,
                                     imageConstraints,
                                     clickPic,
                                     WebCameraOpen,
                                     handleClickPicture,
                                     handleCamerarotation,
                                     capturedImage,
                                     captureAreaRef ,
                                     saveImage,
                                     handleSaveImage,
                                     isEditable,
                                     handleEdit,
                                     vehicleDetailModal,
                                     initialFieldValues,
                                     handleFormSubmit,
                                     loadingDetail,
                                     fetchError,
                                     handleSubmitInspection,
                                     detailEnter, isMobile,
                                     showOneTire,
                                     loadingsubmit,handleSubmitInfo,
                                 }) => {
  const history = useHistory();
  const [form] = Form.useForm();


  const genExtra = () => (
      <UpCircleOutlined
          onClick={(event) => {
            event.stopPropagation();
          }}
      />

      
  );
  // const customModalStyle = {
  //   // Define your custom styles here
  //   background: 'lightblue',
  //   color: 'darkblue',
  // };
  return (
      <MainContainer>
        <ImageBackgroundVehicleInspection>
          <MainBgInsertDetails>
            <ContentVehicleInspection>
              <div className="header-min-width">
                <div className="header-max-width">
                  <Header title={true} arrow={false} />
                </div>
              </div>
              <MainDownContainer>
                <VehicleInspectionP>
                  Please complete inspection items within each category below
                </VehicleInspectionP>
                  {/*<MobileView>*/}
                  {/*    <p> Mobile View content</p>*/}
                  {/*</MobileView>*/}
                  {/*{*/}
                  {/*    isMobile ? "Mobile " : "Laptop"*/}
                  {/*}*/}
              </MainDownContainer>
            </ContentVehicleInspection>

            <MainDownContainer>
              <ContentFooterareaVehicleinspection>
                <div className="veh-inspection-mobilepaddind">
                  <MobileViewWarningContainer>
                    <MobileViewWarning>
                      <i>
                        Please use <b>mobile phone </b>for optimal perfomance
                      </i>

                    </MobileViewWarning>
                  </MobileViewWarningContainer>
                </div>
                <div className="vehicle-inspection-collapse-style">
                  <Collapse
                      className="vehicle-inspection-content-container"
                      defaultActiveKey={['1']}
                      expandIconPosition="right"
                      expandIcon={({ isActive }) => (
                          <UpCircleOutlined
                              style={
                                isActive
                                    ? { fontSize: 35, color: '#FF7A00', marginTop: 0 }
                                    : { fontSize: 35, color: 'lightGray', marginTop: 0 }
                              }
                              rotate={isActive ? 180 : 0}
                          />
                      )}
                  >
                    <Panel
                        style={{ overflow: 'hidden' }}
                        header={
                          <Row
                              gutter={40}
                              style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                          >
                            {lyftUser == 'true' ? (
                                <Col>
                                  {vehicleInstructions?.verificationItem.filter((e) => e.url).length ===
                                  3 ? (
                                      <div className="collapse-completed-number-container"><AiOutlineCheck /></div>
                                  ) : (
                                      <div className="collapse-number-container">1</div>
                                  )}
                                </Col>
                            ) : (
                                <Col>
                                  {vehicleInstructions?.verificationItem.filter((e) => e.url).length ===
                                  2 ? (
                                      <div className="collapse-completed-number-container"><AiOutlineCheck /></div>
                                  ) : (
                                      <div className="collapse-number-container">1</div>
                                  )}
                                </Col>
                            )}
                            <Col>
                              <div className="veh-inspection-verification_text">
                                Car Verification Items
                              </div>
                            </Col>
                            <Col></Col>
                          </Row>
                        }
                        key="1"
                        extra={genExtra}
                    >
                      <div className="veh-inspection-cards_container">
                        {vehicleInstructions?.verificationItem?.map(
                            (item, index) => (
                                <>
                                  {lyftUser == 'true' ? (
                                      <div className="veh-inspection-first_card">
                                        <InspectionCard
                                            key={index}
                                            deleteFile={handleDeleteModal}
                                            groupType="carVerificiationItems"
                                            item={item}
                                            handleModal={handleModal}
                                            category={item.id}
                                            title={item.title}
                                            titletwo={item.type}
                                            type={item.type}
                                        />
                                      </div>
                                  ) : (
                                      //  currentUser?.lyftUser === null ? (
                                      <div className="veh-inspection-first_card">
                                        {item.lyft ? null : (
                                            <InspectionCard
                                                key={index}
                                                deleteFile={handleDeleteModal}
                                                groupType="carVerificiationItems"
                                                item={item}
                                                handleModal={handleModal}
                                                category={item.id}
                                                locationMap={locationMap}
                                                title={item.title}
                                                titletwo={item.type}
                                                type={item.type}
                                            />
                                        )}
                                      </div>
                                  )}
                                </>
                            )
                            // )
                            //  : null
                        )}
                      </div>
                    </Panel>
                  
                    <Panel
                        style={{ overflow: 'hidden' }}
                        header={
                          <Row
                              gutter={40}
                              style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                          >
                            <Col>
                              {vehicleInstructions?.exteriorItems.filter((e) => e.url).length === 4 ? (
                                  <div className="collapse-completed-number-container"><AiOutlineCheck /></div>
                              ) : (
                                  <div className="collapse-number-container">2</div>
                              )}
                            </Col>
                            <Col>
                              <div className="veh-inspection-verification_text">Exterior Items</div>
                            </Col>
                            <Col></Col>
                          </Row>
                        }
                        key="3"
                        extra={genExtra}
                    >
                      <div className="veh-inspection-cards_container">
                        {vehicleInstructions?.exteriorItems?.map((item, index) => (
                            <div className="veh-inspection-first_card">
                              <InspectionCard
                                  deleteFile={handleDeleteModal}
                                  groupType="exteriorItems"
                                  item={item}
                                  key={index}
                                  locationMap={locationMap}
                                  handleModal={handleModal}
                                  category={item.id}
                                  title={item.title}
                                  titletwo={item.type}
                                  type={item.type}
                                  isModalVisible={isModalVisible}
                              />
                            </div>
                        ))}
                      </div>
                    </Panel>
                    <Panel
                        style={{ overflow: 'hidden' }}
                        header={
                          <Row
                              gutter={40}
                              style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}
                          >
                            <Col>
                              {vehicleInstructions?.tires.filter((e) => e.url).length === 4 || vehicleInstructions?.tire.filter((e) => e.url).length === 1? (
                                  <div className="collapse-completed-number-container"><AiOutlineCheck /></div>
                              ) : (
                                  <div className="collapse-number-container">3</div>
                              )}
                            </Col>
                            <Col>
                              <div className="veh-inspection-verification_text">Tires</div>
                            </Col>
                            <Col></Col>
                          </Row>
                        }
                        key="4"
                        extra={genExtra}
                    >
                      <div className="veh-inspection-cards_container">
                        { showOneTire=== true ||  vehicleInstructions?.tire.filter((e) => e.url).length === 1 ?  
                         vehicleInstructions?. tire?.map((item, index) => (
                          <div className="veh-inspection-first_card">
                            <InspectionCard
                                deleteFile={handleDeleteModal}
                                groupType="tire"
                                item={item}
                                key={index}
                                handleModal={handleModal}
                                category={item.id}
                                title={item.title}
                                locationMap={locationMap}
                                titletwo={item.type}
                                type={item.type}
                            />
                          </div>
                      )):
                      vehicleInstructions?.tires?.map((item, index) => (
                        <div className="veh-inspection-first_card">
                          <InspectionCard
                              deleteFile={handleDeleteModal}
                              groupType="tires"
                              item={item}
                              key={index}
                              handleModal={handleModal}
                              category={item.id}
                              title={item.title}
                              locationMap={locationMap}
                              titletwo={item.type}
                              type={item.type}
                          />
                        </div>
                    ))
                        }
                       
                      </div>
                    </Panel>
                  </Collapse>
                </div>

                {lyftUser == 'true' ? (
                    <div>
                      {paymentStatus && paymentStatus == true ? (
                          <>
                            { vehicleInstructions?.exteriorItems.filter((e) => e.url).length === 4 &&
                            ( vehicleInstructions?.tires.filter((e) => e.url).length === 4 ||
                            vehicleInstructions?.tire.filter((e) => e.url).length === 1) &&
                            vehicleInstructions?.verificationItem.filter((e) => e.url).length === 3 ? (
                                <div className="vec-inspection-submitbtn_container">
                                  <button
                                      disabled={vehicleStatusLoading}
                                      onClick={changeVehicleStatus}
                                      className="vec-inspection-submit_button"
                                  >
                                    {vehicleStatusLoading ? <ClipLoader color={'white'} size={20} /> : 'Submit'}
                                  </button>
                                </div>
                            ) : (
                                <div className="vec-inspection-submitbtn_container">
                                  <button
                                      style={{ background: 'gray' }}
                                      disabled={true}
                                      className="vec-inspection-submit_button"
                                  >
                                    Submit
                                  </button>
                                </div>
                            )
                            }
                          </>
                      ) : vehicleInstructions?.exteriorItems.filter((e) => e.url).length === 4 &&
                      ( vehicleInstructions?.tires.filter((e) => e.url).length === 4 ||
                      vehicleInstructions?.tire.filter((e) => e.url).length === 1)&&
                      vehicleInstructions?.verificationItem.filter((e) => e.url).length === 3 ? (
                          <div className="vec-inspection-submitbtn_container">
                            <button
                                onClick={handleSubmitInspection}
                                className="vec-inspection-submit_button"
                            >
                             {loadingsubmit ? (
                                      <ClipLoader color={'white'} size={20} />
                                  ) : (
                                      'Submit'
                                  )}
                            </button>
                          </div>
                      ) : (
                          <div className="vec-inspection-submitbtn_container">
                            <button
                                style={{ background: 'gray' }}
                                disabled={true}
                                className="vec-inspection-submit_button"
                            >
                              Submit
                            </button>
                          </div>
                      )}
                    </div>
                ) : (
                    <>
                      {vehicleInstructions?.exteriorItems.filter((e) => e.url)?.length === 4 &&
                      ( vehicleInstructions?.tires.filter((e) => e.url).length === 4 ||
                      vehicleInstructions?.tire.filter((e) => e.url).length === 1)&&
                      vehicleInstructions?.verificationItem.filter((e) => e.url)?.length === 2 &&
                      ((vehicleStatus &&
                              vehicleStatus?.inspectionCompanies?.length == 1 &&
                              ((vehicleStatus?.inspectionCompanies[0]?.Company?.name.toLowerCase() == 'zum')))
                          // ||
                          // (vehicleStatus &&
                          //     vehicleStatus?.inspectionCompanies?.length == 2 &&
                          //     ((vehicleStatus?.inspectionCompanies[0]?.Company?.name.toLowerCase() == 'zum') ||
                          //         (vehicleStatus?.inspectionCompanies[1]?.Company?.name.toLowerCase() == 'zum')))
                      ) ? (
                          <div className="vec-inspection-submitbtn_container">
                            {paymentStatus && paymentStatus == true ? (
                                <button
                                    disabled={vehicleStatusLoading}
                                    onClick={changeVehicleStatus}
                                    className="vec-inspection-submit_button"
                                >
                                  {vehicleStatusLoading ? (
                                      <ClipLoader color={'white'} size={20} />
                                  ) : (
                                      'Submit'
                                  )}
                                </button>
                            ) : (
                                <button
                                    disabled={vehicleStatusLoading}
                                    // onClick={()=>history.push(`/checkoutScreen/${match?.id}/${match.vehicleId}`)}
                                    // onClick={() => handleSkipPayment(paymentStatus)}
                                    onClick={handleSubmitInspection}
                                    className="vec-inspection-submit_button"
                                >
                                 {loadingsubmit ? (
                                      <ClipLoader color={'white'} size={20} />
                                  ) : (
                                      'Submit'
                                  )}
                                </button>
                            )}
                          </div>
                      ) : vehicleInstructions?.exteriorItems.filter((e) => e.url).length === 4 &&
                      ( vehicleInstructions?.tires.filter((e) => e.url).length === 4 ||
                      vehicleInstructions?.tire.filter((e) => e.url).length === 1) &&
                      vehicleInstructions?.verificationItem.filter((e) => e.url).length === 2 &&
                      paymentStatus &&
                      paymentStatus == true ? (
                          <div className="vec-inspection-submitbtn_container">
                            <button
                                disabled={vehicleStatusLoading}
                                onClick={changeVehicleStatus}
                                className="vec-inspection-submit_button"
                            >
                              {vehicleStatusLoading ? <ClipLoader color={'white'} size={20} /> : 'Submit'}
                            </button>
                          </div>
                      ) : vehicleInstructions?.exteriorItems.filter((e) => e.url).length === 4 &&
                      ( 
                        vehicleInstructions?.tires.filter((e) => e.url).length === 4 ||
                      vehicleInstructions?.tire.filter((e) => e.url).length === 1)&&
                      vehicleInstructions?.verificationItem.filter((e) => e.url).length === 2 ? (
                          <div className="vec-inspection-submitbtn_container">
                            <button
                                onClick={handleSubmitInspection}
                                className="vec-inspection-submit_button"
                            >
                             {loadingsubmit ? (
                                      <ClipLoader color={'white'} size={20} />
                                  ) : (
                                      'Submit'
                                  )}
                            </button>
                          </div>
                      ) : (
                          <div className="vec-inspection-submitbtn_container">
                            <button
                                style={{ background: 'gray' }}
                                disabled={true}
                                className="vec-inspection-submit_button"
                            >
                              Submit
                            </button>
                          </div>
                      )}
                    </>
                )}
              </ContentFooterareaVehicleinspection>
  <Modal
        isOpen={vehicleDetailModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            maxWidth: '350px',
            height:'33vh',
            margin: 'auto',
            background: '#D9D9D9',
          },
        }}
      >
        <div>
          {/* <div style={{fontSize:'16px',textAlign:'center', color:'#FF0000',fontWeight:'700'}}>Discrepancy Detected</div> */}
          {/* This text will wrap within the modal */}
          {fetchError?
          <>
          <div style={{fontSize:'16px',textAlign:'center', color:'#FF0000',fontWeight:'700'}}>Note</div>
          <div style={{fontSize:'14px',textAlign:'center',color:'#414B55',fontWeight:'500',marginTop:'10px'}}>
          System was unable to detect the License Plate Number
          </div>
          </>
          :
          <>
          <div style={{fontSize:'16px',textAlign:'center', color:'#FF0000',fontWeight:'700'}}>Discrepancy Detected</div>
           <div style={{fontSize:'14px',textAlign:'center',color:'#414B55',fontWeight:'500',marginTop:'10px'}}>
          Mismatch in License Plate Number detected. Please retake the photo of the license plate of the vehicle selected
          </div>
          </>
          }
          {/* <div style={{fontSize:'14px',textAlign:'center',color:'#414B55',fontWeight:'500',marginTop:'10px'}}>
          Mismatch in License Plate Number detected. Please retake the photo of the license plate of the vehicle selected
          </div> */}
        </div>

        <button
                                    //  htmlType="submit"
                                      onClick={handleSubmitInfo}
                                      className= {fetchError? 'vec-inspection-Confirm-- ' : 'vec-inspection-Confirm__ ' }  
                                  >
                                      Ok
                                  </button>  
      </Modal>


        {/* <Modal
      title="Title"
      visible={true}
      centered={true}
      footer={null} // Remove the default footer
      wrapClassName="modal-container" // Apply the container styles using the class name
    >
        <p>This is the first line of text.</p>
        <p>This is the second line of text.</p>
        <button
                                     htmlType="submit"
                                      // disabled={true}
                                      className=  'vec-inspection-Confirm__ ' 
                                  >
                                       Confirm
                                  </button> 
    </Modal> */}
              <InstructionModal
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  aiLoading={aiLoading}
                  handleImageControl={handleImageControl}
                  handleVideoUpload={handleVideoUpload}
                  vehicleInstructionValues={vehicleInstructionValues}
                  handleImageUpload={handleImageUpload}
                  isModalVisible={isModalVisible}
                  handleModalClose={handleModalClose}
                  uploadingPercentage={uploadingPercentage}
                  locationMap={locationMap}
                  handleImageControlvin={handleImageControlvin}
                  webRef={webRef}
                  vinwebcam={vinwebcam}
                  handlecameraOpen={handlecameraOpen}
                  imageConstraints={imageConstraints}
                  clickPic={clickPic}
                  WebCameraOpen={WebCameraOpen}
                  handleClickPicture={handleClickPicture}
                  handleCamerarotation={handleCamerarotation}
                  capturedImage={capturedImage}
                  captureAreaRef ={ captureAreaRef }
                  saveImage={saveImage}
                  handleSaveImage={handleSaveImage}
                  isMobile={ isMobile}

              />

              <VideoConfirmationModal
                  modal={isVideoModal}
                  aiStatus={aiStatus}
                  handleVideoDeny={handleVideoDeny}
                  handleVideoConfirmation={handleVideoConfirmation}
              />

              <ImageConfirmationModal
                  modal={modal}
                  aiStatus={aiStatus}
                  handleImageDeny={handleImageDeny}
                  handleImageConfirmation={handleImageConfirmation}
              />

              <SurveyModal
                  changeRating={changeRating}
                  rating={rating}
                  commentError={commentError}
                  isSurveyModalVisible={isSurveyModalVisible}
                  handleSurveyModal={handleSurveyModal}
                  handleCheckBox={handleCheckBox}
                  handleComment={handleComment}
                  // commentError={commentError}
                  handleSubmitSurvey={handleSubmitSurvey}
                  surveyModalLoading={surveyModalLoading}
              />
              <ConfimrationModal
                  loading={deleteLoading}
                  handleModal={setDeleteModal}
                  onClickYes={deleteFile}
                  isVisible={isDeleteModal}
              />
            </MainDownContainer>
          </MainBgInsertDetails>
        </ImageBackgroundVehicleInspection>
      </MainContainer>
  );
};
export default VehicleInspectionScreen;
