/* eslint-disable */

import React, {useEffect} from 'react';
import {ClipLoader} from 'react-spinners';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
 import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {PDFDownloadLink, Page, Text, View, Image, Document, StyleSheet, Font} from '@react-pdf/renderer';
//@ts-ignore
import InspectorPoint from '../../Assets/Inspector Point.png';
import ActionCreators from '../../actions';
import {setOtherReportData} from '../../utils/functions';
import ChackboxImg from '../../Assets/Checkbox.png';
import Arial from '../../font/ARIAL.TTF';
import Arialbold from '../../font/ARIALBD 1.TTF'
import Inter from '../../font/Inter-VariableFont.ttf';
import Lato from '../../font/Lato-Regular.ttf';
import './styles.css';
import tickimg from '../../Assets/tickimgOld.png';
import Moment from "react-moment";

Font.register({family: 'Arial', format: "truetype", src: Arial});
Font.register({family: 'Arialbold', format: "truetype", src: Arialbold});
Font.register({family: 'Inter', format: "truetype", src: Inter});
Font.register({family: 'Lato', format: "truetype", src: Lato});

const styles = StyleSheet.create({
    body: {
        paddingBottom: 65,
        paddingHorizontal: 10,
    },
    head: {
        width: '40%'
    },
    headimg: {
        width: '60%',
        marginTop: '60px',
        marginLeft: 60,
    },
    heading: {
        width: '60%',

    },
    headText: {
        marginTop: '60px',
        fontWeight: 'bold',
        fontSize: '27px',
        fontFamily:'Arialbold'

    },
    headparagraph: {
        marginTop: '13px',
        fontWeight: '700',
        fontFamily: 'Arial',
    },
    mainContent: {
        width: '50%',
        marginLeft:10

    },
    Submithead: {
        marginTop: '24px',
        marginLeft: '10px',
        fontFamily: 'Arialbold',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    submitpara: {
        fontWeight: '200',
        marginTop: '8px',
        marginLeft: '10px',
        fontFamily: 'Inter',
        fontSize: '16px',

    },
    TopName: {
        marginTop: '10px',
        marginLeft: '10px',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,

    },
    line: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '8@s',
        marginTop: '15px',
        width: '77%',
    },
    carvinline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '6@s',
        marginTop: '15px',
        width: '82%',
    },
    Licenseline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '9@s',
        marginTop: '15px',
        width: '73%',
    },
    Yearline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '11@s',
        marginTop: '15px',
        width: '69%',
    },
    Registrationline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '22@s',
        marginTop: '15px',
        width: '40%',
    },
    CoNameline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '12@s',
        marginTop: '15px',
        width: '61%',
    },
    CoAddressline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '15@s',
        marginTop: '15px',
        width: '58%',
    },
    Mechanicline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '23@s',
        marginTop: '15px',
        width: '39%',
    },
    Getaroundline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '15@s',
        marginTop: '15px',
        width: '60%',
    },
    Dateline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '11@s',
        marginTop: '15px',
        width: '69%',
    },
    Signatureline: {
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        marginLeft: '15@s',
        marginTop: '10px',
        width: '61%',
    },
    backimg: {
        width: '70%',
        marginTop: '9px',
        marginLeft: 60,
        height: '366px'
    },
    checkbox: {
        border: "1 px solid #000000",
        width: '14.97px',
        height: '14px',
        left: '49px',
        top: '15px',
        position:'relative'
    },
    checkbox1: {
        border: "1 px solid #000000",
        width: '14.97px',
        height: '14px',
        left: '75px',
        top: '15px',

    },
    RegText1: {
        top: '15px',
        left: '83px',
        fontSize: '14px'

    },
    RegText: {
        top: '15px',
        left: '57px',
        fontSize: '14px'

    },
    RightContent: {
        width: '50%',
    },
    righthead: {
        marginTop: '24px',
        fontWeight: 'bold',
        fontFamily: 'Arial',
        left: '14px'
    },
    passTtext: {
        width: '15%',
        marginTop:'24px',
        fontWeight: 'bold',
        fontFamily: 'Arialbold',
        textAlign:'center',
        fontSize:'14px'

    },
    borderbox: {
        border: '1px solid #000000',
        padding: '30px'
    },
    Rightcheckbox:{
        border: "1 px solid #000000",
        width: '15.97px',
        height: '15px',
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:'auto',
        marginBottom:'auto',
        position:'relative'
    },
    content:
        {
            marginLeft: '7%', fontWeight: '500', fontFamily: 'Inter', fontSize: '14px',

            border: '1px solid #000000',
            padding: '8px',
            width: '280px',
            borderTopColor: '#ffffff',

        },
    headcontent:
        {
            marginLeft: '7%', fontWeight: '700', fontFamily: 'Arial', fontSize: '15px',
            top: '6px',
            border: '1px solid #000000',
            padding: '8px',
            width: '280px',
            borderTopColor: '#ffffff',

        },
    bodycontent:{
        display: 'flex',
        flexDirection: 'row'
    },
    rightsideborder:{
        width: '15%',
        border:'1px solid black',
        borderTopWidth: 0,
        borderLeftWidth:0
    },
    formdata:{
        marginTop:'-7',
        fontFamily:'Lato',
        fontSize:'16px',
        marginLeft:'10px'
    },
    Topborder:{
        width: '70%',
        marginLeft: 10,
        border:'1px solid black',
        borderTopWidth: 0
    },
    InspectionTxt:
        {width: '70%',
        marginRight: 10,
        marginTop:'24px',
        marginLeft:14,
        fontSize:'14px',
        fontWeight:'bold',
            fontFamily:'Arialbold'
    }


});

const GetAroundCertificate = (props) => {
    const history = useHistory();
    const {isLoading, handleModal, SetModal} = props;

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const getToken = () => localStorage.getItem('token') || null;
        if (getToken) {
            props.getVehicleCertificate({id: props?.id, companyId: props.companyId}, props.setLoading, 'uber');
        } else {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
            localStorage.removeItem('vehicleData');
            window.location.reload('/login');
        }
    }, [props?.companyId]);

    const handleReload = () => {
        SetModal(false);
        localStorage.setItem('tabStatus', 'REVIEWED');
        setTimeout(function () {
            handleModal();
        }, 200);
    };
    return (
        <div className="App">
            <PDFDownloadLink
                wrap={false}
                document={
                    <Document>
                        <Page size="A3" style={styles.body}>
                            <View style={styles.bodycontent}>
                                <View style={styles.head}>
                                    <Image style={styles.headimg}
                                           src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1677489133/Getaround_Logo_1_ms5nbk.png'}/>
                                </View>
                                <View style={styles.heading}>
                                    <Text style={styles.headText}>Getaround Vehicle Inspection Form</Text>
                                    <Text style={styles.headparagraph}>To qualify, your vehicle must pass all inspection
                                        points. This inspection form must be completed by a certified mechanic.</Text>

                                </View>
                            </View>
                            <View style={styles.bodycontent}>
                                <View style={styles.mainContent}>
                                    <Text style={styles.Submithead}>
                                        Submit your inspection to Getaround
                                    </Text>
                                    <Text style={styles.submitpara}>
                                        Once the vehicle has passed inspection, the host must email this inspection form to their Getaround Account Executive.
                                    </Text>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Host Name</Text>
                                        <View style={styles.line}>
                                            <Text style={styles.formdata}>{`${props.reportData && props.reportData?.CertificateData?.Host_Name
                                            } ${props.reportData && props.reportData?.CertificateData?.Host_Last_Name
                                            }`}</Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Host Email</Text>
                                        <View style={styles.line}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Host_Email}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Car VIN</Text>
                                        <View style={styles.carvinline}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Vin} </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Car Mileage</Text>
                                        <View style={styles.line}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Mileage}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>License Plate</Text>
                                        <View style={styles.Licenseline}>
                                            <Text style={{marginTop:'-7',fontFamily:'Lato',fontSize:'14px',marginLeft:'5px'}}>
                                                {props.reportData && props.reportData?.CertificateData?.licensePlateNumber}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Year/Make/Model </Text>
                                        <View style={styles.Yearline}>
                                            <Text style={{...styles.formdata,fontSize:'9.7',marginTop:'-4',}}>
                                                {`${props.reportData && props.reportData?.CertificateData?.Year
                                                }/ ${props.reportData && props.reportData?.CertificateData?.Make
                                                } /${props.reportData && props.reportData?.CertificateData?.Model
                                                }`}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Has Current Registration Sticker</Text>
                                        <View style={styles.checkbox}>
                                            <Image style={{position:'absolute',height:'12px' }} src={tickimg}/>
                                            {/*{props.reportData && props.reportData?.CertificateData?.Registration_Stricker?.status==true? (*/}
                                            {/*    <Image style={{position:'absolute',height:'12px' }} src={tickimg}/>*/}
                                            {/*    ):null*/}
                                            {/*}*/}
                                        </View><Text style={styles.RegText}>Yes</Text>
                                        <View style={styles.checkbox1}>
                                            {/*{props.reportData && props.reportData?.CertificateData?.Registration_Stricker?.status==false? (*/}
                                            {/*    <Image style={{position:'absolute',height:'12px' }} src={tickimg}/>*/}
                                            {/*):null*/}
                                            {/*}*/}
                                        </View><Text style={styles.RegText1}>No</Text>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Registration Sticker Month/Year</Text>
                                        <View style={styles.Registrationline}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Registration_Stricker_Month_Year}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Inspection Co Name</Text>
                                        <View style={styles.CoNameline}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Inspection_Co_Name}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Inspection Co Address</Text>
                                        <View style={styles.CoAddressline}>
                                            <Text style={{marginTop:'-4',fontFamily:'Lato',fontSize:'9.7px',marginLeft:'5px'}}>
                                                {props.reportData && props.reportData?.CertificateData?.Inspection_Co_Address}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Mechanic's Certification Number</Text>
                                        <View style={styles.Mechanicline}>
                                            <Text style={styles.formdata}>
                                                {props.reportData && props.reportData?.CertificateData?.Certification_Number}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Getaround Car Name</Text>
                                        <View style={styles.Getaroundline}>
                                            <Text style={{marginTop:'-7',fontFamily:'Lato',fontSize:'16px',marginLeft:'10px'}}>
                                                {props.reportData && props.reportData?.CertificateData?.Getaround_Car_Name}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Inspection Date</Text>
                                        <View style={styles.Dateline}>
                                            <Text style={styles.formdata}>
                                                {moment(props.reportData && props.reportData?.CertificateData?.Inspection_Date).format("MM-DD-YYYY")}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Inspector Name</Text>
                                        <View style={styles.Dateline}>
                                            <Text style={styles.formdata}>
                                                {`${props.reportData && props.reportData?.CertificateData?.Inspector_Name
                                                } ${props.reportData && props.reportData?.CertificateData?.Inspector_Last_Name
                                                }`}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row'}}>
                                        <Text style={styles.TopName}>Inspector Signature</Text>
                                        <View style={styles.Signatureline}>
                                            {props.reportData && props.reportData?.CertificateData?.Inspector_Signature ? (
                                                <Image
                                                    style={{ width: "130px",
                                                        height: "40px",
                                                        marginLeft: "50px",
                                                        marginBottom: -23, }}
                                                    source={`${process.env.REACT_APP_AWS_S3_LINK}/${props.reportData && props.reportData?.CertificateData?.Inspector_Signature}?x-request=xhr`}
                                                />
                                            ) : (
                                                <Text style={{marginTop:'-10',fontFamily:'Lato',fontSize:'14px',marginLeft:'5px'}}>{`${props.reportData && props.reportData?.CertificateData?.Inspector_Name
                                                } ${props.reportData && props.reportData?.CertificateData?.Inspector_Last_Name
                                                
                                                }`}</Text>
                                            )}
                                        </View>
                                    </View>
                                    <Text style={{...styles.submitpara,paddingRight:10}}> Use this diagram to indicate all visible damage on
                                        the vehicle including dents, rust, etc.</Text>
                                    <View>
                                        <Image style={styles.backimg}
                                               src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1677503568/Screen_Shot_2023-02-22_at_9.15_1_pisdyv.png'}/>
                                    </View>
                                </View>
                                <View style={styles.RightContent}>
                                    <Image
                                        style={{height:'925',marginLeft:'4%',marginTop:'23px',width:'90%'}}
                                        source={InspectorPoint} alt="img"
                                    />

                                    {/*<View style={{display:'flex', flexDirection:'row',padding: 3}}>*/}
                                    {/*    <View style={styles.InspectionTxt}>*/}
                                    {/*        <Text>*/}
                                    {/*           Inspection Point*/}
                                    {/*        </Text>*/}

                                    {/*    </View>*/}
                                    {/*    <View style={styles.passTtext}>*/}
                                    {/*        <Text>*/}
                                    {/*            Pass*/}
                                    {/*        </Text>*/}

                                    {/*    </View>*/}
                                    {/*    <View style={styles.passTtext}>*/}
                                    {/*        <Text>*/}
                                    {/*            Fail*/}
                                    {/*        </Text>*/}

                                    {/*    </View>*/}
                                    {/*</View>*/}
                                    {/*<View style={{display:'flex', flexDirection:'column', }}>*/}

                                    {/*    {props?.reportData?.CertificateData?.Inspection_Point?.map((checks, index) =>*/}
                                    {/*        (*/}
                                    {/*            <View>*/}
                                    {/*                {checks?.category_Name=='Tires'?(*/}
                                    {/*                    <View>*/}
                                    {/*                    <View style={{display:'flex', flexDirection:'row',padding: 3,marginTop:'-7px' }}>*/}
                                    {/*                        <View style={styles.Topborder}>*/}
                                    {/*                            <Text style={{fontFamily:'Arialbold',fontWeight:700,marginLeft:'1%',fontSize:'14px',paddingTop:5,paddingBottom: 5}}>*/}
                                    {/*                                {checks?.category_Name}*/}
                                    {/*                            </Text>*/}
                                    {/*                        </View>*/}
                                    {/*                        <View style={styles.rightsideborder}>*/}
                                    {/*                            <View style={styles.Rightcheckbox}>*/}
                                    {/*                                {checks?.check[0]?.status==true?(*/}
                                    {/*                                    <Image style={{position:'absolute',height:'13px' }} src={tickimg}/>*/}
                                    {/*                                ):null}*/}
                                    {/*                            </View>*/}
                                    {/*                        </View>*/}
                                    {/*                        <View style={styles.rightsideborder}>*/}
                                    {/*                            <View style={styles.Rightcheckbox}>*/}
                                    {/*                                {checks?.check[0]?.status==false?(*/}
                                    {/*                                    <Image style={{position:'absolute',height:'13px' }} src={tickimg}/>*/}
                                    {/*                                ):null}*/}
                                    {/*                            </View>*/}
                                    {/*                        </View>*/}
                                    {/*                    </View>*/}
                                    {/*                        {checks?.check?.map((data,ind)=>*/}
                                    {/*                            <View>*/}
                                    {/*                                <View style={{display:'flex', flexDirection:'row',padding: 3,marginTop:'-7px' }}>*/}
                                    {/*                                    <View style={styles.Topborder}>*/}
                                    {/*                                        <Text style={{fontFamily:'Inter',fontSize:'14px',width:'97%',marginLeft:'1%',paddingTop:5,paddingBottom: 5}}>*/}
                                    {/*                                            {data?.Check?.name}*/}
                                    {/*                                        </Text>*/}

                                    {/*                                    </View>*/}
                                    {/*                                    <View style={styles.rightsideborder}>*/}
                                    {/*                                    </View>*/}
                                    {/*                                    <View style={styles.rightsideborder}>*/}
                                    {/*                                    </View>*/}
                                    {/*                                </View>*/}
                                    {/*                            </View>*/}

                                    {/*                        )}*/}
                                    {/*                    </View>*/}
                                    {/*                ):*/}
                                    {/*                    <View>*/}
                                    {/*                <View style={{display:'flex', flexDirection:'row',padding: 3,marginTop:'-7px',Top:24 }}>*/}
                                    {/*                    <View style={styles.Topborder}>*/}
                                    {/*                        <Text style={{fontFamily:'Arialbold',fontWeight:700,marginLeft:'1%',fontSize:'14px',paddingTop:5,paddingBottom: 5}}>*/}
                                    {/*                            {checks?.category_Name}*/}
                                    {/*                        </Text>*/}
                                    {/*                    </View>*/}
                                    {/*                    <View style={styles.rightsideborder}>*/}
                                    {/*                    </View>*/}
                                    {/*                    <View style={styles.rightsideborder}>*/}
                                    {/*                    </View>*/}
                                    {/*                </View>*/}
                                    {/*                        {checks?.check?.map((data,ind)=>*/}
                                    {/*                            <View>*/}
                                    {/*                                <View style={{display:'flex', flexDirection:'row',padding: 3,marginTop:'-7px' }}>*/}
                                    {/*                                    <View style={styles.Topborder}>*/}
                                    {/*                                        <Text style={{fontFamily:'Inter',fontSize:'14px',width:'95%',marginLeft:'1%',paddingTop:5,paddingBottom: 5}}>*/}
                                    {/*                                            {data?.Check?.name}*/}
                                    {/*                                        </Text>*/}

                                    {/*                                    </View>*/}
                                    {/*                                    <View style={styles.rightsideborder}>*/}
                                    {/*                                        <View style={styles.Rightcheckbox}>*/}
                                    {/*                                            {checks?.check[ind]?.status==true?(*/}
                                    {/*                                                <Image style={{position:'absolute',height:'13px' }} src={tickimg}/>*/}
                                    {/*                                            ):null}*/}
                                    {/*                                        </View>*/}
                                    {/*                                    </View>*/}
                                    {/*                                    <View style={styles.rightsideborder}>*/}
                                    {/*                                        <View style={styles.Rightcheckbox}>*/}
                                    {/*                                            {checks?.check[ind]?.status==false?(*/}
                                    {/*                                                <Image style={{position:'absolute',height:'13px' }} src={tickimg}/>*/}
                                    {/*                                            ):null}*/}
                                    {/*                                        </View>*/}
                                    {/*                                    </View>*/}
                                    {/*                                </View>*/}
                                    {/*                                {ind==checks?.check?.length-1?(*/}
                                    {/*                                    <View style={{display:'flex', flexDirection:'row',padding: 3,marginTop:'-7px' }}>*/}
                                    {/*                                        <View style={styles.Topborder}>*/}
                                    {/*                                            <View style={{padding:12}}></View>*/}
                                    {/*                                        </View>*/}
                                    {/*                                        <View style={styles.rightsideborder}>*/}

                                    {/*                                        </View>*/}
                                    {/*                                        <View style={styles.rightsideborder}>*/}
                                    {/*                                        </View>*/}
                                    {/*                                    </View>*/}
                                    {/*                                ):null}*/}
                                    {/*                            </View>*/}
                                    {/*                        )}*/}
                                    {/*                    </View>*/}
                                    {/*                }*/}
                                    {/*            </View>*/}

                                    {/*        )*/}
                                    {/*    )}*/}
                                    {/*</View>*/}


                                </View>
                            </View>

                        </Page>

                    </Document>
                }
                fileName="Vehicle-certificate.pdf"
            >
                {({blob, url, loading, error}) => (
                    <>
                        {isLoading ? (
                            <ClipLoader color={'#246DB5'} size={40}/>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div
                                    onClick={() => handleReload()}
                                    style={{
                                        fontSize: '15px',
                                        marginBottom: '20px',
                                        marginTop: '20px',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        margin: 'auto',
                                        background: '#3276ba',
                                        padding: '13px',
                                        borderRadius: '50px',
                                    }}
                                >
                                    Download vehicle report
                                </div>
                            </div>
                        )}
                    </>
                )}
            </PDFDownloadLink>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
    return {
        reportData: state.vehicleInstruction.getAroundCertificate,
        // checksData: setOtherReportData(state.vehicleInstruction.uberVehicleCertificate?.CertificateData, 'index_2'),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetAroundCertificate);
