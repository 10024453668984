import React from 'react'
import './style.css'
import LandingBannerComponent from "../../Components/LandingBannerComponent";
import {
    AboutChexComponent,
    CommunityBannerComponent, CustomFooter, CustomReviewsComponent,
    HelpBusinessSectionComponent,
    HomeBadgeBoxComponent, PricingCardRide, WhyChexAiComponent
} from "../../Components";
import {Button, Form, Input} from "antd";
import InputMask from "react-input-mask";
import ClipLoader from "react-spinners/ClipLoader";
import {GoPlus} from "react-icons/go";
import {Link} from "react-router-dom";

const RideshareScreen = ({handleSubmit, handleReDirect, handleCheckBox, handleLoginRedirect, handleTermsOfUse, isLoading, breakPoints}) =>{
    return(
        <div>
            <LandingBannerComponent
                HomeLandingText={<div> <h3>Vehicle  <span className='darkBlue'> INSPECTION </span> PLATFORM </h3></div>}
                BannerImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470838/Group_470_y8tzo1_umxqrk.png'}
                BannerTextPara={'Our solution is designed for convenience. Complete inspection requirement online through your phone'}
                // redirectionPage={"/register"}
            />

            <div className='box_badge_wrapper_outer'>
                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470850/image_13_Traced_vewxrn_tsjvtz.png"}
                    badgeHeading={"Easy & Convenient"}
                    badgeInfo={"Chex.AI's mobile web app is designed for you to conduct inspections at your convenience"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470849/image_14_Traced_qmtupw_v6rhgv.png"}
                    badgeHeading={"Advanced & Efficient"}
                    badgeInfo={"Our system uses machine learning technology which gives you results instantly"}

                />

                <HomeBadgeBoxComponent
                    badge_image={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470851/image_15_Traced_qsaw9d_uxy09q.png"}
                    badgeHeading={"Cost Effective"}
                    badgeInfo={"We provide the best service at a lower cost when compared to competition"}

                />

            </div>


            <div className='form_section_main_outer' id="wrapper_form_id">
                <div className='form_section_main_inner'>
                    <div className='form_section_image_wrapper'>
                        <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470831/Group_423_v53nfc_wuyezc.png'} alt=""/>
                    </div>
                    <div className='form_section_content_wrapper'>
                        <h2 className='form_heading_title'>
                            <span>Sign up</span> and start vehicle inspection
                        </h2>

                        <div className='form_wrapper_main'>
                            <div className="">
                                <Form
                                    className="form"
                                 autoComplete="off"
                                    // {...layout}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={handleSubmit}
                                >
                                    <div className="namingSections_chex">
                                        <Form.Item
                                            name="name"
                                            className="form-item-style-chex_first_ride"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your first name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="input_field_chex_ride"
                                                placeholder="First name"
                                                autoComplete="off"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="last_name"
                                            className="form-item-style-chex_first_ride"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your last name!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="input_field_chex_ride"
                                                placeholder="Last name"
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        name="email"
                                        type="email"
                                        className="form-item-style-chex"
                                        rules={[
                                            {
                                                // type: 'email',
                                                required: true,
                                                message: "Please enter valid email!",
                                                pattern: new RegExp(
                                                    /^\s*(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input className="input_field_chex_ride" placeholder="Email"  autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        name="phone"
                                        className="form-item-style"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter phone number!",
                                            },
                                        ]}
                                    >
                                        <InputMask
                                            mask="+1(999)999 9999"
                                            className="form-control"
                                            id="phone"
                                            placeholder="phone number"
                                            type="text"
                                            name="phone"
                                            autoComplete="phone"
                                            required
                                            disabled={false}
                                        >
                                            {() => (
                                                <Input
                                                    id="phone"
                                                    placeholder="phone number"
                                                    type="text"
                                                    name="phone"
                                                    className="input_field_chex_ride"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </InputMask>
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        className="form-item-style"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your password!",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            style={{fontFamily: "Poppins"}}
                                            className="input_field_chex_ride"
                                            placeholder="Password"
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                    <div style={{marginBottom: 15, marginTop: 10}}>
                                        <div
                                            style={{display: "flex"}}
                                        >
                                            {/*<div></div>*/}

                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <div style={{display: "flex"}}>
                                                    <label className="newcontainer">
                                                        <input onChange={handleCheckBox} type="checkbox"/>
                                                        <span className="newcheckmark_chex_ride"></span>
                                                    </label>
                                                    {/*<label className="newcontainer_ride">*/}
                                                    {/*    <input onChange={handleCheckBox} type="checkbox"/>*/}
                                                    {/*    <span className="newcheckmark_chex_ride"></span>*/}
                                                    {/*</label>*/}
                                                    <div className="register-checkbox-text_chex_ride">
                                                        By checking the box, you accept our{" "}
                                                        <span
                                                            style={{
                                                                color: "#2566c7",
                                                                cursor: "pointer",
                                                                textDecoration: "underline",
                                                                fontWeight: "bold",
                                                            }}
                                                            onClick={() => handleTermsOfUse()}
                                                        >
                          Terms of Use
                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div></div>*/}
                                        </div>

                                    </div>

                                    <Form.Item>
                                        <Button
                                            disabled={isLoading ? true : false}
                                            className="button-wrapper_chex_ride"
                                            htmlType="submit"
                                        >
                                            {isLoading ? (
                                                <ClipLoader color={"orange"} size={20}/>
                                            ) : (
                                                "Register"
                                            )}
                                        </Button>
                                    </Form.Item>

                                    <div className="login-landing-signup_ride">
                                        <div>
                                            Already have an account?{" "}
                                            <span
                                                style={{
                                                    color: "#2566c7",
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                onClick={() => handleLoginRedirect()}
                                            >
                          Login
                        </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>


                        </div>

                    </div>

                </div>

            </div>



            <div className='why_chex_section_ride'>
                <div className='why_chex_heading'>
                    <h2>
                       How Chex<span className='span_style_why'>.AI</span> works
                    </h2>

                </div>
                <div className='main_wrapper_flex_chex'>
                    <div className='why_chex_inner_section_wrapper_ride straigt_ride_flex'>
                        <div className='why_chex_content_wrapper_ride'>
                            <div className='chex_content_image_wrapper straign_content_image_wrapper'>
                                <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470831/Group_427_dsxncz_us13eq.png"} alt=""/>

                            </div>
                            <div className='content_code_section straigt_content_ride'>
                                <h2>
                                    Register
                                </h2>
                                <p>
                                    Please Register your account by filling out the form above to get started
                                </p>

                            </div>

                        </div>

                        <div className='why_chex_Image_wrapper_ride'>
                            <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470832/Group_424_eh98nh_gfkg6v.png"} alt=""/>

                        </div>


                    </div>

                    <div className='why_chex_inner_section_wrapper_ride reverse_ride_flex'>
                        <div className='why_chex_content_wrapper_ride'>
                            <div className='chex_content_image_wrapper reverse_chex_image_wrapper'>
                                <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/Group_426_lmke18_i3mjja.png"} alt=""/>

                            </div>
                            <div className='content_code_section reverse_ride_content'>
                                <h2>
                                    Complete Inspection
                                </h2>
                                <p>
                                    Follow the instructions for each inspection point within our app and upload photo and video clips
                                </p>

                            </div>

                        </div>

                        <div className='why_chex_Image_wrapper_ride'>
                            <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1666086118/secondregistration_psqkch.png"} alt=""/>

                        </div>


                    </div>


                    <div className='why_chex_inner_section_wrapper_ride straigt_ride_flex'>
                        <div className='why_chex_content_wrapper_ride '>
                            <div className='chex_content_image_wrapper straign_content_image_wrapper'>
                                <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470832/Group_428_qp4mz6_ybvepv.png"} alt=""/>

                            </div>
                            <div className='content_code_section straigt_content_ride'>
                                <h2>
                                    Complete Payment
                                </h2>
                                <p>
                                    Enter payment details. <span>If you don’t pass, your first re-inspection is free</span>
                                </p>

                            </div>

                        </div>

                        <div className='why_chex_Image_wrapper_ride'>
                            <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470834/Group_429_ie43wu_fatb2m.png"} alt=""/>

                        </div>


                    </div>



                    <div className='why_chex_inner_section_wrapper_ride reverse_ride_flex'>
                        <div className='why_chex_content_wrapper_ride'>
                            <div className='chex_content_image_wrapper reverse_chex_image_wrapper'>
                                <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470832/Group_430_dmnimb_qcj9sw.png"} alt=""/>

                            </div>
                            <div className='content_code_section reverse_ride_content'>
                                <h2>
                                    Same-day Certification
                                </h2>
                                <p>
                                    All certifications completed within <span>4 hours</span>  or less. Downloadable verification in-app
                                </p>

                            </div>

                        </div>

                        <div className='why_chex_Image_wrapper_ride'>
                            <img src={"https://res.cloudinary.com/dgjordf6e/image/upload/v1665470832/Group_431_rqxehu_hc7jbg.png"} alt=""/>

                        </div>


                    </div>

                </div>



            </div>



            <div className='pricing_wrapper_main_ride' id="pricingSectionComplete">
                <div className='pricing_wrapper_main_ride_inner'>
                    <h2>
                        Pricing
                    </h2>
                    <p>
                        Convenience isn’t the only benefit of using Chex.AI. We offer best pricing for Uber & Lyft Inspections along with best in class customer support
                    </p>

                    <div className={'pricing_card_main_outer_ride'}>
                        <div className='pricing_cards_main_wrapper'>

                            <div className='pricing_card_box_wrap'>
                        <PricingCardRide
                            deboseImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1683183910/Uber_tmndnt.png'}
                            textDebose={'INSPECTION'}
                            priceValue={'24.99'}
                            inspectionDetail={'Single Inspection'}
                        />
                            </div>
                            <div className='pricing_card_box_wrap'>
                        <PricingCardRide
                            deboseImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1683184092/Lyft_gs8ejr.png'}
                            textDebose={'INSPECTION'}
                            imageLyft={'imageLyft'}
                            priceValue={'24.99'}
                            inspectionDetail={'Single Inspection'}
                        />
                        </div>
                            <div className='pricing_card_box_wrap'>
                        <PricingCardRide
                            deboseImage={'https://res.cloudinary.com/dgjordf6e/image/upload/v1683184227/uber_left_skzrhq.png'}
                            priceValue={'39.99'}
                            imageDouble={'image_double'}
                            inspectionDetail={'Uber & Lyft Inspection'}
                        />
                    </div>
                        </div>

                    </div>



                    <div className='points_pricing_wrapper_main'>
                        <div className='points_pricing_wrapper_inner'>
                            <ul>
                                <li>
                                    <div className='point_wrapper_ride_style'>
                                        <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />
                                        <p>
                                            Inspection results within 4 hours
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='point_wrapper_ride_style'>
                                        <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />
                                        <p>
                                            <span>$14.99</span>   for additional selection
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        {/*<div className='point_wrapper_ride_style'>*/}
                        {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                        {/*    <p>*/}
                        {/*        Inspection results within 4 hours*/}
                        {/*    </p>*/}
                        {/*</div>*/}

                            {/*<div className='point_wrapper_ride_style'>*/}
                            {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                            {/*    <p>*/}
                            {/*        Verified certification in-app*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>

                        <div className='points_pricing_wrapper_inner'>
                            <ul className="second_lists_inspection_style">
                                <li>
                                    <div className='point_wrapper_ride_style'>
                                        <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />
                                        <p>
                                            Verified certification in-app
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className='point_wrapper_ride_style'>
                                        <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />
                                        <p>
                                            No appointments needed
                                        </p>
                                    </div>
                                </li>

                            </ul>
                            {/*<div className='point_wrapper_ride_style'>*/}
                            {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                            {/*    <p>*/}
                            {/*        Inspection results within 4 hours*/}
                            {/*    </p>*/}
                            {/*</div>*/}

                            {/*<div className='point_wrapper_ride_style'>*/}
                            {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                            {/*    <p>*/}
                            {/*        Verified certification in-app*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>

                        {/*<div className='points_pricing_wrapper_inner'>*/}
                            {/*<div className='point_wrapper_ride_style'>*/}
                            {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                            {/*    <p>*/}
                            {/*      <span>$14.99</span>   for additional selection*/}
                            {/*    </p>*/}
                            {/*</div>*/}

                            {/*<div className='point_wrapper_ride_style'>*/}
                            {/*    <GoPlus size={18} style={{color:'#FF7A00', marginRight:10, marginTop: 3}} />*/}
                            {/*    <p>*/}
                            {/*        No appointments needed*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        {/*</div>*/}

                    </div>

                    {/*<a href="" className='btn_pricing_ride'>*/}
                        <Link to='/getStarted' className='btn_pricing_ride'>
                            Get Started
                        </Link>


                    {/*</a>*/}
                </div>


            </div>

            <div>
                <AboutChexComponent />
            </div>

            <div>
                <CommunityBannerComponent />
            </div>

            <div>
                <CustomReviewsComponent breakPoints={breakPoints}  />
            </div>

            <div>
                <HelpBusinessSectionComponent
                    headingSolution={<h2>How can we help your<span className='business_help_span_style'> business?</span></h2>}
                    paraSolution={'We can help your business optimize asset inspection process. Our interactive dashboard allows you to review data in real time and provide feedback to your customers instantaneously!'}
                    buttonText={'Contact Us'}
                    wrapper_background_style={'wrapper_background_style'}
                    imageSolution={'https://res.cloudinary.com/dgjordf6e/image/upload/v1665470847/Group_448_nkwgnj_uiddnk.png'}

                />
            </div>


            <div className='states_section_main_wrapper_outer'>
                <div className='image_wrapper_states_cars'>
                    <img src={'https://res.cloudinary.com/dgjordf6e/image/upload/v1666092834/Layer_2_ltzyhz_haonq3.png'} alt=""/>

                </div>
                <div className='content_wrapper_states_main'>
                    <div className='content_inner_state_wrapper'>
                        <h2>
                            <span className='orangeSpan'>States</span> where <span className='blueSpan'> Chex.Ai</span> works
                        </h2>

                        <div className='states_content_data'>

                            <div className='unorder_lists'>
                                <ul>
                                <li>
                                    Arizona
                                </li>
                                <li>
                                    Alabama
                                </li>
                                <li>
                                    Alaska
                                </li>
                                <li>
                                    Arkansas
                                </li>
                                <li>
                                    California
                                </li>
                                <li>
                                    Florida
                                </li>
                                <li>
                                    Georgia
                                </li>

                                    <li>
                                        Idaho
                                    </li>

                                    <li>
                                        Indiana
                                    </li>
                                    <li>
                                        Iowa
                                    </li>
                                    <li>
                                        Kansas
                                    </li>


                                </ul>



                            </div>

                            <div className='unorder_lists'>
                                <ul>
                                <li>
                                    Missouri
                                </li>
                                <li>
                                    Mississippi
                                </li>
                                <li>
                                    Montana
                                </li>
                                <li>
                                    New Mexico
                                </li>
                                <li>
                                    North Dakota
                                </li>
                                <li>
                                    Oklahoma
                                </li>
                                <li>
                                    Oregon
                                </li>

                                    <li>
                                        South Carolina
                                    </li>
                                    <li>
                                        South Dakota
                                    </li>
                                    <li>
                                        Tennessee
                                    </li>
                                    <li>
                                        Texas
                                    </li>
                                    <li>
                                        Wyoming
                                    </li>
                                </ul>

                            </div>
                        </div>


                    </div>

                </div>

            </div>

            <div>
                <CustomFooter />
            </div>

        </div>
    )
}
export default RideshareScreen