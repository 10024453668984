
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useToasts } from 'react-toast-notifications';

import ActionCreators from '../../actions';
import { ContactUsScreen } from '../../Screens';
import { setCompanies } from '../../utils/functions';

const ContactUsContainer = (props) => {
  const [data, setData] = useState({
    message: "",
    name: "",
  });
  const [loading, setLoading] = useState()
  const {addToast} = useToasts();
  
  const handleChange = (event) => {
    const obj = {...data};
    obj[event.target.name] = event.target.value;
    setData(obj);
  }
  
  const handleSubmit = () => {
    const {contactUs, userData} = props;
    const params = {
      ...data,
      email: userData?.email
    }
   contactUs(params, addToast, setLoading,'',setData);
  }
  return <ContactUsScreen handleSubmit={handleSubmit} handleChange={handleChange} loading={loading} data={data}/>;
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    userData: state.auth.currentUser,
    isLoading: state.auth.isAuthLoading,
    companies: setCompanies(state.auth.companies),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContainer);
