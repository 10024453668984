/* eslint-disable */

import React, { useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { PDFDownloadLink, Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
//@ts-ignore
import InspectorPoint from '../../Assets/Inspector Point.png';
import ActionCreators from '../../actions';
import { setOtherReportData } from '../../utils/functions';
import ChackboxImg from '../../Assets/Checkbox.png';
import Arial from '../../font/ARIAL.TTF';
import Arialbold from '../../font/ARIALBD 1.TTF'
import Inter from '../../font/Inter-VariableFont.ttf';
import Lato from '../../font/Lato-Regular.ttf';
import './styles.css';
import tickimg from '../../Assets/tickimgOld.png';
import Dsptire from '../../Assets/Dsptire.png';
import Moment from "react-moment";

Font.register({ family: 'Arial', format: "truetype", src: Arial });
Font.register({ family: 'Arialbold', format: "truetype", src: Arialbold });
Font.register({ family: 'Inter', format: "truetype", src: Inter });
Font.register({ family: 'Lato', format: "truetype", src: Lato });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    paddingHorizontal: 10,
    // padding-bottom: 30px;
    paddingTop: 20,
    fontFamily: 'Arial',
  },
  arrow: {
    height: "20px",
    width: "20px",
    transform: "rotate(180deg)",
    marginTop: "-12px",
    marginLeft: "10px"
  },
  bodyWrapper: {
    paddingBottom: "30px",
    paddingTop: "30px",
  },
  headerWidth: {
    paddingLeft: "20px",
    paddingRight: "20px",
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  multiText: {
    color: "#000000",
    fontSize: "30px",
    textTransform: "uppercase",
    fontWeight: "700",
    fontFamily:'Inter'
  },
  form: {
    Width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  formDiv: {
    marginBottom: "1px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "20px",
    width: '55%',
  },
  formDivMileage: {
    marginBottom: "1px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "20px",
    width: '35%',
  },
  formDivVin: {
    marginBottom: "1px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "20px",
    width: '65%',
  },
  formDiv2: {
    marginBottom: "1px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "20px",
    width: '45%',
  },
  formLabelDriver: {
    fontSize: "15px",
    marginRight: "9px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "black",
    fontFamily:'Inter'
  },
  formLabel: {
    fontSize: "15px",
    // marginRight: "9px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "black",
    fontFamily:'Inter'
  },
  formUnderline: {
    borderBottomWidth: "2px",
    borderColor: "black",
    borderStyle: "solid",
    marginTop: "-5px",
    width: "84%",
  },
  formInput: {
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "black",
    marginBottom: "0",
    marginLeft: "10px",
    marginTop: "3px",
  },
  formInputTire: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "black",
    marginBottom: "0px",
    // marginRight: "-29%",
    // borderBottomWidth:"2px solid black",
    // paddingLeft:'20px',
    fontFamily:"Inter",
    // marginTop: "3px",

  },
  formInputLine:{
      borderBottomWidth: "2px",
      borderColor: "black",
      borderStyle: "solid",
      marginTop: "-5px",
      marginBottom:'15px',
      // marginLeft:'20px',
      width: "50%",

  },
  reportTireDiv: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",

  },
  mainDiv: {
    // justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    width: '100%'
  },
  reportCardDiv: {
    width: "49%",
  },
  reportCardHeadingBox: {
    paddingTop: "15px",
    paddingLeft: "7px",
    paddingRight: "15px",
    paddingBottom: "15px",
    borderWidth: "1px",
    borderColor: "black",
    backgroundColor: "black",
  },
  reportCardHeading: {
    color: "white",
    paddingLeft: "-20px",
    fontSize: "18px",
    fontWeight: "700",
    fontFamily:'Inter'
  },
  greenContainer: {
    height: "50px",
    width: "100%",
    backgroundColor: "#2F8559",
  },
  greenContainerLeft: {
    height: "565px",
    width: "10%",
    backgroundColor: "#2F8559",
    marginTop: '-14px'
  },
  greenContainerHeading: {
    paddingTop: "22px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "18px",
  },
  greenContainerHeadingLine: {
    marginLeft: "5.2%",
    backgroundColor: "white",
    height: "2px",
    width: "50%",
  },
  greenContainerHeadingText: {
    fontSize: "12px",
    color: "white",
    paddingRight: "1px",
    // textAlign: "end",
    flexGrow: 1,
    fontWeight:'bold',
    fontFamily:'Inter',
    paddingLeft: "3px",
    marginTop:'-10px'
  },
  greenContainerGrid: {
    display: "flex",
    flexDirection: "row",
  },
  greenContainerGridLeftChild: {
    flex: 0.5,
    marginLeft: "10px",
  },
  greenContainerGridLeftChildArrowLine: {
    backgroundColor: "rgb(255, 255, 255)",
    height: "150px",
    width: "2px",
    marginLeft: "19px",
    marginTop: "-13px",
  },

  greenbox1: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "32px",
    marginLeft: '7px'
  },
  box2: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "34px", marginLeft: '8px'
  },
  box3: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "30px", marginLeft: '8px'
  },
  box4: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "30px", marginLeft: '8px'
  },
  box5: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "30px", marginLeft: '8px'
  },
  box6: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "29px", marginLeft: '8px'
  },
  box7: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "30px", marginLeft: '8px'
  },
  box8: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "29px", marginLeft: '8px'
  },
  yellowContainer: {
    // height: "618px",
    // width: "355px",
    height: "50px",
    width: "90%",
    backgroundColor: "#FEE604",
    marginTop: '-551px',
    marginLeft: "39px",
  },
  yellowContainerHeading: {
    paddingTop: "22px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "18px",
  },
  yellowContainerHeadingLine: {
    marginLeft: "5.7%",
    backgroundColor: "white",
    height: "2px",
    width: "40%",
  },
  yellowContainerHeadingText: {
    fontSize: "12px",
    color: "white",
    paddingRight: "1px",
    // textAlign: "end",
    flexGrow: 1,
    paddingLeft: "3px",
    fontFamily:'Inter',
    fontWeight:'bold',
    marginTop:'-10px'
  },
  yellowContainerLeft: {
    height: "515px",
    width: "10%",
    backgroundColor: "#FEE604",
    marginTop: '-14px',
    marginLeft: '10%'
  },
  yellowContainerGrid: {
    display: "flex",
    flexDirection: "row",
  },
  yellowContainerGridLeftChild: {
    flex: 0.5,
    marginLeft: "10px",
  },
  yellowContainerGridLeftChildArrowLine: {
    backgroundColor: "rgb(255, 255, 255)",
    height: "100px",
    width: "2px",
    marginLeft: "19px",
    marginTop: "-13px",
  },
  yellowContainerGridLeftChildArrowLineHead: {
    marginLeft: "6px",
    marginTop: "-7px",
  },
  yellowbox1: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "32px",
    marginLeft: '7px'
  },

  redContainer: {
    height: "50px",
    width: "80%",
    backgroundColor: "#F74F5D",
    marginTop: '-505px',
    marginLeft: "77.5px",
  },
  redContainerLeft: {
    height: "469px",
    width: "10%",
    backgroundColor: "#F74F5D",
    marginTop: '-14px',
    marginLeft: '20%'
  },
  redContainerHeading: {
    paddingTop: "22px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "18px",
  },
  redContainerHeadingLine: {
    marginLeft: "6.5%",
    backgroundColor: "white",
    height: "2px",
    width: "25%",
  },
  redContainerHeadingText: {
    fontSize: "12px",
    color: "white",
    paddingRight: "1px",
    flexGrow: 1,
    paddingLeft: "6px",
    fontFamily:"Inter",
    fontWeight:'bold',
    marginTop:'-10px'
  },
  redContainerGrid: {
    display: "flex",
    flexDirection: "row",
  },
  redContainerGridLeftChild: {
    flex: 0.5,
    marginLeft: "10px",
  },
  redContainerGridLeftChildArrowLine: {
    height: "52px",
    backgroundColor: "rgb(255, 255, 255)",
    width: "2px",
    marginLeft: "19px",
    marginTop: "-13px",
  },
  redContainerGridLeftChildArrowLineHead: {
    marginLeft: "6px",
    marginTop: "-7px",
  },
  redbox1: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    marginTop: "32px",
    marginLeft: '7px',
  },

  functionalityBoxMainDiv: {
    height: "520px",
    width: "70.5%",
    marginLeft: "115px",
    marginTop: '-455px'
  },
  functionalityBoxHeadingBox: {
    backgroundColor: "#000000",
    height: "55px",
    // width: "275px",
    width: '100%'
  },
  functionalityBoxHeadingBoxText: {
    fontSize: "12px",
    color: "white",
    // textAlign: "center",
    marginLeft:'8px',
    fontWeight: "600",
    marginTop: "17px",
    fontFamily:'Inter',
   

  },
  functionalityBoxColumnGrid: {
    display: "flex",
    flexDirection: "column",
  },
  functionalityBox: {
    fontSize: "12px",
    backgroundColor: "white",
    color: "#000000",
    height: "50px",
    width: "100%",
    paddingLeft: "5px",
    paddingRight:'4px',
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid rgb(0, 0, 0)",
    borderRight: "2px solid rgb(0, 0, 0)",
  },

  tireCommentMainBox: {
    width: "50%",
    marginLeft: '20px'
  },
  tireSection: {
    border: "2px solid black",
  },
  tireSectionHeadingBox: {
    // padding: "15px",
    // borderWidth: "1px",
    // borderColor: "black",
    // backgroundColor: "black",
    width: "100%",
    height:'50px'
  },
  tireSectionHeadingBoxText: {
    color: "white",
    fontSize: "18px",
    padding: "15px",
    fontWeight: "700",
    fontFamily:'Inter',
   position:'absolute',
  },
  tireSectionLeftRightFrontBox: {
    backgroundColor: "#D9D9D9",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-evenly",
  },
  tireSectionLeftRightHeading: {
    display: "flex",
    justifyContent: "center",
marginLeft:'95px',
    fontWeight: "bold",
  },
  tireSectionLeftRightHeadings: {
    display: "flex",
    justifyContent: "center",
marginLeft:'115px',
    fontWeight: "bold",
  },
  tireSectionTireTredBox: {
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    // width:'100%'
  },
  tireSectionTireTredBoxLabel: {
    fontSize: "13px",
    paddingTop: "20px",
    width: "100%",
    paddingLeft: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  tireSectionTireTredBoxPlaceholder: {
    borderBottomWidth: "2px",
    borderColor: "black",
    borderBottomStyle: "solid",
    marginBottom: "19px",
    width: "50%",
  },
  tireSectionTireTredBoxPlaceholder1: {
    borderBottomWidth: "0px",
    borderLeftWidth:'2px',
    borderColor: "black",
    borderBottomStyle: "solid",
    marginBottom: "19px",
    // width: "100%",
    height:'4vh',
  },
  commentSection: {
    width: "100%",
  },
  commentHeading: {
    marginTop: "25px",
    marginBottom: "20px",
    fontWeight: "700",
    fontSize: "23px",
    fontFamily:'Inter'
  },
  commentBox: {
    height: "330px",
    width: "100%",
    border: "2px solid black",
  },
  heading1: {
    fontSize: "20px",
  },
  heading2: {
    fontSize: "14px",
    fontFamily:"Inter",
    fontWeight:'700',
    color:'black'
  },
  heading3: {
    fontSize: "10px",
  },

});


const DSPCertificate = (props) => {
  // console.log("props?.reportData" , props?.reportData);
  const history = useHistory();
  const { isLoading, handleModal, SetModal } = props;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const getToken = () => localStorage.getItem('token') || null;
    if (getToken) {
      props.getVehicleCertificate({ id: props?.id, companyId: props.companyId }, props.setLoading, 'uber');
    } else {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.removeItem('vehicleData');
      window.location.reload('/login');
    }
  }, [props?.companyId]);

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem('tabStatus', 'REVIEWED');
    setTimeout(function () {
      handleModal();
    }, 200);
  };
  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>

            <Page size="A3" style={styles.bodyWrapper}>

              <View style={styles.headerWidth}>
                <View style={styles.header}>
                  <Text style={styles.multiText}>DSP DAILY INSPECTION</Text>
                </View>
              </View>
              <View>
                <View style={styles.form}>
                  <View style={styles.formDiv}>
                    <View style={styles.formLabelDriver}>
                      <Text> Driver&nbsp;Name:</Text>
                    </View>
                    <View style={styles.formUnderline}>
                      <Text style={styles.formInput}>  {`${props?.reportData && props?.reportData?.Host_First_Name
                              } ${props?.reportData && props?.reportData?.Host_Last_Name

                              }`}</Text>
                    </View>
                  </View>
                  <View style={styles.formDiv2}>
                    <View style={{ ...styles.formLabel, marginLeft: '3px' }}>
                      <Text> Date:</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: '85%' }}>
                      <Text style={styles.formInput}>{moment(props?.reportData?.Inspection_Date).format("MM-DD-YYYY")}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.form}>
                  <View style={styles.formDiv}>
                    <View style={styles.formLabel}>
                      <Text> Vehicle&nbsp;License&nbsp;Plate&nbsp;# &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: "74%", }}>
                      <Text style={styles.formInput}>{props?.reportData?.licensePlateNumber}</Text>
                    </View>
                  </View>
                  <View style={styles.formDiv2}>
                    <View style={{ ...styles.formLabel, marginLeft: '7px' }}>
                      <Text> Year/Model:</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: '76%', marginRight: '3px' }}>
                      <Text style={styles.formInput}>
                      {props?.reportData?.Year===null || props?.reportData?.Model===null?
                null:
                `${props?.reportData?.Year}/${props?.reportData?.Model}`
                }</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.form}>
                  <View style={styles.formDivMileage}>
                    <View style={styles.formLabel}>
                      <Text> Mileage:</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: '78%', marginRight: '3px' }}>
                      <Text style={styles.formInput}> {props?.reportData?.Mileage}</Text>
                    </View>
                  </View>
                  <View style={styles.formDivVin}>
                    <View style={styles.formLabel}>
                      <Text> VIN:</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: "93%" }}>
                      <Text style={styles.formInput}>{props?.reportData?.Vin}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.form}>
                  <View style={styles.formDiv}>
                    <View style={styles.formLabel}>
                      <Text> Inspectior&nbsp;Name</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: '78%', marginLeft: '7px' }}>
                      <Text style={styles.formInput}>{`${props?.reportData?.Inspector_Name} ${props?.reportData?.Inspector_Last_Name}`}</Text>
                    </View>
                  </View>
                  <View style={styles.formDiv2}>
                    <View style={{ ...styles.formLabel, marginLeft: '6px' }}>
                      <Text> Inspector&nbsp;Signature:</Text>
                    </View>
                    <View style={{ ...styles.formUnderline, width: '60%', marginLeft: '6px' }}>
                      
                        {props?.reportData && props?.reportData?.Inspector_Signature ? (
                          <Image
                            style={{
                              width: "130px",
                              height: "40px",
                              marginLeft: "50px",
                              // marginBottom: -90,
                              marginTop: -10,
                              position:'absolute'

                            }}
                            source={`${process.env.REACT_APP_AWS_S3_LINK}/${props?.reportData && props?.reportData?.Inspector_Signature}`}
                          />
                        ) : (
                          <>
                          <Text style={styles.formInput}>
                            {`${props?.reportData && props?.reportData?.Inspector_Name
                              } ${props?.reportData && props?.reportData?.Inspector_Last_Name

                              }`}</Text>
                          </>
                        )}
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.reportTireDiv}>
                <View style={styles.mainDiv}>
                  <View style={styles.reportCardDiv}>
                    <View style={styles.reportCardHeadingBox}>
                      <View style={styles.reportCardHeading}>
                        <Text>REPORT CARD</Text>
                      </View>
                    </View>
                    <View style={styles.greenContainer}>
                      <View style={styles.greenContainerHeading}>
                        <View style={styles.greenContainerHeadingLine}></View>
                        <Text style={styles.greenContainerHeadingText}>CHECKED AND ACCEPTABLE</Text>
                      </View>


                    </View>
                    <View style={styles.greenContainerLeft}>
                      <View style={styles.greenContainerGridLeftChildArrowLine}></View>
                      <Image style={styles.arrow} source={"https://img.icons8.com/sf-black-filled/64/FFFFFF/collapse-arrow.png"} />
                      <View style={styles.greenbox1}>
                        {props?.reportData?.checks[0]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box2}>
                        {props?.reportData?.checks[1]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                      <View style={styles.box3}>
                        {props?.reportData?.checks[2]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box4}>
                        {props?.reportData?.checks[3]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box5}>                 
                         {props?.reportData?.checks[4]?.checkStatus === " Good" ? (
                        <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                      ) : null}
                      </View>
                      <View style={styles.box6}>
                        {props?.reportData?.checks[5]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box7}>
                        {props?.reportData?.checks[6]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View> 
                      <View style={styles.box8}>
                        {props?.reportData?.checks[7]?.checkStatus === " Good" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                    </View>
                    {/* <!---yellow container----> */}
                    <View style={styles.yellowContainer}>
                      <View style={styles.yellowContainerHeading}>
                        <View style={styles.yellowContainerHeadingLine}></View>
                        <Text style={styles.yellowContainerHeadingText}>MAY&nbsp;NEED&nbsp;FUTURE&nbsp;ATTENTION</Text>
                      </View>
                    </View>
                    <View style={styles.yellowContainerLeft}>
                      <View style={styles.yellowContainerGridLeftChildArrowLine}></View>
                      <Image style={styles.arrow} source={"https://img.icons8.com/sf-black-filled/64/FFFFFF/collapse-arrow.png"} />
                      <View style={styles.yellowbox1}>
                      {props?.reportData?.checks[0]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box2}>
                      {props?.reportData?.checks[1]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box3}>
                      {props?.reportData?.checks[2]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box4}>
                      {props?.reportData?.checks[3]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box5}>
                      {props?.reportData?.checks[4]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box6}>
                      {props?.reportData?.checks[5]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box7}>
                      {props?.reportData?.checks[6]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>
                      <View style={styles.box8}>
                      {props?.reportData?.checks[7]?.checkStatus === "Repair" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                      </View>

                    </View>

                    {/* <!---red div---> */}
                    <View style={styles.redContainer}>
                      <View style={styles.redContainerHeading}>
                        <View style={styles.redContainerHeadingLine}></View>
                        <Text style={styles.redContainerHeadingText}>REQUIRES&nbsp;IMMEDIATE&nbsp;ATTENTION</Text>
                      </View>
                    </View>

                    <View style={styles.redContainerLeft}>
                      <View>
                        <View style={styles.redContainerGridLeftChildArrowLine}></View>
                        <Image style={styles.arrow} source={"https://img.icons8.com/sf-black-filled/64/FFFFFF/collapse-arrow.png"} />
                        <View style={styles.redbox1}>
                        {props?.reportData?.checks[0]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box2}>
                        {props?.reportData?.checks[1]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box3}>
                        {props?.reportData?.checks[2]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box4}>
                        {props?.reportData?.checks[3]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box5}>
                        {props?.reportData?.checks[4]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box6}>
                        {props?.reportData?.checks[5]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box7}>
                        {props?.reportData?.checks[6]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                        <View style={styles.box8}>
                        {props?.reportData?.checks[7]?.checkStatus === "Replace" ? (
                          <Image style={{ position: 'absolute', height: '20px', }} src={tickimg} />
                        ) : null}
                        </View>
                      </View>
                    </View>



                    <View>
                      <View style={styles.functionalityBoxMainDiv}>
                        <View style={styles.functionalityBoxHeadingBox}>
                          <Text style={styles.functionalityBoxHeadingBoxText}>Light Functionality & Body Damage/Wear</Text>
                        </View>
                        <View style={styles.functionalityBoxColumnGrid}>
                          <View style={styles.functionalityBox}>
                            <Text>Head Lights / Tail Lights</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Windshield Condition (Cracks, Chips, or Fitting)</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Exterior Rearview Mirrors</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Turn Indicator Lights</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Exterior Left - Condition of the vehicle exterior body</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Exterior Right - Condition of the vehicle exterior body</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Front (Including Bumper and Hood) - Condition of the vehicle exterior body</Text>
                          </View>
                          <View style={styles.functionalityBox}>
                            <Text>Rear (Including Bumper and Trunk) - Condition of the vehicle exterior body</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {/* <!-- -----------------------------tires----------------------------------------> */}
                  <View style={styles.tireCommentMainBox}>
                    <View>
                      <View style={styles.tireSection}>
                        <View>
                        <Image style={styles.tireSectionHeadingBox} src={Dsptire} />
                          <View style={styles.tireSectionHeadingBoxText}>
                            <Text> TIRES</Text>
                          </View>
                        </View>

                        <View style={styles.tireSectionLeftRightFrontBox}>
                          <View style={styles.tireSectionLeftRightHeading}>
                            <Text style={styles.heading2}> Left Front </Text>
                          </View>
                          <View style={styles.tireSectionLeftRightHeadings}>
                            <Text style={styles.heading2}>Right Front </Text>
                          </View>
                        </View>
                        <View style={styles.tireSectionTireTredBox}>
                          <View style={styles.tireSectionTireTredBoxLabel}>
                            <Text>Tire&nbsp;Tread</Text>

                            <View style={styles.formInputLine}>
                              <Text style={{...styles.formInputTire,marginBottom:'-20px',marginLeft:'25px'}}>  {props?.reportData?.tireDimensionObject?.left_front}</Text>
                            </View>
                          </View>
                          <View style={styles.tireSectionTireTredBoxPlaceholder1}></View>
                          <View style={styles.tireSectionTireTredBoxLabel}>
                            <Text>Tire&nbsp;Tread</Text>
                            <View style={styles.formInputLine}>
                              <Text style={{...styles.formInputTire,marginBottom:'-20px',marginLeft:'25px'}}>  {props?.reportData?.tireDimensionObject?.right_front}</Text>
                            </View>
                          </View>
                        </View>
                        <View style={styles.tireSectionLeftRightFrontBox}>
                          <View style={{...styles.tireSectionLeftRightHeading,marginLeft:'100px'}}>
                            <Text style={styles.heading2}>Left Rear</Text>
                          </View>
                          <View style={{...styles.tireSectionLeftRightHeadings,marginLeft:'127px'}}>
                            <Text style={styles.heading2}>Right Rear</Text>
                          </View>
                        </View>
                        <View style={{...styles.tireSectionTireTredBox,borderBottomWidth:0}}>
                          <View style={styles.tireSectionTireTredBoxLabel}>
                            <Text>Tire&nbsp;Tread</Text>
                            <View style={styles.formInputLine}>
                              <Text style={{...styles.formInputTire,marginBottom:'-20px',marginLeft:'25px'}}>  {props?.reportData?.tireDimensionObject?.left_rear}</Text>
                            </View>
                          </View>
                          <View style={styles.tireSectionTireTredBoxPlaceholder1}></View>
                          <View style={styles.tireSectionTireTredBoxLabel}>
                            <Text>Tire&nbsp;Tread</Text>
                            <View style={styles.formInputLine}>
                              <Text style={{...styles.formInputTire,marginBottom:'-20px',marginLeft:'25px'}}>  {props?.reportData?.tireDimensionObject?.right_rear}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      {/* <!-----------------------Commment Section--------------------------> */}
                      <View style={styles.commentSection}>
                        <View style={styles.commentHeading}>
                          <Text>COMMENTS:</Text>
                        </View>
                        <View style={styles.commentBox}>
                          <Text style={styles.formInput}>{props?.reportData?.remarks}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View></View>
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={'#246DB5'} size={40} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  onClick={() => handleReload()}
                  style={{
                    fontSize: '15px',
                    marginBottom: '20px',
                    marginTop: '20px',
                    color: 'white',
                    fontWeight: 'bold',
                    margin: 'auto',
                    background: '#3276ba',
                    padding: '13px',
                    borderRadius: '50px',
                  }}
                >
                  Download vehicle report
                </div>
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  
  return {
    reportData: state.vehicleInstruction.getAroundCertificate,
    // checksData: setOtherReportData(state.vehicleInstruction.uberVehicleCertificate?.CertificateData, 'index_2'),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DSPCertificate);
