/* eslint-disable */

import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet, Font,
} from "@react-pdf/renderer";
import moment from "moment";
import Comma from "../../Assets/Comma.png";
import TURO_icon from "../../Assets/TURO_icon.png";
import Vector from "../../Assets/Vector.png";
import "./LyftCertificate.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { Row, Col, Checkbox } from 'antd';
import ActionCreators from "../../actions";
import { setTuroReportData } from "../../utils/functions";

import kalamFont from '../../font/Kalam-Light.ttf';

Font.register({ family: 'kalamFont', format: "truetype", src: kalamFont });

const styles = StyleSheet.create({
  body: {
    paddingBottom: 5,
  },
  headerWidth: {
    // paddingHorizontal: 10,
    marginBottom: "25px",
  },
  header: {
    color: "#333333",
    marginTop: 15,
    fontSize: 40,
    fontWeight: "700",
  },
  californiaText: {
    color: "white",
    fontSize: "12px",
    textTransform: "uppercase",
    fontWeight: "400",
    marginLeft: "20@s",
  },
  californiaText1: {
    color: "white",
    fontSize: "20px",
    textTransform: "uppercase",
    fontWeight: "600",
  },
  boxContainer: {
    borderWidth: 1,
    borderColor: "#141414",
    backgroundColor: "#E4E5E7",
    padding: "10@s",
  },
  lyftdrivertextContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15@s",
  },
  boxWidth: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  lyftdriverText: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "42@s",
    color: "#272E38",
  },
  allfieldsText: {
    color: "#47484F",
    fontSize: "10px",
    fontWeight: "400",
    marginLeft: "8@s",
  },
  nameinputContainer: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "100%",
  },
  lyftdriverName: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "23@s",
    color: "#47484F",
    marginBottom: 0,
    marginLeft: "10@s",
    marginTop: "3@s",
  },
  nameText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "30%",
  },
  threeinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  nameinputContainerOne: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "30%",
    marginRight: "10@s",
  },
  nameinputContainerTwo: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "30%",
    marginRight: "10@s",
  },
  nameinputContainerThree: {
    borderWidth: 1,
    borderColor: "#47484F",
    marginTop: "10@s",
    width: "40%",
  },
  threeinputFields: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "50%",
  },
  licenseplateContainer: {
    borderWidth: 1,
    borderColor: "#47484F",
    width: "45%",
    marginRight: "13@s",
  },
  codeText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "23@s",
    color: "black",
    marginLeft: "50%",
  },
  vinContainer: {
    fontSize: "12px",
    fontWeight: "30",
    fontStyle: "normal",
    color: "black",
  },
  twoinputfieldContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10@s",
  },
  vintopBorder: {
    borderTopWidth: 1,
    borderTopColor: "#47484F",
    width: "100%",
  },
  topText: {
    marginTop: "30px",
    fontSize: "12px",
    // borderLeftWidth: 1,
    paddingLeft: "8 px",
    paddingRight: "10px",
  },
  vehicleinspectionPadding: {
    paddingHorizontal: "20@s",
    paddingTop: "10@s",
  },
  checkboxesmainContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30@s",
  },
  checkboxesmainContainerTwo: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: "30@s",
    marginTop: "10@s",
  },
  inspectionfirstContainer: {
    marginBottom: "10@s",
    width: "98%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionsecondContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inspectionText: {
    // marginTop: '20@s',
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "#000000",
    textTransform: "uppercase",
  },
  inspectiontextTwo: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    marginLeft: "15@s",
    textTransform: "uppercase",
  },
  passText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    marginRight: "38@s",
    textTransform: "uppercase",
  },
  failText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#716E6E",
    textTransform: "uppercase",
  },
  passfailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  passfailContainer1: {
    flexDirection: "row",
    alignItems: "center",
  },
  numbersText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "21@s",
    color: "#000000",
  },
  detailsText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "5px",
  },
  detailsText2: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700px",
    lineHeight: "21@s",
    color: "#828282",
    marginLeft: "19px",
  },
  shieldText: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "#828282",
    marginLeft: "15@s",
    marginTop: "10@s",
    marginBottom: "10@s",
  },
  failpassbox: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
    marginLeft: "0@s",
    width: "100%",
    padding: "20px 10px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    position: "absolute",
    marginBottom: "45@s",
    top: -10,
  },

  boxinspectionText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "-40px",
    textTransform: "uppercase",
  },
  circleoneText: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "-115px",
    lineHeight: "21@s",
    color: "#716E6E",
    marginTop: "5px",
  },
  circleoneText1: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21@s",
    color: "black",
    marginTop: "8px",
    marginLeft: "3px",
  },
  boxpassText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
  },
  boxfailText: {
    marginTop: "-40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
  },
  orangeCircle: {
    marginTop: "-5px",
    borderWidth: 2,
    borderColor: "#000",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    lineHeight: "50@s",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "-25px",
  },
  boxfailText: {
    marginTop: "-40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "black",
    marginLeft: "30px",
  },
  boxinspectionContainer: {
    marginLeft: "10px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tobecompletedContainer: {
    backgroundColor: "#F4F4F4",
    padding: "15@s 10@s",
  },
  tobecompletedContainer1: {
    marginTop: "10@s",
  },
  tobecompletedWidth: {
    paddingHorizontal: "20@s",
    marginTop: "15@s",
  },
  tobecompletedWidth1: {
    paddingHorizontal: "20@s",
  },
  tobecompletedtextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  tobecompletedText: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "23@s",
    color: "black",
    textTransform: "uppercase",
  },
  fieldsText: {
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "21@s",
    color: "#716E6E",
    textTransform: "uppercase",
  },

  inpectionText: {
    fontSize: "16px",
    color: "#47484F",
    marginRight: "10px",
    fontWeight: "600",
    fontStyle: "noraml",
    lineHeight: "22@s",
    textTransform: "uppercase",
  },
  documentexpiryText: {
    fontSize: "12px",
    color: "#47484F",
  },
  dateText: {
    fontSize: "18px",
    color: "black",
    fontWeight: "extralight",
  },
  datemainContainer: {
    marginTop: "10@s",
    marginLeft: "52%",
  },
  namesfieldscontainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "25@s",
    alignItems: "flex-end",
  },
  namesfieldscontainer1: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "25@s",
    alignItems: "flex-end",
  },
  borderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },
  dimentionBorderWidth: {
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },
  borderWidth1: {
    borderBottomWidth: 1,
    borderBottomColor: "#000000",
  },
  topName: {
    fontSize: "14px",
    // fontFamily: 'kalamFont',
    color: "black",
    marginBottom: '0px',
  },
  tireDimentions: {
    fontSize: "10px",
    color: "#000",
    marginTop: '10@s',
  },
  bottomName: {
    fontSize: "10px",
    color: "#000000",
    paddingTop: "5px",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  bottomName1: {
    fontSize: "10px",
    color: "#000000",
    paddingTop: "5px",
    fontWeight: "700",
    marginBottom: -10,
    textTransform: "uppercase",
    width: "100px",
  },
  bottomPara: {
    paddingHorizontal: "25@s",
    fontSize: 10,
    lineHeight: 1.6,
    marginTop: "30@s",
    fontWeight: "700",
    color: "#000000",
  },
  bottomPara1: {
    paddingHorizontal: "30@s",
    fontSize: 10,
    marginTop: "15@s",
    marginLeft: "10px",
    color: "black",
  },
  bottomPara2: {
    paddingHorizontal: "30@s",
    fontSize: 10,
    marginTop: "15@s",
    marginLeft: "14px",
    color: "black",
  },
  tickpassContainer: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "50px",
  },
  tickpassContainertires: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  tickSelectContainer: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  tickfailContainer: {
    borderWidth: 1,
    borderColor: "black",
    borderRadius: "2px",
    height: "15px",
    width: "15px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "15px",
  },
  tickfailContainer1: {
    borderWidth: 1,
    borderColor: "black",
    height: "14px",
    width: "14px",
    justifyContent: "center",
    alignItems: "center",
  },
  inspectiondateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
  statetext: {
    fontSize: "10px",
    marginLeft: "10px",
    color: "gray",
    marginRight: "10px",
  },
  statewrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
  },
  stateinspection1: {
    fontSize: "11px",
    color: "gray",
    marginTop: "5px",
    marginLeft: "9.5px",
  },
  turoImage: {
    height: "40px",
    width: "100px",
  },
});

const TUROCertificate = (props) => {
  const { isLoading, handleModal, SetModal, reportData, checksData } = props;
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const getToken = () => localStorage.getItem("token") || null;
    if (getToken) {
      props.getVehicleCertificate(
        { id: props?.id, companyId: props.companyId },
        props.setLoading,
        "template4"
      );
    } else {
      props.history.push("/login");
    }
  }, [props?.companyId]);

  const windShield = [
    {
      id: 1,
      name: "Large crack (Greater than 1 ”) = Fail",
    },
    {
      id: 2,
      name: "Small crack (Smaller than 1 ”) = Pass",
    },
  ];

  const handleReload = () => {
    SetModal(false);
    localStorage.setItem("tabStatus", "REVIEWED");
    setTimeout(function () {
      handleModal();
    }, 200);
  };

  return (
    <div className="App">
      <PDFDownloadLink
        wrap={false}
        document={
          <Document>
            <Page size="A3" style={styles.body}>
              <View style={{ paddingHorizontal: "20@s", paddingTop: 20 }}>
                <Image style={styles.turoImage} source={TURO_icon} />
                <Text style={styles.header}>
                  Annual vehicle inspection checklist
                </Text>
              </View>

              {/* <View style={{ marginLeft: "20@s"}}>
                  <View style={{ flexWrap: "wrap", flexDirection: "row", alignSelf: 'center'}}>
                    <Image
                      style={{
                        width: "100%",
                        marginLeft: -10,
                      }}
                      source={Inspection_Checks_TURO}
                    />
                  </View>
                </View> */}

              <View
                style={{
                  marginTop: "20@s",
                  flexDirection: "row",
                  paddingHorizontal: "20px",
                }}
              >
                <View style={{ flexDirection: "column", width: "50%" }}>
                  <View style={styles.inspectionfirstContainer}>
                    <Text style={styles.inspectionText}>INSPECTION POINTS</Text>
                    <View style={styles.passfailContainer}>
                      <Text style={styles.passText}>PASS</Text>
                      <Text style={styles.failText}>FAIL</Text>
                    </View>
                  </View>

                  {checksData?.map((check, index) =>
                    index <= 11 ? (
                      <>
                      {check.Check.name === "Brakes - shoes" ? (
                        <View
                        key={index}
                        style={{
                          marginBottom: "8px",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "8px",
                        }}
                      >
                        <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text style={styles.numbersText}>
                                  {index + 1}
                                </Text>
                                <Text style={styles.detailsText}>
                                  {`${check.Check.name} & pads ( `}<Text style={{ textDecoration: 'underline' }}>{">"}</Text>{` 3mm thick)`}
                                </Text>
                                
                              </View>
                              <View style={styles.passfailContainer}>
                                {check.status == true || check.status == 1 ? (
                                  <View style={styles.tickpassContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickpassContainer}></View>
                                )}
                                {check.status == false || check.status == 0 ? (
                                  <View style={styles.tickfailContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickfailContainer}></View>
                                )}
                              </View>
                        </View>
                      ) : 
                        check.Check.name === "Windshield" ? (
                          <>
                            <View
                              style={{
                                marginBottom: "8px",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "8px",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text style={styles.numbersText}>
                                  {index + 1}
                                </Text>
                                <Text style={styles.detailsText}>
                                  {check.Check.name}
                                </Text>
                              </View>
                              <View style={styles.passfailContainer}>
                                {check.status == true || check.status == 1 ? (
                                  <View style={styles.tickpassContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickpassContainer}></View>
                                )}
                                {check.status == false || check.status == 0 ? (
                                  <View style={styles.tickfailContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickfailContainer}></View>
                                )}
                              </View>
                            </View>
                            {windShield?.map((shield, index) => {
                              return (
                                <Text key={index} style={styles.shieldText}>
                                  {shield.name}
                                </Text>
                              );
                            })}
                          </>
                        ) : (
                          // null

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "8@s",
                              marginBottom: "8@s",
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text style={styles.numbersText}>
                                {index + 1}
                              </Text>
                              <Text style={styles.detailsText}>
                                {check.Check.name}
                              </Text>
                            </View>
                            <View style={styles.passfailContainer}>
                              {check.status == true || check.status == 1 ? (
                                <View style={styles.tickpassContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles.tickpassContainer}></View>
                              )}
                              {check.status == false || check.status == 0 ? (
                                <View style={styles.tickfailContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles.tickfailContainer}></View>
                              )}
                            </View>
                          </View>
                        )}
                      </>
                    ) : null
                  )}
                </View>

                <View
                  style={{
                    flexDirection: "column",
                    width: "50%",
                    paddingLeft: "20@s",
                    marginBottom: '8@s'
                  }}
                >
                  <View style={styles.inspectionfirstContainer}>
                    <Text style={styles.inspectionText}>INSPECTION POINTS</Text>
                    <View style={styles.passfailContainer}>
                      <Text style={styles.passText}>PASS</Text>
                      <Text style={styles.failText}>FAIL</Text>
                    </View>
                  </View>

                  {props?.checksData?.map((check, index) =>
                    index > 11 ? (
                      <View
                      key={index}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "8@s",
                          marginBottom: '8@s'
                        }}
                      >
                        {index === 15 ? (
                          <View style={{
                            width: '100%'}}>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <View style={{ flexDirection: "row" }}>
                                <Text style={styles.numbersText}>
                                  {index + 1}
                                </Text>
                                <Text style={styles.detailsText}>
                                  {check?.Check?.name}
                                </Text>
                              </View>
                              <View style={styles.passfailContainer1}>
                                {check?.status == true || check?.status == 1 ? (
                                  <View style={styles.tickpassContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickpassContainer}></View>
                                )}
                                {check?.status == false ||
                                check?.status == 0 ? (
                                  <View style={styles.tickfailContainer}>
                                    <Image
                                      style={{
                                        width: "8px",
                                        marginLeft: "0px",
                                      }}
                                      source={Vector}
                                    />
                                  </View>
                                ) : (
                                  <View style={styles.tickfailContainer}></View>
                                )}
                              </View>
                            </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "5@s",
                                    marginBottom: '5@s'
                                  }}
                                >
                                  <View style={{ marginTop: "10@s" }}>
                                    <Text style={styles.detailsText2}>
                                      Right front measurement
                                    </Text>
                                  </View>
                                  <View style={{ width: "21%", marginRight: '15@s' }}>
                                  {reportData && reportData?.Files.map((files, index) => {
                                      return(
                                        <>
                                        {files?.category === 'right_front_tire' ? 
                                       <View style={{flexDirection:'row',justifyContent:'center'}}>
                                       <Text key={index} style={styles.tireDimentions}>
                                       {files?.dimension === null ? "*"
                                          : (
                                            <>
                                            {files?.dimension}
                                         </>
                                         )}
                                       </Text>
                                         <Image source={Comma} style={{ height: 4 , width: 4,marginTop:11}} />
                                       </View>
                                        : null}
                                      </>
                                      )
                                    })}
                                    <View style={styles.dimentionBorderWidth} />
                                  </View>
                                </View>

                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "5@s",
                                    marginBottom: '5@s'
                                  }}
                                >
                                  <View style={{ marginTop: "10@s" }}>
                                    <Text style={styles.detailsText2}>
                                    Left front measurement
                                    </Text>
                                  </View>
                                  <View style={{ width: "21%", marginRight: '15@s' }}>
                                  {reportData && reportData?.Files.map((files, index) => {
                                      return(
                                        <>
                                        {files?.category === 'left_front_tire' ?
                                        <View style={{flexDirection:'row',justifyContent:'center'}}>
                                        <Text key={index} style={styles.tireDimentions}>
                                        {files?.dimension === null ? "*"
                                           : (
                                             <>
                                             {files?.dimension}
                                          </>
                                          )}
                                        </Text>
                                          <Image source={Comma} style={{ height: 4 , width: 4,marginTop:11}} />
                                        </View>
                                        : null}
                                      </>
                                      )
                                    })}
                                    <View style={styles.dimentionBorderWidth} />
                                  </View>
                                </View>

                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "5@s",
                                    marginBottom: '5@s'
                                  }}
                                >
                                  <View style={{ marginTop: "10@s" }}>
                                    <Text style={styles.detailsText2}>
                                    Right rear measurement
                                    </Text>
                                  </View>
                                  <View style={{ width: "21%", marginRight: '15@s' }}>
                                  {reportData && reportData?.Files.map((files, index) => {
                                      return(
                                        <>
                                        {files?.category === 'right_rear_tire' ? 
                                        <View style={{flexDirection:'row',justifyContent:'center'}}>
                                        <Text key={index} style={styles.tireDimentions}>
                                        {files?.dimension === null ? "*"
                                           : (
                                             <>
                                             {files?.dimension}
                                          </>
                                          )}
                                        </Text>
                                          <Image source={Comma} style={{ height: 4 , width: 4,marginTop:11}} />
                                        </View>
                                        : null}
                                      </>
                                      )
                                    })}
                                    <View style={styles.dimentionBorderWidth} />
                                  </View>
                                </View>

                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "5@s",
                                    marginBottom: '5@s'
                                  }}
                                >
                                  <View style={{ marginTop: "10@s" }}>
                                    <Text style={styles.detailsText2}>
                                    Left rear measurement
                                    </Text>
                                  </View>
                                  <View style={{ width: "21%", marginRight: '15@s' }}>
                                  {reportData && reportData?.Files.map((files, index) => {
                                      return(
                                        <>
                                        {files?.category === 'left_rear_tire' ? 
                                        <View style={{flexDirection:'row',justifyContent:'center'}}>
                                        <Text key={index} style={styles.tireDimentions}>
                                        {files?.dimension === null ? "*"
                                           : (
                                             <>
                                             {files?.dimension}
                                          </>
                                          )}
                                        </Text>
                                          <Image source={Comma} style={{ height: 4 , width: 4,marginTop:11}} />
                                        </View>
                                        : null}
                                      </>
                                      )
                                    })}
                                    <View style={styles.dimentionBorderWidth} />
                                  </View>
                                </View>
                            {/* //   );
                            // })} */}
                          </View>
                        ) : (
                          <>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text style={styles.numbersText}>
                                {index + 1}
                              </Text>
                              <Text style={styles.detailsText}>
                                {check?.Check?.name}
                              </Text>
                            </View>
                            <View style={styles.passfailContainer}>
                              {check?.status == true || check?.status == 1 ? (
                                <View style={styles.tickpassContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles.tickpassContainer}></View>
                              )}
                              {check?.status == false || check?.status == 0 ? (
                                <View style={styles.tickfailContainer}>
                                  <Image
                                    style={{ width: "8px", marginLeft: "0px" }}
                                    source={Vector}
                                  />
                                </View>
                              ) : (
                                <View style={styles.tickfailContainer}></View>
                              )}
                            </View>
                          </>
                        )}
                      </View>
                    ) : null
                  )}

                  <View
                    style={[
                      styles.inspectionsecondContainer,
                      { marginTop: "20px" },
                    ]}
                  >
                    <View style={styles.failpassbox}>
                      <View style={styles.boxinspectionContainer}>
                        <Text style={styles.boxinspectionText}>
                          VEHICLE INSPECTION
                        </Text>
                        <Text style={styles.circleoneText}>
                          (Please circle)
                        </Text>
                      </View>
                      {reportData && reportData.finalStatus === "pass" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>PASS</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>PASS</Text>
                      )}
                      {reportData && reportData.finalStatus === "fail" ? (
                        <View style={styles.orangeCircle}>
                          <Text style={styles.boxpassText}>FAIL</Text>
                        </View>
                      ) : (
                        <Text style={styles.boxpassText}>FAIL</Text>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.tobecompletedWidth1}>
                <View style={styles.tobecompletedContainer1}>
                  <View style={styles.namesfieldscontainer1}>
                    <View style={{ width: "50%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData &&
                          `${reportData?.Username} ${
                            reportData?.UserlastName === null
                              ? ""
                              : reportData?.UserlastName
                          }`}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>HOST NAME</Text>
                    </View>
                    <View style={{ width: "50%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Useremail}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>HOST EMAIL ADDRESS</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer1}>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}> </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>HOST SIGNATURE</Text>
                    </View>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Userphone}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>HOST PHONE NUMBER</Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>Date</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.tobecompletedWidth}>
                <View style={styles.tobecompletedContainer}>
                  <View style={styles.tobecompletedtextContainer}>
                    <Text style={styles.tobecompletedText}>
                      TO BE COMPLETED BY QUALIFIED MECHANIC{" "}
                    </Text>
                  </View>

                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.licensePlateNumber}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>LICENSE PLATE</Text>
                    </View>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.vin}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>VIN #</Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.milage}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>VEHICLE MILEAGE</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.make}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>VEHICLE MAKE</Text>
                    </View>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.model}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>VEHICLE MODEL</Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.year}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>VEHICLE YEAR</Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "80%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.stateAddress}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>
                        INSPECTOR COMPANY AND ADDRESS
                      </Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>{reportData && reportData?.stateNumber}</Text>
                      <View style={styles.borderWidth1} />
                      <Text style={styles.bottomName1}>
                        LICENSE/ASW ID # (OPTIONAL)
                      </Text>
                    </View>
                  </View>
                  <View style={styles.namesfieldscontainer}>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData &&
                          `${reportData?.Inspectorname} ${
                            reportData?.InspectorlastName === null
                              ? ""
                              : reportData?.InspectorlastName
                          }`}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>INSPECTOR NAME</Text>
                    </View>
                    <View style={{ width: "40%", marginRight: "20@s" }}>
                      <Text style={styles.topName}>
                        {reportData && reportData?.Inspectorsignature ? (
                          <Image
                            style={{
                              width: "150px",
                              height: "50px",
                              marginLeft: "50px",
                              marginBottom: -10,
                            }}
                            source={`${process.env.REACT_APP_AWS_S3_LINK}/${
                              reportData && reportData?.Inspectorsignature
                            }?x-request=xhr`}
                          />
                        ) : (
                            null
                          // <Text style={styles.topName}>
                          //   {reportData &&
                          //     `${reportData?.Inspectorname} ${
                          //       reportData?.InspectorlastName === null
                          //         ? ""
                          //         : reportData?.InspectorlastName
                          //     }`}
                          // </Text>
                        )}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>INSPECTOR SIGNATURE</Text>
                    </View>
                    <View style={{ width: "20%" }}>
                      <Text style={styles.topName}>
                        {moment(
                          new Date(reportData && reportData?.reviewedDate)
                        ).format("MM/ DD /YYYY")}
                      </Text>
                      <View style={styles.borderWidth} />
                      <Text style={styles.bottomName}>DATE</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ textAlign: "left" }}>
                <Text style={styles.bottomPara}>
                  TURO HOSTS: PLEASE ENSURE ALL FIELDS ON THIS CHECKLIST ARE
                  COMPLETED AND THAT YOUR CAR PASSED THE INSPECTION BEFORE
                  SUBMITTING IT IN YOUR CAR'S "SAFETY INSPECTIONS" TAB.
                </Text>
              </View>
            </Page>
          </Document>
        }
        fileName="Vehicle-certificate.pdf"
      >
        {({ blob, url, loading, error }) => (
          <>
            {isLoading ? (
              <ClipLoader color={"#246DB5"} size={40} />
            ) : (
              <div
                style={{
                  display: "flex",
                  marginTop: "30px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    handleReload();
                  }}
                  style={{
                    fontSize: "15px",
                    marginBottom: "20px",
                    marginTop: "20px",
                    color: "white",
                    fontWeight: "bold",
                    margin: "auto",
                    background: "#3276ba",
                    padding: "13px",
                    borderRadius: "50px",
                  }}
                >
                  Download Vehicle report
                </div>
                <i className="fa fa-download" />
              </div>
            )}
          </>
        )}
      </PDFDownloadLink>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

function mapStateToProps(state) {
  return {
    reportData: state.vehicleInstruction.turoVehicleCertificate,
    checksData: setTuroReportData(
      state.vehicleInstruction.turoVehicleCertificate?.Files
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TUROCertificate);
